import ReactPlayer from 'react-player';

import { EMPTY_STATE_BG_VARIANTS } from 'app/constants/empty-states';

import { Paper, Box, Button, Typography } from '@mui/material';
import * as SVG from '../Icons';

const EmptyState = ({
  title,
  videoUrl,
  howToDoUrl,
  videoTitle,
  description,
  onAddNewClick,
  videoDescription,
  withAddNew = true,
  withHowToDo = true,
  videoBgColor = 'teal.50',
  addButtonTitle = 'Add new',
  bgVariant = EMPTY_STATE_BG_VARIANTS.BIG_LIST,
}) => {
  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '944px',
          height: '522px',
          backgroundImage: `url(/assets/icons/empty-states/${bgVariant}.svg)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Box component={Paper} display="flex" padding="28px" gap="28px" maxWidth="701px">
          <Box flex={1} display="flex" flexDirection="column" gap="20px">
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="heading5" fontWeight={500} color="accent.800">
                {title}
              </Typography>
              <Typography variant="body2" fontSize={15} color="accent.500">
                {description}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap="10px"
              marginTop="auto"
              sx={{
                'a:not([role=button]):not(.MuiButtonBase-root)': {
                  color: 'accent.900',
                },
              }}
            >
              {withHowToDo && (
                <Box
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://docs.allpass.ai/${howToDoUrl}`}
                >
                  <Button
                    color="gray"
                    variant="contained"
                    startIcon={<SVG.HeroIconsOutline iconId="book-open" size={16} />}
                  >
                    How to do
                  </Button>
                </Box>
              )}
              {withAddNew && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onAddNewClick}
                  startIcon={<SVG.CommonIcons iconId="plus" size={16} sx={{ color: 'common.white' }} />}
                >
                  <Typography variant="button">{addButtonTitle}</Typography>
                </Button>
              )}
            </Box>
          </Box>
          {videoUrl && (
            <Box
              height="180px"
              flex={1}
              bgcolor="accent.100"
              padding="5px"
              borderRadius="14px"
              marginY="20px"
              sx={{
                // '.react-player__preview': {
                //   borderRadius: '14px',
                //   backgroundColor: videoBgColor,
                // },
                video: { borderRadius: '14px', width: 'auto', height: 'auto' },
              }}
            >
              <ReactPlayer
                // light
                playIcon={
                  <Box
                    width="100%"
                    height="100%"
                    position="relative"
                    display="flex"
                    alignItems="center"
                    padding="10px"
                  >
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Box>
                        <Typography variant="body1" fontWeight={600}>
                          {videoTitle}
                        </Typography>
                        <Typography variant="caption" fontSize="10px">
                          {videoDescription}
                        </Typography>
                      </Box>
                      <img src="/assets/images/logo/allpass-logo.svg" alt="" width="60px" height="60px" />
                    </Box>
                    <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                      <img src="/assets/icons/play-icon.svg" alt="" width="40px" height="40px" />
                    </Box>
                  </Box>
                }
                controls
                width="100%"
                height="100%"
                url={videoUrl}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyState;
