import { useController } from 'react-hook-form';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import CircleChecked from '@mui/icons-material/RadioButtonCheckedOutlined';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

const ControlledCheckbox = ({
  defaultValue,
  type = 'standard',
  customLabel,
  name,
  control,
  label,
  ...restProps
}) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  const isCircle = type === 'circle';

  const handleChange = (e) => {
    const { checked } = e.target;
    const valueTarget = checked ? defaultValue : '';
    onChange(defaultValue ? valueTarget : checked);
  };

  return (
    <FormControlLabel
      control={
        isCircle ? (
          <Checkbox
            checked={!!value}
            onChange={handleChange}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            {...restProps}
          />
        ) : (
          <Checkbox checked={!!value} onChange={handleChange} {...restProps} />
        )
      }
      label={customLabel || <Typography variant="body2">{label}</Typography>}
    />
  );
};

export default ControlledCheckbox;
