import history from '@history';
import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
import { ExceptionCode } from 'app/constants/exceptionCode';
import { updateOtpStorage } from 'app/utils/updateOtpStorage';

import { setUserData, setQRCode } from './userSlice';

export const tokenLogin = () => async (dispatch) => {
  dispatch(startLoading());

  return jwtService
    .signInWithToken()
    .then((user) => {
      dispatch(setUserData(user));
      return dispatch(loginSuccess());
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const refreshTokenLogin = () => async (dispatch) => {
  dispatch(startLoading());

  return jwtService
    .signInWithRefreshToken()
    .then((user) => {
      dispatch(setUserData(user));
      return dispatch(loginSuccess());
    })
    .then(() => {
      history.push({
        pathname: history.location.pathname,
        state: { redirectUrl: history.location.pathname },
      });
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const submitLogin = (model) => async (dispatch) => {
  dispatch(startLoading());
  return jwtService
    .signInWithEmailAndPassword(model)
    .then((data) => {
      const { user, code, token } = { ...data };
      if (code === ExceptionCode.UserNotVerifiedEmail) {
        updateOtpStorage(token, model.login);
        dispatch(codeSentToEmail());
        return;
      }
      if (!user?.twoFactorEnabled) {
        dispatch(setUserData(user));
      }

      return dispatch(loginSuccess(user));
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const submit2fAuth = (model) => async (dispatch) => {
  dispatch(startLoading());
  return jwtService
    .signIn2faCode(model)
    .then((user) => {
      dispatch(setUserData(user));
      return dispatch(loginSuccess());
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const register2fAuth = (model) => async (dispatch) => {
  return jwtService
    .register2faCode(model)
    .then((QRCode) => {
      dispatch(setQRCode(QRCode));
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const disable2fAuth = (model) => async (dispatch) => {
  return jwtService
    .turnOff2fa(model)
    .then((user) => {
      dispatch(setUserData(user));
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const confirm2fAuth = (model) => async (dispatch) => {
  dispatch(startLoading());
  return jwtService
    .confirm2fAuth(model)
    .then((user) => {
      dispatch(setUserData(user));
    })
    .catch((err) => dispatch(loginError(err.message)));
};

export const confirmInvite = (model) => async (dispatch) => {
  dispatch(startLoading());
  return jwtService
    .confirmInvite(model)
    .then((user) => {
      history.push('/sign-in');
      dispatch(loginSuccess(user));
    })
    .catch((err) => dispatch(loginError(err.message)));
};

const initialState = {
  success: false,
  errors: [],
  loading: false,
  twoFactorVerified: false,
  isCodeSent: false,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    codeSentToEmail(state) {
      state.isCodeSent = true;
    },
    loginSuccess: (state, action) => {
      state.twoFactorVerified = action.payload?.twoFactorVerified;
      state.loading = false;
      state.success = true;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.errors = Array.isArray(action.payload) ? action.payload : [action.payload];
    },
  },
  extraReducers: {},
});

export const { codeSentToEmail, loginSuccess, loginError, startLoading } = loginSlice.actions;

export default loginSlice.reducer;
