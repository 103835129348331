import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export default function Search({ fontSize, placeholder, iconPosition = 'end', ...restProps }) {
  const adornment = iconPosition === 'start' ? 'startAdornment' : 'endAdornment';

  return (
    <TextField
      sx={{
        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
          padding: '0',
        },
      }}
      variant="outlined"
      InputProps={{
        ...restProps,
        placeholder,
        [adornment]: (
          <InputAdornment position={iconPosition} sx={{ color: 'accent.900' }}>
            <SearchIcon fontSize={fontSize} />
          </InputAdornment>
        ),
      }}
    />
  );
}
