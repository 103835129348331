import i18next from 'i18next';

export const TRANSLATION_COMMON_KEY = 'common-translation';

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_COMMON_KEY, require(`./i18n/${lang}`).default)
);

export const lightPaletteText = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
  navbar: 'rgb(255,255,255)',
  integration: '#000000',
  invert: 'rgb(255,255,255)',
  nav: '#5D3DD4',
};

export const darkPaletteText = {
  primary: 'rgb(255,255,255)',
  nav: '#9AE9B7',
  secondary: 'rgb(148, 163, 184)',
  disabled: 'rgb(156, 163, 175)',
  navbar: 'rgb(255,255,255)',
  integration: '#000000',
  invert: 'rgb(255,255,255)',
};

const themesConfig = {
  default: {
    palette: {
      mode: 'light',
      divider: '#e2e8f0',
      text: lightPaletteText,
      common: {
        black: 'rgb(17, 24, 39)',
        white: 'rgb(255, 255, 255)',
        teal: '#9AE9B7',
      },
      primary: {
        light: '#64748b',
        main: '#5D3DD4',
        dark: '#0f172a',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#818cf8',
        main: '#5D3DD4',
        dark: '#3730a3',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#FFFFFF',
        default: '#FAFAFA',
        navbar: '#FFFFFF',
      },
      error: {
        light: '#ffcdd2',
        main: '#CF2929',
        dark: '#b71c1c',
      },
    },
    status: {
      danger: 'orange',
    },
  },
  defaultDark: {
    palette: {
      mode: 'dark',
      divider: 'rgba(241,245,249,.12)',
      text: darkPaletteText,
      common: {
        black: '#000000',
        white: 'rgb(255, 255, 255)',
        teal: '#9AE9B7',
      },
      primary: {
        light: '#64748b',
        main: '#334155',
        dark: '#0f172a',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#818cf8',
        main: '#4f46e5',
        dark: '#3730a3',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#1e293b',
        default: '#111827',
        navbar: '#000000',
      },
      error: {
        light: '#ffcdd2',
        main: '#CF2929',
        dark: '#b71c1c',
      },
      status: {
        danger: 'orange',
      },
    },
  },
};

export default themesConfig;
