import { createAction } from '@reduxjs/toolkit';

import invitesService from './invitesService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const INVITES_MODULE_KEY = 'invites';

const storeBuilder = new BaseEntityStoreBuilder(INVITES_MODULE_KEY, invitesService);

export const { selectors } = storeBuilder;
export const actions = {
  getInviteById: storeBuilder.actions.getById,
  getAllInvites: storeBuilder.actions.getAll,
  deleteInvite: storeBuilder.actions.delete,
  updateInvite: storeBuilder.actions.update,
  createInvite: storeBuilder.actions.create,
  clearErrors: createAction('invite/clearError'),
  updateInviteToken: storeBuilder.createAction('updateToken'),
};

const invitesSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.updateInviteToken.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.clearErrors, (state, action) => {
      state.error = null;
    })
);

export default invitesSlice.reducer;
