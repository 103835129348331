import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Tooltip as MuiTooltip, Typography } from '@mui/material';

const Tooltip = ({
  text,
  children,
  customText,
  iconSize = 16,
  textAlign = 'start',
  iconColor = 'warning.main',
  icon = 'workflow:exclamation-circle',
  ...restProps
}) => (
  <MuiTooltip
    title={
      customText || (
        <Typography variant="caption" color="common.white" textAlign={textAlign}>
          {text}
        </Typography>
      )
    }
    placement="top"
    {...restProps}
  >
    {children || (
      <FuseSvgIcon sx={{ color: iconColor }} size={iconSize}>
        {icon}
      </FuseSvgIcon>
    )}
  </MuiTooltip>
);

export default Tooltip;
