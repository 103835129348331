import { lazy } from 'react';
import i18next from 'i18next';
import { TRANSLATION_KEY, PATHS } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

const Applicants = lazy(() => import('./Applicants'));
const ApplicantDetails = lazy(() => import('./ApplicantDetails'));
const ApplicantHistory = lazy(() => import('./ApplicantHistory'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const VerificationsNavigationConfig = {
  id: 'verifications',
  title: 'Verifications',
  translate: 'VERIFICATIONS',
  type: 'collapse',
  icon: 'navigation:verification',
  auth: [PERMISSIONS.ListApplicants.value, PERMISSIONS.ApplicantAll.value, PERMISSIONS.OnlyTestMode.value],
  children: [
    {
      id: 'Applicants',
      title: 'Applicants',
      translate: 'APPLICANTS',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.APPLICANTS,
      auth: [PERMISSIONS.ApplicantAll.value, PERMISSIONS.OnlyTestMode.value],
    },
  ],
};

export const VerificationsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.ApplicantAll.value, PERMISSIONS.ListApplicants.value, PERMISSIONS.OnlyTestMode.value],
  routes: [
    {
      path: PATHS.APPLICANTS,
      element: <Applicants />,
    },
    {
      path: PATHS.APPLICANTS_DETAILS,
      children: [
        { path: '', element: <ApplicantDetails /> },
        { path: PATHS.APPLICANTS_HISTORY, element: <ApplicantHistory /> },
      ],
      settings: {
        layout: {
          config: { toolbar: false },
        },
      },
    },
  ],
};
