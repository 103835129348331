import match from 'autosuggest-highlight/match';
import _ from '../../../@lodash';

const MAX_SUGGESTION_COUNT = 10;

function getSuggestions(value, data) {
  const inputValue = _.deburr(value.trim()).toLowerCase();

  if (!inputValue.length || !data) {
    return [];
  }

  let count = 0;
  return data.filter((suggestion) => {
    const keep = count < MAX_SUGGESTION_COUNT && match(suggestion.title, inputValue).length;

    if (keep) {
      count += 1;
    }

    return keep;
  });
}

const initialState = {
  searchText: '',
  search: false,
  navigation: null,
  suggestions: [],
  noSuggestions: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        opened: true,
      };
    }
    case 'close': {
      return {
        ...state,
        opened: false,
        searchText: '',
      };
    }
    case 'setSearchText': {
      return {
        ...state,
        searchText: action.value,
      };
    }
    case 'setNavigation': {
      return {
        ...state,
        navigation: action.value,
      };
    }
    case 'updateSuggestions': {
      const suggestions = getSuggestions(action.value, state.navigation);
      const isInputBlank = !action.value.trim().length;
      const noSuggestions = !isInputBlank && !suggestions?.length;

      return {
        ...state,
        suggestions,
        noSuggestions,
      };
    }
    case 'clearSuggestions': {
      return {
        ...state,
        suggestions: [],
        noSuggestions: false,
      };
    }
    case 'decrement': {
      return { count: state.count - 1 };
    }
    default: {
      return state;
    }
  }
}
