import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { SERVICES_MODULE_KEY } from '../constants';

const adapter = createEntityAdapter({
  selectId: (verificationServices) => verificationServices.service,
});

const verificationServices = createSlice({
  name: SERVICES_MODULE_KEY,
  initialState: adapter.getInitialState({
    services: null,
    histories: null,
    info: null,
    error: null,
    webhookState: null,
  }),
  reducers: {
    fillInfo: (state, { payload }) => {
      state.info = payload;
    },
    updateInfo: (state, { payload }) => {
      state.info = { ...state.info, ...payload };
    },
    updateReviewer: (state, { payload }) => {
      state.info.review = payload;
    },
    fillHistories: (state, { payload }) => {
      state.histories = payload;
    },
    fillError: (state, { payload }) => {
      state.error = payload;
    },
    fillDevice: (state, { payload }) => {
      state.device = payload;
    },
    fillWebhookState: (state, { payload }) => {
      state.webhookState = payload;
    },
    addDataService: (state, { payload }) => {
      return state.ids.includes('AML')
        ? adapter.updateOne(state, payload)
        : adapter.addOne(state, { ...payload.changes, service: payload.id });
    },
    fillDataServices: adapter.setAll,
    clearState: (state) => {
      state.info = null;
      state.histories = null;
      state.error = null;
      state.services = null;
      state.device = null;
      state.webhookState = null;
      adapter.removeAll(state);
    },
  },
});

export const { actions } = verificationServices;

const entityStore = (state) => state[SERVICES_MODULE_KEY];

const baseSelectors = adapter.getSelectors(entityStore);

export const selectors = {
  ...baseSelectors,
  selectHistories: createSelector(entityStore, (state) => state.histories),
  selectInfo: createSelector(entityStore, (state) => state.info),
  selectError: createSelector(entityStore, (state) => state.error),
  selectDevice: createSelector(entityStore, (state) => state.device),
  selectWebhookState: createSelector(entityStore, (state) => state.webhookState),
  selectByServiceName: (serviceName) => (state) => baseSelectors.selectById(state, serviceName),
  selectDataByServiceName: (serviceName) => (state) => baseSelectors.selectById(state, serviceName)?.data,
};

export default verificationServices.reducer;
