import _ from 'lodash';
import { Autocomplete, Box, Paper, Typography } from '@mui/material';
import * as SVG from 'app/shared-components/Icons';
import RenderOptionChip from './RenderOptionChip';

const ControlledSearchInput = ({
  value,
  label,
  control,
  multiple,
  freeSolo,
  onChange,
  fieldValue,
  renderTags,
  placeholder,
  options = [],
  getOptionLabel,
  getOptionDisabled,
  withCheckMark = true,
  labelPosition = 'top',
  actions,
  ...restProps
}) => {
  const deleteOption = (option) => {
    const filteredValues = value.filter((optionValue) => optionValue.value !== option);

    onChange({}, filteredValues);
  };

  const labelPositionLeft = labelPosition === 'left';

  return (
    <Autocomplete
      fullWidth
      value={value}
      options={options}
      freeSolo={freeSolo}
      onChange={onChange}
      multiple={multiple}
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={(option) => !!_.find(value, { value: option.value })}
      popupIcon={<SVG.CommonIcons size={16} iconId="cheveron-down" />}
      renderOption={(props, option) => {
        const checkMark = withCheckMark && value.value === option.value;

        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }} {...props}>
            <Typography variant="body2">{option.label}</Typography>
            {checkMark && (
              <Box marginLeft="auto">
                <SVG.HeroIconsSolid size={16} iconId="check" sx={{ color: 'violet.900' }} />
              </Box>
            )}
          </Box>
        );
      }}
      renderInput={(params) => (
        <RenderOptionChip
          label={label}
          value={value}
          params={params}
          multiple={multiple}
          placeholder={placeholder}
          deleteOption={deleteOption}
          labelPositionLeft={labelPositionLeft}
        />
      )}
      PaperComponent={({ children }) => {
        return (
          <Paper>
            {children}
            {actions && actions}
          </Paper>
        );
      }}
      {...restProps}
    />
  );
};

export default ControlledSearchInput;
