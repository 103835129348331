/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['Admin'],
  manager: ['Admin', 'manager'],
  operator: ['Admin', 'manager', 'operator'],
  onlyGuest: [],
};

export default authRoles;

export const AUTH_ROLES = {
  ADMIN: 'Admin',
  MANAGER: 'manager',
  OPERATOR: 'operator',
};

export const ROLE_RUNG = {
  Admin: 3,
  manager: 2,
  operator: 1,
};
