import { createSlice, createEntityAdapter, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

export const withStatusProcessing = (entity, builder) => {
  const actionChecker = (finalActionStatus) => (action) =>
    action.type.startsWith(entity) && action.type.endsWith(finalActionStatus);

  return builder
    .addMatcher(actionChecker('/pending'), (state) => {
      state.loading = true;
      state.success = false;
    })
    .addMatcher(actionChecker('/rejected'), (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    })
    .addMatcher(actionChecker('/fulfilled'), (state) => {
      state.loading = false;
      state.success = true;
    });
};

export class BaseEntityStoreBuilder {
  get actionReducerAssoc() {
    return [
      {
        action: this.actions.getAll,
        reducer: (state, action) => {
          state.total = action.payload?.total || 0;
          return this.adapter.setAll(state, action.payload?.rows || []);
        },
      },
      {
        action: this.actions.getById,
        reducer: this.adapter.upsertOne,
      },
      {
        action: this.actions.delete,
        reducer: this.adapter.removeOne,
      },
      {
        action: this.actions.update,
        reducer: this.adapter.updateOne,
      },
      {
        action: this.actions.create,
        reducer: this.adapter.addOne,
      },
    ];
  }

  constructor(entityName, apiService, selectId, sortComparer) {
    this._entityName = entityName;
    this._apiService = apiService;

    this.actions = {
      getById: this.createAction('getById'),
      getAll: this.createAction('getAll'),
      delete: this.createAction('delete'),
      update: this.createAction('update'),
      create: this.createAction('create'),
    };

    this.adapter = createEntityAdapter({
      ...(selectId && { selectId }),
      ...sortComparer,
    });

    const entityStore = (state) => state[entityName];
    this.selectors = {
      ...this.adapter.getSelectors(entityStore),
      selectLoadingStatus: createSelector(entityStore, (state) => state.loading),
      selectError: createSelector(entityStore, (state) => state.error),
      selectSuccess: createSelector(entityStore, (state) => state.success),
      selectTotal: createSelector(entityStore, (state) => state.total),
    };
  }

  createAction(name, serviceMethod = name) {
    if (!this._apiService[serviceMethod]) return null;

    return createAsyncThunk(
      `${this._entityName}/${name}`,
      this._apiService[serviceMethod].bind(this._apiService)
    );
  }

  generateSlice(customCaseBuilder) {
    return createSlice({
      name: this._entityName,
      initialState: this.adapter.getInitialState({
        loading: false,
        success: false,
        total: 0,
      }),
      extraReducers: (builder) => {
        const baseReducer = this.actionReducerAssoc.reduce((acc, { action, reducer }) => {
          if (!action) return acc;
          return acc.addCase(action.fulfilled, reducer);
        }, builder);

        customCaseBuilder(baseReducer);

        return withStatusProcessing(this._entityName, baseReducer);
      },
    });
  }
}
