import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

class TariffPlanService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/client/tariff');
  }

  async getEnabledCompanyTariffPlan(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/current', { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new TariffPlanService();
