import { createSelector, createSlice } from '@reduxjs/toolkit';

import tariffPlanService from './tariffPlanService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const TARIFF_PLAN_MODULE_KEY = 'tariff-plan';

const storeBuilder = new BaseEntityStoreBuilder(TARIFF_PLAN_MODULE_KEY, tariffPlanService);

const entityStore = (state) => state[TARIFF_PLAN_MODULE_KEY];
export const selectors = {
  selectEnabledTariffPlan: createSelector(entityStore, (state) => state.data),
  selectLoadingStatus: createSelector(entityStore, (state) => state.loading),
  selectError: createSelector(entityStore, (state) => state.error),
};

export const actions = {
  getEnabledCompanyTariffPlan: storeBuilder.createAction('getEnabledCompanyTariffPlan'),
};

const slice = createSlice({
  name: TARIFF_PLAN_MODULE_KEY,
  initialState: {
    data: null,
    loading: false,
  },

  extraReducers: {
    [actions.getEnabledCompanyTariffPlan.fulfilled](state, { payload }) {
      state.data = payload;
      state.loading = false;
    },
    [actions.getEnabledCompanyTariffPlan.rejected](state, { error }) {
      state.error = error.message;
      state.loading = false;
    },
    [actions.getEnabledCompanyTariffPlan.pending](state) {
      state.loading = true;
    },
  },
});

export default slice.reducer;
