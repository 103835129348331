import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

class InvitesService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/invite');
  }

  async create({ record, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post('', record);
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async delete({ id, callback }) {
    try {
      await this.axios.delete(id.toString());
      if (typeof callback === 'function') {
        callback();
      }
      return id;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateToken({ id, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`updateToken/${id}`);
      if (typeof callback === 'function') {
        callback();
      }
      return { id, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async confirm({ token, newUser }) {
    try {
      const response = await this.axios.post(`confirm/${token}`, newUser);
      return response;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new InvitesService();
