import { useController } from 'react-hook-form';
import { FormControl, TextField, Typography } from '@mui/material';

const ControlledTextField = ({ required, name, control, customLabel, label, ...restProps }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <FormControl fullWidth>
      {customLabel || (
        <Typography variant="caption" color={error ? 'warning.main' : 'accent.500'}>
          {label}
          {required && '*'}
        </Typography>
      )}
      <TextField placeholder={label} error={!!error} value={value} onChange={onChange} {...restProps} />

      {error && (
        <Typography variant="caption" color="error.main">
          {error.message}
        </Typography>
      )}
    </FormControl>
  );
};

export default ControlledTextField;
