export const TRANSLATION_KEY = 'users';

export const PATHS = {
  USERS: '/users',
  USER_DETAILS: '/users/:userId',
  PROFILE: '/profile',
  INVITES: '/invites',
  INVITE_DETAILS: '/invites/:inviteId',
  ROLES: '/roles',
  ROLE_BUILDER: ':roleId',
};
