import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import useLocalStorage from 'app/hooks/useLocalStorage';
import { createSelector } from '@reduxjs/toolkit';

const defaultSelector = createSelector(
  () => '',
  () => []
);

export const useDraftList = ({ draftListKey, draftItemIndexSelector }) => {
  const itemIndex = useSelector(draftItemIndexSelector || defaultSelector);
  const [draftList, setDraftList] = useLocalStorage(draftListKey, []);

  useEffect(() => {
    if (typeof itemIndex === 'object') {
      setDraftList(draftList.filter((_, index) => index !== itemIndex.index));
    }
  }, [itemIndex]);

  return draftList;
};
