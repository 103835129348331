export const PATHS = {
  AML_SCAN: '/aml-scan',
  AML_SCAN_RESULTS: '/aml-scan-results',
  AML_SCAN_RESULT_DETAILS: ':scanId',
  AML_SCAN_RESULT_LOGS: ':scanId/logs',
};

export const TRANSLATION_KEY = 'aml';
export const AML_SCAN_MODULE_KEY = 'aml-scan';

export const AML_CHECK_STATUSES = {
  INIT: 'INIT',
  DONE: 'DONE',
  FAIL: 'FAIL',
  PROCESS: 'PROCESS',
};

export const AML_SCAN_ENTITY_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  ORGANIZATION: 'ORGANIZATION',
};

export const AML_SCAN_SEARCH_BY = {
  NAME_SURNAME: 'NAME_SURNAME',
  PASSPORT_NUMBER: 'PASSPORT_NUMBER',
  IDENTITY_NUMBER: 'IDENTITY_NUMBER',
};

export const AML_SCAN_MATCH_STATUS = {
  UNKNOWN: 'UNKNOWN',
  NO_MATCH: 'NO_MATCH',
  POTENTIAL_MATCH: 'POTENTIAL_MATCH',
  FALSE_POSITIVE: 'FALSE_POSITIVE',
  TRUE_POSITIVE: 'TRUE_POSITIVE',
  TRUE_POSITIVE_APPROVE: 'TRUE_POSITIVE_APPROVE',
  TRUE_POSITIVE_REJECT: 'TRUE_POSITIVE_REJECT',
};

export const AML_SCAN_CASE_STATUS = {
  UNKNOWN: 'UNKNOWN',
  UNCONFIRMED: 'UNCONFIRMED',
  CONFIRMED_FALSE_POSITIVE: 'CONFIRMED_FALSE_POSITIVE',
  CONFIRMED_TRUE_POSITIVE: 'CONFIRMED_TRUE_POSITIVE',
};

export const mockedData = {
  id: '1d6d8cab-a1ab-416f-b542-f078a0dbe256',
  clientKey: 'b6c98bc0-06b4-4cc7-89b1-ebd71b856d20',
  status: 'UNKNOWN',
  matchStatus: 'POTENTIAL_MATCH',
  searchBy: 'NAME_SURNAME',
  value: 'ABDURAHMAN SALEM IBRAHIM MILAD',
  entityType: 'INDIVIDUAL',
  birthYear: '',
  minMatchRate: 92,
  isDeepSearch: null,
  countryCodes: [],
  externalId: 'D0166713966',
  totalRecordCount: 18,
  data: {
    isZFS: false,
    limit: 30,
    start: 0,
    value: 'ABDURAHMAN SALEM IBRAHIM MILAD',
    scanBy: 'bd62434f-fc7b-46bd-b518-104b1e3e301a',
    scanId: 'D0166713966',
    tagList: [],
    scanDate: '2024-08-30T18:26:41.407',
    scanType: 'SearchByName',
    birthYear: null,
    caseStatus: 'Open',
    isSafeList: false,
    isWhiteList: false,
    riskLevelId: 0,
    maxMatchRate: 100,
    minMatchRate: 92,
    matchStatusId: 2,
    nameSearchType: 'Individual',
    nationalyFilter: null,
    referenceNumber: 'D0166713966',
    nameSearchTypeId: 1,
    totalRecordCount: 18,
    whiteListMessage: null,
    returnRecordCount: 18,
    totalProfileCount: 5,
    assignedUserGuidId: null,
    outReferenceNumber: '1d6d8cab-a1ab-416f-b542-f078a0dbe256',
  },
  amlProfiles: [
    {
      id: '3f837797-992d-4258-9394-6cb8ea853bd2',
      status: 'POTENTIAL_MATCH',
      shortInfo: {
        type: 'Sanction',
        fullName: 'Abd Al-Rahman Salim Ibrahim al-Milad',
        lastName: 'al-Milad',
        firstName: 'Abd',
        matchRate: 100,
        entityType: 'Individual',
        nationality: 'LIBYE',
        blacklistName: 'Trésor Direction Générale Economic Sanctions List',
      },
      createdAt: '2024-08-30T15:26:42.270Z',
      updatedAt: '2024-08-30T15:26:42.270Z',
      comments: [],
    },
    {
      id: '261f5bee-092e-471c-a067-94436795af02',
      status: 'POTENTIAL_MATCH',
      shortInfo: {
        type: 'Watchlist',
        fullName: 'Abd Al-Rahman SALIM',
        lastName: 'SALIM',
        firstName: 'Abd',
        matchRate: 100,
        entityType: 'Individual',
        nationality: 'Libya',
        blacklistName: 'Argentina Ministry of Foreign Affairs and UN Sanctions',
      },
      createdAt: '2024-08-30T15:26:42.270Z',
      updatedAt: '2024-08-30T15:26:42.270Z',
      comments: [],
    },
    {
      id: 'd04c6326-91fd-4934-9513-a066ce8d84df',
      status: 'POTENTIAL_MATCH',
      shortInfo: {
        type: 'Sanction',
        fullName: 'Abd Al-Rahman SALIM',
        lastName: 'SALIM',
        firstName: 'Abd',
        matchRate: 100,
        entityType: 'Individual',
        nationality: 'Libya',
        blacklistName: 'Financial Monitoring Service of the Republic of Azerbaijan ',
      },
      createdAt: '2024-08-30T15:26:42.270Z',
      updatedAt: '2024-08-30T15:26:42.270Z',
      comments: [],
    },
    {
      id: 'c6acc0ce-4c23-44b7-ae1c-1811652ba360',
      status: 'POTENTIAL_MATCH',
      shortInfo: {
        type: 'Sanction',
        fullName: 'Abd Al-Rahman Salim Ibrahim Al-Milad',
        lastName: 'Al-Milad',
        firstName: 'Abd',
        matchRate: 100,
        entityType: 'Individual',
        nationality: 'LIBYE',
        blacklistName: 'Monaco Economic Sanctions',
      },
      createdAt: '2024-08-30T15:26:42.270Z',
      updatedAt: '2024-08-30T15:26:42.270Z',
      comments: [],
    },
    {
      id: 'dcc87ef3-49c0-4895-b9d9-f619591d04c4',
      status: 'POTENTIAL_MATCH',
      shortInfo: {
        type: 'Sanction',
        fullName: 'ABD AL-RAHMAN SALIM IBRAHIM AL-MILAD',
        lastName: 'AL-MILAD',
        firstName: 'ABD',
        matchRate: 100,
        entityType: 'Individual',
        nationality: 'Libya\r\n',
        blacklistName: 'United Nations Sanctions (UN)',
      },
      createdAt: '2024-08-30T15:26:42.270Z',
      updatedAt: '2024-08-30T15:26:42.270Z',
      comments: [],
    },
  ],
  createdAt: '2024-08-30T15:26:37.771Z',
  updatedAt: '2024-08-30T15:26:42.256Z',
};

export const listTypeOptions = [
  { label: 'Sanction', value: 1, name: 'option 1' },
  { label: 'Wanted', value: 2, name: 'option 2' },
  { label: 'WatchList', value: 3, name: 'option 3' },
  { label: 'SOC', value: 4, name: 'option 4' },
  { label: 'PEP Class 1', value: 5, name: 'option 5' },
  { label: 'PEP Class 2', value: 6, name: 'option 6' },
  { label: 'PEP Class 3', value: 7, name: 'option 7' },
  { label: 'PEP Class 4', value: 8, name: 'option 8' },
  { label: 'SIP', value: 9, name: 'option 9' },
  { label: 'Local List', value: 10, name: 'option 10' },
];
