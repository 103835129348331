import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import Button from '@mui/material/Button';
import FuseLoading from '@fuse/core/FuseLoading';
import { useEffectOnce } from 'app/hooks/useEffectOnce';
import PermissionsGate from 'app/main/teams/components/PermissionsGate';

import Search from '../Search';
import SimpleLayout from '../SimpleLayout';
import DateRangePicker from '../DateRangePicker';
import { useDraftList } from './hooks/useDraftList';
import { ListGridWrapper } from './ListGridWrapper';
import { useManageFilteringAndDataFetching } from './hooks/useManageFilteringAndDataFetching';

const PageWithFiltersAndInfinityScroll = ({
  title,
  btnText,
  children,
  fetchList,
  emptyState,
  addNewClick,
  draftListKey,
  listSelector,
  totalSelector,
  loadingSelector,
  addNewBtnScopes = [],
  searchPlaceholder,
  draftItemIndexSelector,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const draftList = useDraftList({ draftListKey, draftItemIndexSelector });
  const { onDateChange, onSearch, loadMoreItems, total, list } = useManageFilteringAndDataFetching({
    fetchList,
    listSelector,
    totalSelector,
  });

  useEffectOnce(() => {
    dispatch(fetchList());
  }, []);

  const noData = !list?.length && !loading;

  return (
    <SimpleLayout
      noData={noData}
      title={title}
      actions={
        <PermissionsGate scopes={addNewBtnScopes}>
          <Button variant="contained" color="secondary" onClick={addNewClick}>
            {btnText}
          </Button>
        </PermissionsGate>
      }
      filters={
        <>
          <DateRangePicker onChange={onDateChange} />
          <Search
            iconPosition="start"
            placeholder={searchPlaceholder}
            onChange={onSearch}
            fontSize="small"
            size="small"
          />
        </>
      }
      content={
        <>
          {!!draftList?.length && (
            <ListGridWrapper list={draftList} draft sx={{ marginBottom: '20px' }}>
              {children}
            </ListGridWrapper>
          )}
          <InfiniteScroll
            next={loadMoreItems}
            dataLength={list.length}
            hasMore={list.length < total}
            style={{ paddingBottom: '20px' }}
            loader={<FuseLoading showLoadingText={false} />}
          >
            <ListGridWrapper list={list}>{children}</ListGridWrapper>
          </InfiniteScroll>

          {noData && emptyState}
        </>
      }
    />
  );
};

export default PageWithFiltersAndInfinityScroll;
