import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import clientService from './clientDetailsService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const CLIENT_DETAILS_MODULE_KEY = 'client-details';

const storeBuilder = new BaseEntityStoreBuilder(
  CLIENT_DETAILS_MODULE_KEY,
  clientService,
  (client) => client.clientKey
);

const entityStore = (state) => state[CLIENT_DETAILS_MODULE_KEY];
export const selectors = {
  selectClient: createSelector(entityStore, (state) => state.data),
  selectError: createSelector(entityStore, (state) => state.error),
  selectGetClientStatus: createSelector(entityStore, (state) => state.status.getClient),
  selectUpdateClientStatus: createSelector(entityStore, (state) => state.status.updateClient),
  selectDeleteClientStatus: createSelector(entityStore, (state) => state.status.deleteClient),
};

export const actions = {
  getClient: createAsyncThunk(`${CLIENT_DETAILS_MODULE_KEY}/getAll`, async (t, { dispatch }) => {
    const client = await clientService.getAll();
    return client;
  }),
  updateClient: storeBuilder.actions.update,
  deleteClient: storeBuilder.actions.delete,
  updateClientLogo: storeBuilder.createAction('updateLogo'),
  clearError: createAction(`${CLIENT_DETAILS_MODULE_KEY}/clearError`),
  clearStatus: createAction(`${CLIENT_DETAILS_MODULE_KEY}/clearStatus`),
};

export const STATUS = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
  DEFAULT: 'default',
};

export const ACTION_TYPE = {
  getClient: 'getClient',
  updateClient: 'updateClient',
  deleteClient: 'deleteClient',
};

const clientDetailsSlice = createSlice({
  name: CLIENT_DETAILS_MODULE_KEY,
  initialState: {
    data: null,
    error: null,
    status: {
      [ACTION_TYPE.getClient]: STATUS.DEFAULT,
      [ACTION_TYPE.updateClient]: STATUS.DEFAULT,
      [ACTION_TYPE.deleteClient]: STATUS.DEFAULT,
    },
  },

  extraReducers: (builder) => {
    const stateActionAssociation = [
      { action: actions.getClient, statusField: [ACTION_TYPE.getClient] },
      { action: actions.updateClient, statusField: [ACTION_TYPE.updateClient] },
      { action: actions.deleteClient, statusField: [ACTION_TYPE.deleteClient] },
    ];

    stateActionAssociation.forEach(({ action, statusField }) =>
      builder
        .addCase(action.pending, (state) => {
          state.status[statusField] = STATUS.PENDING;
          state.error = null;
        })
        .addCase(action.fulfilled, (state, { payload }) => {
          state.data = payload;
          state.status[statusField] = STATUS.FULFILLED;
        })
        .addCase(action.rejected, (state, { error }) => {
          state.error = error?.message;
          state.status[statusField] = STATUS.REJECTED;
        })
    );
    builder
      .addCase(actions.updateClientLogo.fulfilled, (state, { payload }) => {
        state.data.logo = payload;
      })
      .addCase(actions.clearError, (state) => {
        state.error = null;
      })
      .addCase(actions.clearStatus, (state, { payload }) => {
        state.status[payload] = null;
      });
  },
});

export default clientDetailsSlice.reducer;
