import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CopyTextfield from '../TextField/CopyTextfield';
import * as SVG from '../Icons';

const BlurredField = ({ label, visible, value, eyeIconSize = 20, showFieldClick, appendIcon = null }) => (
  <Stack direction="row" component={Card} padding="6px 16px" alignItems="center" maxWidth="max-content">
    <Stack direction="row" gap="5px" alignItems="center">
      <SVG.HeroIconsOutline iconId="key" size={20} />
      <Typography variant="subtitle2" marginRight="20px">
        {label}
      </Typography>
    </Stack>

    <CopyTextfield valueToCopy={value}>
      <Typography
        variant="body2"
        sx={{
          filter: !visible ? 'blur(4px)' : 'none',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </Typography>
    </CopyTextfield>

    <Stack direction="row">
      <IconButton color="secondary" onClick={showFieldClick}>
        <SVG.HeroIconsOutline size={eyeIconSize} iconId={visible ? 'eye-off' : 'eye'} />
      </IconButton>
      {appendIcon && appendIcon}
    </Stack>
  </Stack>
);
export default BlurredField;
