import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

const PATHS_VERIFICATION = '/verification';

class VerificationsService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_SERVICE);
  }

  async getAll(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get(PATHS_VERIFICATION, { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getById(transactionId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_VERIFICATION}/info/${transactionId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateDocumentField({ path, applicantId, transactionId, id, value, fieldType, fieldId, field }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${path}/${transactionId || applicantId}/documentField/${id}`, { value });
      const restField = field.filter((fieldData) => fieldData.id !== id);
      return { id: fieldId, changes: { fieldType, data: [...restField, data] } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async assignReviewer({ id, callback, ...restParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_VERIFICATION}/${id}/review/assign`, { ...restParams });
      if (typeof callback === 'function') {
        callback();
      }
      return { id, changes: { review: data } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async batchAssign({ form, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_VERIFICATION}/review/batch-assign`, form);
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async approve({ id, ...restParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${PATHS_VERIFICATION}/${id}/review/approve`, { ...restParams });
      return { id, changes: { review: data } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async decline({ id, ...restParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${PATHS_VERIFICATION}/${id}/review/reject`, { ...restParams });
      return { id, changes: { review: data } };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async delete({ transactionId, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`${PATHS_VERIFICATION}/${transactionId}`);
      if (typeof callback === 'function') {
        callback();
      }
      return transactionId;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new VerificationsService();
