import { createSelector, createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    options: null,
    openedDialog: null,
  },
  reducers: {
    openDialog: (state, { payload: { dialogId, ...options } }) => {
      state.options = options;
      state.openedDialog = dialogId;
    },
    closeDialog: (state, action) => {
      state.openedDialog = null;
      // state.options = clearOptions ? null : state.options;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;
export const selectDialogOptions = createSelector(
  (state) => state.fuse,
  (state) => state.dialog.options
);
export const selectOpenedDialog = createSelector(
  (state) => state.fuse,
  (state) => state.dialog.openedDialog
);

export default dialogSlice.reducer;
