import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import BaseApi from '../../../services/common/base-api';
import { AxiosManager } from '../../../services/axiosManager';

class DashboardService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/');
  }

  async _baseApplicantsStatisticRequest(endpoint, filter) {
    const params = pickBy(filter, identity);
    try {
      const {
        data: { data },
      } = await this.axios.get(`applicants/statistics/${endpoint}`, { params });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  getApplicantsCountryCount(filter) {
    return this._baseApplicantsStatisticRequest('applicants-country-count', filter);
  }

  getApplicantsPhoneCountryCount(filter) {
    return this._baseApplicantsStatisticRequest('phone-country-count', filter);
  }

  getApplicantsDocCountryCount(filter) {
    return this._baseApplicantsStatisticRequest('doc-country-count', filter);
  }

  getApplicantsTimePassFlow(filter) {
    return this._baseApplicantsStatisticRequest('time-pass-flow', filter);
  }

  getNewApplicantsPerDay(filter) {
    return this._baseApplicantsStatisticRequest('new-applicants-per-day', filter);
  }

  getApplicantsCountByStatus(filter) {
    return this._baseApplicantsStatisticRequest('count-by-status', filter);
  }

  getApplicantsWarningsCount(filter) {
    return this._baseApplicantsStatisticRequest('warning-count', filter);
  }

  getTotalNumberOfApplicants(filter) {
    return this._baseApplicantsStatisticRequest('total-count', filter);
  }

  getInReviewApplicantsCount(filter) {
    return this._baseApplicantsStatisticRequest('in-review', filter);
  }

  async _baseStatisticsRequest(endpoint, filter) {
    const params = pickBy(filter, identity);

    try {
      const {
        data: { data },
      } = await this.axios.get(`verification/statistics/${endpoint}`, { params });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  failsStageStatistics(filter) {
    return this._baseStatisticsRequest('failsStageStats', filter);
  }

  platformSuccessStatistics(filter) {
    return this._baseStatisticsRequest('platformSuccessStats', filter);
  }

  successStatistics(filter) {
    return this._baseStatisticsRequest('successStats', filter);
  }

  failedStatistics(filter) {
    return this._baseStatisticsRequest('failedStats', filter);
  }

  dateComparedStatistics(filter) {
    return this._baseStatisticsRequest('dateComparedStats', filter);
  }

  failsComparedStatistics(filter) {
    return this._baseStatisticsRequest('failsComparedStats', filter);
  }

  averageCountComparedStatistics(filter) {
    return this._baseStatisticsRequest('averageCountComparedStats', filter);
  }

  averageCountServicesStats(filter) {
    return this._baseStatisticsRequest('servicesStats', filter);
  }
}

export default new DashboardService();
