import _ from 'lodash';
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { BaseEntityStoreBuilder } from '../../../store/helpers';
import { actions as serviceActions } from './servicesSlice';
import amlService from './amlService';

import { AML_CHECK_STATUSES, AML_MODULE_KEY, SERVICES } from '../constants';

const storeBuilder = new BaseEntityStoreBuilder(AML_MODULE_KEY, amlService);
const entityStore = (state) => state[AML_MODULE_KEY];

export const selectors = {
  ...storeBuilder.selectors,
  selectAMLCheckStatus: createSelector(entityStore, (state) => state.status),
  selectAMLFullInfo: createSelector(entityStore, (state) => state.amlFullInfo),
  selectAMLProfilesList: createSelector(entityStore, (state) => state.amlProfilesList),
  selectAMLProfileDetails: createSelector(entityStore, (state) => state.amlProfileDetails),
  selectProfilesTotal: createSelector(entityStore, (state) => state.profilesTotal),
};

export const actions = {
  exportAMLData: storeBuilder.createAction('exportAMLData'),
  getAMLProfileDetails: storeBuilder.createAction('getAMLProfileDetails'),
  getAmlProfilesList: storeBuilder.createAction('getAmlProfilesList'),
  startAMLProcess: storeBuilder.createAction('startAMLProcess'),
  startOngoingMonitoring: storeBuilder.createAction('startOngoingMonitoring'),
  stopOngoingMonitoring: storeBuilder.createAction('stopOngoingMonitoring'),
  updateOngoingMonitoring: storeBuilder.createAction('updateOngoingMonitoring'),
  checkAMLUpdate: createAsyncThunk(
    `${AML_MODULE_KEY}/checkAMLUpdate`,
    async ({ id, callback }, { getState, dispatch }) => {
      const response = await amlService.checkAMLUpdate(id);
      const { period, isActive, aml, status } = { ...response };

      if (status === AML_CHECK_STATUSES.FAIL) {
        callback();
        return;
      }
      const data = { id: SERVICES.AML, changes: { data: aml, status } };
      dispatch(serviceActions.addDataService(data));

      const { info } = getState()['verification-services'];
      const { amlMonitoring } = { ...info };
      dispatch(serviceActions.updateInfo({ amlMonitoring: { ...amlMonitoring, period, isActive } }));
    }
  ),
  updateAMLProfileStatus: storeBuilder.createAction('updateAMLProfileStatus'),
  createCommentInAMLProfile: storeBuilder.createAction('createCommentInAMLProfile'),
  getAMLProfileComments: storeBuilder.createAction('getAMLProfileComments'),
};

const amlSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.startAMLProcess.fulfilled, (state, action) => {
      state.status = action.payload?.status;
    })
    .addCase(actions.checkAMLUpdate.fulfilled, (state, action) => {
      state.status = action.payload?.status;
    })
    .addCase(actions.checkAMLUpdate.rejected, (state) => {
      state.status = AML_CHECK_STATUSES.FAIL;
    })
    .addCase(actions.getAmlProfilesList.fulfilled, (state, { payload, meta }) => {
      const { arg } = meta;
      const {
        queryParams: { offset },
      } = { ...arg };

      state.profilesTotal = payload.total;

      if (!offset) {
        state.amlProfilesList = payload.rows;
      } else {
        state.amlProfilesList = [...state.amlProfilesList, ...payload.rows];
      }
    })
    .addCase(actions.getAMLProfileDetails.fulfilled, (state, action) => {
      state.amlProfileDetails = action.payload;
    })
    .addCase(actions.exportAMLData.fulfilled, (state, action) => {
      state.amlFullInfo = action.payload;
    })
    .addCase(actions.createCommentInAMLProfile.fulfilled, (state, { payload }) => {
      state.amlProfilesList = state.amlProfilesList.map((profile) => {
        if (profile?.id === payload?.amlProfileId) {
          const { comments } = profile;
          return { ...profile, comments: comments ? [payload, ...comments] : [payload] };
        }
        return profile;
      });
    })
    .addCase(actions.updateAMLProfileStatus.fulfilled, (state, { payload }) => {
      const profileIndex = _.findIndex(state.amlProfilesList, { id: payload.id });
      state.amlProfilesList[profileIndex] = {
        ...payload,
        ...payload?.shortInfo,
        comments: state.amlProfilesList[profileIndex].comments,
      };
    })
);

export default amlSlice.reducer;
