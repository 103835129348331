export default {
  ORGANIZATION: 'Organization',
  INDIVIDUAL: 'Individual',
  // NOTIFICATIONS
  PROFILE_STATUS_UPDATED: 'AML Profile status successfully updated',

  TIMELINE: 'Timeline',
  LOGS: 'AML Scan Logs',
  BACK_TO_RESULT: 'Back To Result',
  MONITORING_PERIODICITY: 'Monitoring Periodicity',
  PERIOD: 'Period',
  CONFIRM: 'Confirm',

  // SCAN DETAILS
  SCAN_DATE: 'Scan Date',
  TOTAL_PROFILE_COUNT: 'Profile Matches',
  SCAN_BY: 'Scan By',
  SCAN_ID: 'Scan ID',
  REFERENCE_NUMBER: 'Reference Number',
  SCAN_QUERY: 'Scan query',
  SCAN_TYPE: 'Scan type',
  NAME_SEARCH_TYPE: 'Name scan type',
  SCAN_MATCH_RATES: 'Scan Match Rates',
  FILTERED: 'Filtered',
  NATIONALITIES: 'Nationalities',
  MATCH_STATUS: 'Match Status',
  RISK_LEVEL: 'Risk Level',
  ASSIGNED_USER_GUID_ID: 'Assigned User',
  CASE_STATUS: 'Case Status',
  IS_DEEP_SEARCH: 'Is Deep Search',

  SEARCH: 'Search',
  AML_HISTORY_RESULTS: 'AML History Results',
  MAX_MATCH_RATE: 'Max match rate',
  SEARCHER: 'Searcher',
  LAST_MODIFIED_DATE: 'Last Modified Date',
  SEARCH_PAGE: 'Search Page',
  RESULTS: 'Results',
  BACK_TO_SEARCH: 'Back to Search',
  MONITORING_PERIOD_SUCCESSFULLY_UPDATED: 'Monitoring period successfully updated',
  ALL_MATCH_STATUS: 'All match statuses',
  ALL_STATUS: 'All case statuses',
  ALL_ENTITY_TYPE: 'All entity types',
  ALL_SEARCH_BY: 'Search by - All',

  // DIALOGS MONITORING
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  DISABLE_MONITORING_TITLE: 'Disable Ongoing Monitoring?',
  DISABLE_MONITORING_DESCRIPTION:
    'This action will disable Ongoing Monitoring. Please confirm your decision.',
  ENABLE_MONITORING_TITLE: 'Enable Ongoing Monitoring?',
  ENABLE_MONITORING_DESCRIPTION:
    'This action will initiate Ongoing Monitoring with the periodicity you defined. Please confirm your decision.',

  SCAN: 'Scan',
  CLEAR: 'Clear',
  CANCEL: 'Cancel',
  AML_SCAN: 'AML Scan',
  NAME_SURNAME: 'By name',
  SCAN_RESULT: 'Scan Result',
  DOWNLOAD_PDF: 'Download PDF',
  IDENTITY_NUMBER: 'By Identity',
  PASSPORT_NUMBER: 'By passport',
  CUSTOMIZE_SCAN: 'Customize scan',
  BY_REGISTER_NUMBER: 'By register number',
  HEADER_TITLE: 'Find and Prevent Financial Fraud with',
  HEADER_TIP:
    'Fill in the fields below and start your search to detect and prevent financial fraud efficiently',

  // PERIODICITY
  DAILY: 'Everyday',
  WEEKLY: 'Once a week',
  MONTHLY: 'Once a month',
  QUARTERLY: 'Quarterly',
  HALF_EARLY: 'Half early',
  YEARLY: 'Yearly',

  // PLACEHOLDERS
  FULL_NAME: 'Full name',
  IDENTITY_NUMBER_P: 'Identity number',
  PASSPORT_NUMBER_P: 'Passport number',
  REGISTER_NUMBER: 'Register number',

  // AML PROFILE STATUSES
  UNKNOWN: 'Unknown',
  NO_MATCH: 'No Match',
  POTENTIAL_MATCH: 'Potential Match',
  FALSE_POSITIVE: 'False Positive',
  TRUE_POSITIVE: 'True Positive',
  TRUE_POSITIVE_APPROVE: 'True Positive Approve',
  TRUE_POSITIVE_REJECT: 'True Positive Reject',
  AML_POSITIVE_CASES: 'AML positive cases',

  // AML CASE STATUSES
  UNCONFIRMED: 'Unconfirmed',
  CONFIRMED_FALSE_POSITIVE: 'Confirmed false positive',
  CONFIRMED_TRUE_POSITIVE: 'Confirmed true positive',

  SUMMARY_AND_DETAILS: 'Scan summary & Details',
  NO_RESULTS_FOUND: 'No results found',
  NO_RESULTS_FOUND_DESCRIPTION:
    'Scan completed, your search did not return any results. Try different criteria or check your spelling',

  // FILTERS
  DEEP_SEARCH_LABEL: 'Deep Search',
  DEEP_SEARCH_TOOLTIP:
    'Deep Search is a wide-range scan that works based on letters and words. For example, if you scan &quot;Grace Doe&quot; for the &quot;John Grace Doe Hubo&quot; record, the system will not get the result without selecting the Deep-Search option. Deep-Search will be executed in a longer time than normal scan. ',
  COUNTRIES_PLACEHOLDER: 'Select country',
  COUNTRIES_TOOLTIP: 'Country selection for the related entity (Max 5 countries)',
  EXACT_MATCH_TOOLTIP:
    'An interval of gradual number bounded by the relation between the query string and possible result. Scan results are more precise when fuziness is chosen closer to "Exact Match".',
  BIRTH_PLACEHOLDER: 'Birth Year Or Year Range',
  BIRTH_FILTER_TOOLTIP:
    '<span>Field for the Birth Year date information.</span><span>Birth Year Ex: 1995</span><span>Birth Year Range Ex: 1995-2000</span><span>Birth Date Pattern: DD.MM.YYYY</span>',

  // CONFIRM DIALOGS
  CONFIRM_CHANGE_CASE_STATUS: 'Confirm Case Status Change',
  CONFIRM_CHANGE_CASE_STATUS_DESCRIPTION:
    'Are you sure you want to change the case status for this aml profile?',

  CONFIRM_CHANGE_MATCH_STATUS: 'Confirm Match Status Change',
  CONFIRM_CHANGE_MATCH_STATUS_DESCRIPTION:
    'Are you sure you want to change the match status for this aml profile?',

  // SCAN BY
  SCAN_NAME_SURNAME: 'Name Surname',
  SCAN_PASSPORT_NUMBER: 'Passport Number',
  SCAN_IDENTITY_NUMBER: 'Identity Number',

  EMPTY_STATE_TITLE: 'AML Results',
  EMPTY_STATE_DESCRIPTION:
    'No AML results available. Perform a manual AML scan to view results for applicants.',
};
