export const hasPermission = (permissions, scopes) => {
  if (!permissions || permissions?.length === 0) {
    return false;
  }

  const scopesMap = scopes.reduce((acc, curr) => {
    acc[curr] = true;

    return acc;
  }, {});

  return permissions.some((permission) => scopesMap[permission.name] || permission.name === '*');
};
