import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';

import Avatar from 'app/shared-components/Avatar';
import { AVATAR_TYPES } from 'app/constants/client';
import ActionsMenu from 'app/shared-components/ActionsMenu';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';
import { changeLanguage, selectCurrentLanguage, selectLanguages } from 'app/store/i18nSlice';

import { logoutUser } from '../../auth/store/userSlice';

function UserMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languages = useSelector(selectLanguages);
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);
  const user = useSelector(({ auth }) => auth.user);
  const currentLanguage = useSelector(selectCurrentLanguage);

  const [userMenu, setUserMenu] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(null);

  const closeSubMenu = (event) => {
    event.stopPropagation();
    setSubMenuOpen(null);
  };

  const openSubMenu = (event) => {
    setSubMenuOpen(event.currentTarget);
  };

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleLanguageChange = (event, lng) => {
    dispatch(changeLanguage(lng));

    closeSubMenu(event);
  };

  const isNotLoggedIn = !user?.role || user?.role?.length === 0;

  const logOutClick = () => {
    dispatch(logoutUser());
    userMenuClose();
  };

  const profileClick = () => {
    navigate('/profile');
    userMenuClose();
  };

  const navigateToLogin = () => {
    navigate('/login');
    userMenuClose();
  };

  const navigateToRegister = () => {
    navigate('/register');
    userMenuClose();
  };

  const [englishLang, ukraineLang] = languages;

  const menuItems = isNotLoggedIn
    ? [
        {
          text: t('LOGIN'),
          onClick: navigateToLogin,
          icon: 'heroicons-outline:lock',
        },
        {
          text: t('REGISTER'),
          onClick: navigateToRegister,
          icon: 'heroicons-outline:user-add',
        },
      ]
    : [
        {
          text: t('PROFILE'),
          onClick: profileClick,
          icon: 'heroicons-outline:user-circle',
        },
        {
          text: t('LANGUAGE'),
          onClick: openSubMenu,
          icon: 'feather:globe',
          subMenuLabel: t(currentLanguage?.flag),
          subMenu: [
            {
              text: t('US'),
              icon: 'common:united-states-flag',
              onClick: (event) => handleLanguageChange(event, englishLang.id),
            },
            {
              text: t('UA'),
              icon: 'common:ukrainian-flag',
              onClick: (event) => handleLanguageChange(event, ukraineLang.id),
            },
          ],
        },
        {
          withDivider: true,
          text: t('LOGOUT'),
          onClick: logOutClick,
          icon: 'common:arrow-circle-broken-right',
          isRemove: true,
        },
      ];

  return (
    <Box>
      <Button
        sx={{
          justifyContent: 'space-between',
          borderRadius: '8px',
          padding: '4px 6px',
          minWidth: '148px',
          backgroundColor: userMenu ? 'accent.100' : 'inherit',
        }}
        onClick={userMenuClick}
      >
        <Box display="flex" gap="5px" alignItems="center">
          {user.data.photoURL ? (
            <Avatar
              type={AVATAR_TYPES.ADMIN_USER}
              path={user.data.photoURL}
              alt={user.data.displayName}
              sx={{ height: 32, width: 32 }}
            />
          ) : (
            <Avatar sx={{ height: 32, width: 32 }} className="md:mx-4">
              {user.data.displayName[0]}
            </Avatar>
          )}

          <Box marginRight="20px" display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="subtitle2">{user.data?.displayName}</Typography>
            <Typography variant="caption">{user.role?.name}</Typography>
          </Box>
        </Box>
        <FuseSvgIcon size={10}>common:directions_up_down</FuseSvgIcon>
      </Button>

      <ActionsMenu
        id="user-menu"
        iconsSize={16}
        anchorEl={userMenu}
        menuItems={menuItems}
        subMenuId="user-sub-menu"
        open={Boolean(userMenu)}
        closeMenu={userMenuClose}
        closeSubMenu={closeSubMenu}
        subMenuAnchorEl={subMenuOpen}
        subMenuOpen={Boolean(subMenuOpen)}
      />
    </Box>
  );
}

export default UserMenu;
