const locale = {
  CLIENT_NOT_FOUND: 'Client not found',
  CLIENT_DISABLED: 'Client disabled',
  CLIENT_CREATION_ERROR: 'Create new client was failed',
  UNABLE_TO_DELETE_ROLE_ASSIGNED_TO_USER: 'Unable to delete a role assigned to a user',
  UNABLE_TO_DELETE_QUESTIONNAIRE: 'Questionnaire cannot be deleted as it’s applied to active applicants.',

  TRANSLATION_LOCALE_NOT_FOUND: 'There is no locale',
  TRANSLATION_LOCALE_ALREADY_EXISTS: 'There is already locale',
  APP_SETTINGS_NOT_FOUND: 'Application settings not found',
  APP_SDK_SETTINGS_NOT_FOUND: 'Application SDK settings not found',

  USER_EMAIL_ALREADY_EXISTS: 'This email already exists',
  USER_NOT_FOUND: 'Users not found!',
  USER_WRONG_CREDENTIALS: 'Invalid password', // ???
  USER_WRONG_STATUS: 'User with login credential is. Please, write to your manager!', // ???
  USER_EMPTY_CREDENTIALS: 'Email and phone number are empty',
  USER_EMAIL_NOT_FOUND: 'Email not found',
  USER_PHONE_ALREADY_EXIST: 'This phone number has already exists. Please, use signIn',
  USER_CREATION_ERROR: 'Create user failed',
  USER_NOT_UPDATED: 'User not updated',
  USER_RESTORE_NOT_FOUND: 'Restore with this token not found',
  USER_RESTORE_EXPIRED: 'Restore with this token not found',
  USER_DESTROYING_ERROR: 'Delete user was failed',

  QR_ALREADY_SENT: 'QR code already generated and sent to you',
  REACHED_MAX_ATTEMPTS: 'User has reached the maximum number of attempts',

  APPLICATION_DEMO_EXPIRED: 'Demo period has been expired for this application!',
  APPLICATION_NOT_FOUND: 'Application platform not found', // ???
  APPLICATION_LICENSE_NOT_FOUND: 'License not found',
  APPLICATION_NOT_OWNED: 'Requested app is not owned by provided client',
  APPLICATION_PLATFORM_NOT_FOUND: 'Application platform not found',
  APPLICATION_SETTINGS_NOT_FOUND: 'Delete setting is not provided',
  APPLICATION_SDK_SETTINGS_NOT_FOUND: '', // ???
  APPLICATION_PLATFORM_NOT_REMOVABLE: 'Application platform could not be deleted',
  ADMIN_USER_NOT_FOUND: 'User not found!', // ???
  ADMIN_USER_CREATE_ERROR: '', // ???
  ADMIN_USER_UPDATE_ERROR: 'User not updated',
  ADMIN_USER_DELETE_ERROR: 'Delete user was failed',
  ADMIN_USER_NOT_ACTIVE: 'User not active!', // ???
  ADMIN_BAD_REQUEST: 'Something went wrong...', // ???
  ADMIN_EMAIL_ALREADY_EXISTS: 'An invitation creation has failed, as the email was already used',
  ADMIN_PHONE_ALREADY_EXISTS: 'This phone number has already exists. Please, use signIn',
  ADMIN_INVITE_ERROR: 'An invitation creation has failed',
  ADMIN_INVITE_EXPIRED: 'Invite with token was expired',
  ADMIN_INVITE_NOT_FOUND: 'Invite with id not found',
  ADMIN_RESTORE_WITH_TOKEN_NOT_FOUND: 'Restore not found',
  ADMIN_RESTORE_TOKEN_EXPIRED: 'Restore was expired',
  AUTH_ERROR: '', // ???
  ALREADY_VERIFIED: 'Two factor verification already verified',
  TWO_FACTOR_VERIFICATION_NEEDED: 'Unable to verify code, please complete two factor registration', // ???

  VERIFICATIONS_NOT_FOUND: 'Verification not found',
  VERIFICATION_ALREADY_FINISHED: 'Verification has already finished',
  SERVICE_NOT_FOUND: 'Service not found',
  INVOICE_NOT_FOUND: 'Invoice record by this ID not found',
  TARIFF_PLAN_ALREADY_EXISTS: 'Tariff plan with this name already exists',
  TARIFF_PLAN_NOT_FOUND: 'Tariff plan does not exists', // ???
  TARIFF_PLAN_BAD_REQUEST: 'Something went wrong...', // ???
  TARIFF_PRICE_NOT_FOUND: '', // ???
  OTP_PASSWORD_ALREADY_SEND: '', // ???
  DOCUMENT_BAD_REQUEST: '', // ???
  SMS_SENDING_ERROR: '', // ???
  WEBHOOKS_NOT_FOUND: 'No webhooks found with such type',
  WEBHOOKS_BAD_REQUEST: '', // ???
  WEBHOOK_NOT_AVAILABLE: '', // ???
  VERIFICATION_FLOW_NOT_EXIST: 'Flow not found', // ???
  VERIFICATION_STEP_NOT_EXIST: 'Step not found in flow',
  VERIFICATION_SETTINGS_NOT_EXIST: 'There is no locale', // ???
  VERIFICATION_FLOW_PASS_STEP_FAILED: 'Verification pass step failed',
  VERIFICATION_STEP_NOT_ACCESSIBLE: 'The step is inaccessible from this step',
  MISSING_VERIFICATION_STEP_ID: 'Missing step id param',
  MISSING_UNCOMPLETED_VERIFICATION_STEPS: 'There are no uncompleted steps for this transaction', // ???
  BROWSER_VERIFICATION_FAILED: 'Current browser verification was failed',
  VERIFICATION_SETTINGS_ALREADY_EXIST: 'There is already created locale',
  VERIFICATIONS_ALREADY_PROCESSED: 'Verification was already processed',
  DOCUMENT_TYPE_NOT_FOUND: "DocumentType record didn't found",
  LIVENESS_ATTEMPT_LIMIT_EXCEEDED: 'Liveness attempt limit exceeded',

  DOCUMENT_RECORD_NOT_FOUND: '', // ???
  FACE_RECORD_NOT_FOUND: "Face record didn't found",
  IMAGE_RECORD_NOT_FOUND: "Image record didn't found",
  INN_RECORD_NOT_FOUND: "Inn record didn't found", // inn ???
  OTP_RECORD_NOT_FOUND: "Otp record didn't found", // ??? no error
  PASSIVE_RECORD_NOT_FOUND: 'No biometry results found',
  WORKPLACE_RECORD_NOT_FOUND: '', // ???
  MEDIA_NOT_FOUND: '', // ???
  UNKNOWN_CLIENT_TYPE: 'Unknown client type',
  WORKFLOW_CANNOT_BE_DELETED: 'Workflow cannot be deleted as it’s applied to active applicants.',

  UNDEFINED_STEP_TYPE: '', // ???
  DOCUMENT_READER_ERROR: '', // ???
  DOCUMENT_NOT_FOUND: '', // ???

  SEND_MAIL_ERROR: '', // ???
  ONE_TIME_PASSWORD_ALREADY_SENT: 'One-time password has already send. Please wait 60 seconds',

  SIGN_NOT_FOUND: 'One-time password has already send. Please wait 60 seconds', // ???
  SIGN_CREATE_ATTEMPTS_EXCEEDED: 'Sign could not be created. Max attempts are exceeded',

  SEND_SMS_ERROR: 'Sign could not be created. Max attempts are exceeded', // ???

  REMOTE_FILE_NOT_FOUND: 'File not found', // ???

  UNDEFINED_EXCEPTION: '', // ???
  AXIOS_ERROR: 'No response. Processing...',
  SERVER_RESPONSE_TIMEOUT: 'Server is not processing the data too long',
  OZ_HTTP_SERVICE_ACCESS_ERROR: 'Error accessing oz http service',

  DO_NOT_ALLOW_ASSIGN_REVIEWER: 'You can only assign a reviewer to yourself',
  DO_NOT_ALLOW_REASSIGN: 'Only administrator and manager can reassign a reviewer',
  VERSION_DOES_NOT_MATCH: 'The data has been updated. Request the data again to see the changes',
  REVIEW_NOT_FOUND: "Review record didn't found",
  ONLY_REVIEWER_ALLOW_APPROVED: 'Only the reviewer can approve the review',
  ONLY_REVIEWER_ALLOW_DECLINE: '', // ???
  UNABLE_CHANGE_FINAL_STATUS: 'Unable to change the final status',
  VERIFICATION_DOES_NOT_BELONG_CLIENT: 'Verification does not belong client',
  REVIEWER_DOES_NOT_BELONG_CLIENT: 'Reviewer does not belong client',

  SEARCH_SERVICE_ERROR: '', // ???

  CANNOT_LINK_FLOW_WITH_APPLICANT: 'On-demand flow cannot be applied; please start with regular flow first.',
  AML_PROCESSING_DOES_NOT_FIND: 'Aml Processing does not find',
  TRIAL_LIMIT_ERROR:
    'You have reached the trial limit for creating new verifications. Please upgrade your plan to continue using this feature',
};

export default locale;
