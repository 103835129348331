import { cloneElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { closeDialog, selectDialogOptions, selectOpenedDialog } from 'app/store/fuse/dialogSlice';
import DIALOGS from '../../../app/constants/dialogs';

function FuseDialog({ dialogId, ...props }) {
  const dispatch = useDispatch();
  const openedDialog = useSelector(selectOpenedDialog);
  const options = useSelector(selectDialogOptions);

  const onClose = useCallback(() => props.onClose
    ? props.onClose()
    : dispatch(closeDialog()), []);

  return (
    <Dialog
      open={dialogId === openedDialog}
      onClose={onClose}
      aria-labelledby="fuse-dialog-title"
      classes={{ paper: 'rounded-xl shadow-4' }}
      {...props}
    >
      {props.children && cloneElement(props.children, { onClose })}
    </Dialog>
  );
}

FuseDialog.propTypes = {
  onClose: PropTypes.func,
  dialogId: PropTypes.oneOf(Object.values(DIALOGS))
};

export default FuseDialog;
