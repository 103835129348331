import { createSelector, createSlice } from '@reduxjs/toolkit';

import billingService from './billingService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const BILLING_STATISTICS = 'billing';

const storeBuilder = new BaseEntityStoreBuilder(BILLING_STATISTICS, billingService);

const entityStore = (state) => state[BILLING_STATISTICS];

export const selectors = {
  getStatisticsByMonth: createSelector(entityStore, (state) => state.data),
  errors: createSelector(entityStore, (state) => state.error),
  loading: createSelector(entityStore, (state) => state.loading),
};

export const actions = {
  getStatisticsByMonth: storeBuilder.createAction('getStatisticsByMonth'),
};

const billingSlice = createSlice({
  name: BILLING_STATISTICS,
  initialState: {
    data: null,
    loading: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(actions.getStatisticsByMonth.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(actions.getStatisticsByMonth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actions.getStatisticsByMonth.rejected, (state, { error }) => {
        state.data = null;
        state.error = error.message;
        state.loading = false;
      });
  },
});

export default billingSlice.reducer;
