import { createSlice } from '@reduxjs/toolkit';
import { clearOtpStorage, updateOtpStorage } from 'app/utils/updateOtpStorage';
import jwtService from '../../services/jwtService';
import { setUserData } from './userSlice';

export const sendOtpCode = (model) => async (dispatch) => {
  dispatch(startLoading());

  return jwtService
    .sendOtpCode(model)
    .then(() => {
      dispatch(otpCodeSuccess());
    })
    .catch((err) => dispatch(signUpError(err.message)));
};

export const signUp = (model) => async (dispatch) => {
  dispatch(startLoading());

  return jwtService
    .signUp(model)
    .then((data) => {
      const { token } = { ...data };
      updateOtpStorage(token, model.email);
      dispatch(signUpSuccess());
    })
    .catch((err) => dispatch(signUpError(err.message)));
};

export const verifyEmail = (model, showMessage) => async (dispatch) => {
  dispatch(startLoading());

  return jwtService
    .verifyEmail(model)
    .then((user) => {
      clearOtpStorage();
      dispatch(emailVerified());
      dispatch(setUserData(user));
    })
    .catch((err) => {
      if (err.message) {
        showMessage(err.message, 'error');
      }
      dispatch(signUpError(err.message));
    });
};

const initialState = {
  success: false,
  errors: null,
  loading: false,
  emailVerified: false,
  isOtpCode: false,
};

const signUpSlice = createSlice({
  name: 'auth/signUp',
  initialState,
  reducers: {
    otpCodeSuccess: (state) => {
      state.loading = false;
      state.isOtpCode = true;
    },
    emailVerified: (state) => {
      state.loading = false;
      state.emailVerified = true;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    signUpSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.errors = null;
    },
    signUpError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { otpCodeSuccess, emailVerified, startLoading, signUpSuccess, signUpError } =
  signUpSlice.actions;

export default signUpSlice.reducer;
