import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '../Toolbar';

const SimpleLayout = ({ noData, toolbarProps, withToolbar, title, actions, filters, content }) => (
  <Box>
    {withToolbar && <Toolbar {...toolbarProps} />}
    <Box padding="40px 24px 0 24px">
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
        <Typography variant="h5" color="accent.900">
          {title}
        </Typography>

        {!noData && actions && actions}
      </Box>
      {filters && (
        <Box
          justifyContent="space-between"
          alignItems="center"
          marginBottom="24px"
          display={noData ? 'none' : 'flex'}
        >
          {filters}
        </Box>
      )}

      <Box marginBottom="24px">{content}</Box>
    </Box>
  </Box>
);

export default SimpleLayout;
