/* eslint-disable camelcase */
import axios from 'axios';

const ACCESS_TOKEN_KEY = 'jwt_access_token';

class AxiosManagerService {
  #instances = new Map();

  #mappedHandlers = new Map();

  bindHttpErrorsHandlers(mappedHandlers) {
    this.#mappedHandlers = mappedHandlers;
  }

  getInstance(host, path) {
    const baseURL = `${host}/${path}`;

    if (!this.#instances.has(host)) {
      const instance = axios.create({ baseURL });
      this.#instances.set(host, instance);
      return instance;
    }

    const parentInstance = this.#instances.get(host);
    const instance = axios.create({ baseURL });

    instance.defaults.headers = parentInstance.defaults.headers;
    this.setInterceptors(instance);

    return instance;
  }

  setHeader(name, value) {
    this.#instances.forEach((instance) => {
      instance.defaults.headers.common[name] = value;
    });
  }

  // eslint-disable-next-line no-dupe-class-members
  setHeader(name, value) {
    this.#instances.forEach((instance) => {
      instance.defaults.headers.common[name] = value;
    });
  }

  setInterceptors(axiosInstance) {
    axiosInstance.interceptors.response.use(
      (res) => res,
      async (err) => {
        const statusCode = err.response?.data?.statusCode;
        if (!statusCode) throw err;
        await this.#mappedHandlers.get(err.response?.data?.statusCode)?.();

        if (statusCode === 4001) {
          const access_token = localStorage.getItem(ACCESS_TOKEN_KEY);
          err.config.headers.Authorization = `Bearer ${access_token}`;
          // eslint-disable-next-line consistent-return
          return axios.request(err.config);
        }
        throw err;
      }
    );
  }

  stringifyError = (err) => err.response?.data?.error || err.message;
}

export const AxiosManager = new AxiosManagerService();
