import { lazy } from 'react';
import i18next from 'i18next';

import { PERMISSIONS } from '../teams/constants/permissions';
import { WORKFLOW_BUILDER_KEY, PATHS } from './constants';

const Workflows = lazy(() => import('./Workflows'));
const FlowsList = lazy(() => import('./FlowsList/FlowsList'));
const FlowBuilder = lazy(() => import('./FlowBuilder/FlowBuilder'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, WORKFLOW_BUILDER_KEY, require(`./i18n/${lang}`).default)
);

export const WorkflowBuilderNavigationConfig = {
  id: WORKFLOW_BUILDER_KEY,
  title: 'Workflows',
  translate: 'WORKFLOWS',
  type: 'item',
  icon: 'navigation:workflow',
  url: PATHS.FLOWS_LIST,
  auth: [PERMISSIONS.WorkflowsAll.value, PERMISSIONS.ListFlows.value, PERMISSIONS.OnlyTestMode.value],
};

export const WorkflowsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.WorkflowsAll.value, PERMISSIONS.ListFlows.value, PERMISSIONS.OnlyTestMode.value],
  routes: [
    {
      path: PATHS.FLOWS_LIST,
      element: <Workflows />,
      children: [
        {
          path: '',
          element: <FlowsList />,
        },
        {
          path: PATHS.FLOW_BUILDER,
          element: <FlowBuilder />,
        },
      ],
    },
  ],
};
