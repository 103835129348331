import { createAsyncThunk } from '@reduxjs/toolkit';

import history from '@history';
import { updateUserPhoto } from 'src/app/auth/store/userSlice';
import usersService from './usersService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const USERS_MODULE_KEY = 'users';

const storeBuilder = new BaseEntityStoreBuilder(USERS_MODULE_KEY, usersService);

export const { selectors } = storeBuilder;
export const actions = {
  getUserById: storeBuilder.actions.getById,
  getAllUsers: storeBuilder.actions.getAll,
  updateUser: storeBuilder.actions.update,
  updateUserPhoto: createAsyncThunk(
    `${USERS_MODULE_KEY}/updatePhoto`,
    async ({ isCurrentUser, ...model }, { dispatch }) => {
      const response = await usersService.updatePhoto(model);
      if (response && isCurrentUser) {
        dispatch(updateUserPhoto(response.changes.photo));
      }
      return response;
    }
  ),
  updateUserStatus: storeBuilder.createAction('updateStatus'),
  deleteUser: createAsyncThunk(`${USERS_MODULE_KEY}/delete`, async (id) => {
    const response = await usersService.delete(id);
    if (response) {
      history.push('/users');
    }
    return response;
  }),
};

const usersSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.updateUserStatus.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.updateUserPhoto.fulfilled, storeBuilder.adapter.updateOne)
);

export default usersSlice.reducer;
