import invoiceService from './invoiceService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const INVOICE_MODULE_KEY = 'invoice';

const storeBuilder = new BaseEntityStoreBuilder(INVOICE_MODULE_KEY, invoiceService);

export const { selectors } = storeBuilder;
export const actions = {
  getInvoices: storeBuilder.createAction('getInvoices'),
  downloadInvoice: storeBuilder.createAction('downloadInvoice'),
};

const invoiceSlice = storeBuilder.generateSlice((builder) =>
  builder.addCase(actions.getInvoices.fulfilled, storeBuilder.adapter.setAll)
);

export default invoiceSlice.reducer;
