import { useController } from 'react-hook-form';
import { FormControlLabel, Switch, Typography } from '@mui/material';

const ControlledSwitch = ({ customLabel, name, control, label, ...restProps }) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <FormControlLabel
      className="m-0 max-w-max"
      control={
        <Switch
          {...restProps}
          checked={value}
          onChange={onChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={
        customLabel || (
          <Typography className="ml-5" variant="subtitle2">
            {label}
          </Typography>
        )
      }
    />
  );
};

export default ControlledSwitch;
