import { createTheme, alpha } from '@mui/material/styles';
import qs from 'qs';

const defaultTheme = {
  palette: {
    mode: 'light',
    text: {
      primary: 'rgb(17, 24, 39)',
      secondary: 'rgb(107, 114, 128)',
      disabled: 'rgb(149, 156, 169)',
    },
    common: {
      black: 'rgb(17, 24, 39)',
      white: 'rgb(255, 255, 255)',
    },
    primary: {
      100: '#D1E3FD',
      200: '#A3C6FB',
      300: '#74A2F3',
      400: '#5082E7',
      500: '#1C55D8',
      600: '#1441B9',
      700: '#0E309B',
      800: '#08217D',
      900: '#051667',
    },
    accent: {
      50: '#FAFAFA',
      100: '#F1F5F8',
      150: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#333D53',
      800: '#1F2937',
      900: '#101010',
    },
    secondary: {
      light: '#bdf2fa',
      main: '#22d3ee',
      dark: '#0cb7e2',
    },
    blue: {
      light: '#CFEEFF',
      main: '#3C5073',
    },
    success: {
      main: '#059669',
      dark: '#1DB041'
    },
    gray: {
      100: '#f1f1f1',
      main: '#F1F5F8',
      dark: '#E7ECF1',
      disabledBackground: '#3C5073'
    },
    background: {
      paper: '#FFFFFF',
      default: '#f6f7f9',
    },
    error: {
      light: '#ffcdd2',
      main: '#CF2929',
      dark: '#b71c1c',
    },
    red: {
      main: '#E51C00',
      contrastText: '#FFFFFF',
      dark: '#CF2929',
      100: '#FEF6F5',
      400: '#FAB4AA',
      800: '#E94029',
      700: '#EF5A44',
      900: '#E51C00'
    },
    yellow: {
      main: '#F7C827',
      800: '#FFD391',
      900: '#F2AE00'
    },
    warning: {
      main: '#EC1313',
      100: '#FEF6F6',
      200: '#FFBDA6',
      300: '#FF917A',
      400: '#FF6859',
      500: '#CF2929',
      600: '#DB192A',
      700: '#B7112E',
      800: '#930B2E',
      900: '#7A062E',
    },
    success: {
      100: '#DCFCD3',
      200: '#B3FAA9',
      300: '#80F07C',
      400: '#59E161',
      500: '#059669',
      600: '#1DB041',
      700: '#14933F',
      800: '#0C763A',
      900: '#076237',
    },
    rating: {
      100: '#FF2323',
      200: '#FF6E0C',
      300: '#FF9500',
      400: '#FFAF01',
      500: '#FFCC00',
      600: '#E0E41E',
      700: '#B2D62D',
      800: '#59E161',
      900: '#1DB041',
    },
    /** New palette */
    violet: {
      100: '#F4F2F6',
      200: '#EAEBFA',
      300: '#DCDDFC',
      400: '#C0C2FA',
      500: '#A4A7F6',
      600: '#8D90E9',
      700: '#7377F0',
      800: '#6E65D8',
      900: '#5D3DD4',
    },
    coal: {
      100: '#F1F5F8',
      200: '#E7ECF1',
      300: '#C8CED5',
      400: '#9AA1AB',
      500: '#606874',
      600: '#485263',
      700: '#333D53',
      800: '#222A43',
      900: '#14141E',
    },
    beige: {
      100: '#FFF7F4',
      200: '#FFEDE4',
      300: '#FDE4D9',
      400: '#F0DAD0',
      500: '#DEB9A9',
      600: '#CD977F',
      700: '#B9856E',
      800: '#AD7257',
      900: '#9B4E2B',
    },
    teal: {
      50: '#89c5a7',
      100: '#F4F8F6',
      200: '#EDF8F1',
      300: '#D5F5E1',
      400: '#B8F5CE',
      500: '#9AE9B7',
      600: '#6AC18A',
      700: '#56A673',
      800: '#2E824D',
      900: '#1B6837',
    },
    blue: {
      100: '#F1F5FC',
      200: '#ECF2FE',
      300: '#E4EBFB',
      400: '#DEE8FE',
      500: '#C7D7F9',
      600: '#C3D3EF',
      700: '#9EB1D4',
      800: '#7A92BC',
      900: '#6179A3',
    },
    green: {
      900: '#2E824D'
    }
  },
};

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
  customScrollbars: true,
  direction: 'ltr',
  theme: {
    main: defaultTheme,
    navbar: defaultTheme,
    toolbar: defaultTheme,
    footer: defaultTheme,
  },
};

export function getParsedQuerySettings() {
  const parsedQueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if (parsedQueryString && parsedQueryString.defaultSettings) {
    return JSON.parse(parsedQueryString.defaultSettings);
  }
  return {};

  // Generating route params from settings
  /* const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings); */
}

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
  typography: {
    fontFamily: ['Inter var', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    heading1: {
      fontSize: '88px',
      lineHeight: '96px',
    },
    heading2: {
      fontSize: '57px',
      lineHeight: '60px',
      fontWeight: 700,
    },
    heading3: {
      fontSize: '48px',
      lineHeight: '50px',
      fontWeight: 700,
    },
    heading4: {
      fontSize: '34px',
      lineHeight: '40px',
      fontWeight: 700,
    },
    heading5: {
      fontSize: '23px',
      lineHeight: '32px',
      fontWeight: 700,
    },
    heading6: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
      color: defaultTheme.palette.text.primary,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    button: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    caption1: {
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '16px'
    },
    caption: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
      color: defaultTheme.palette.accent[500]
    },
    overline: {
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'inherit',
      },
      styleOverrides: {
        
        root: {
          textTransform: 'none',
        },

        sizeMedium: {
          borderRadius: 20,
          height: 40,
        },
        sizeSmall: {
          borderRadius: '20px',
          padding: '6px 16px',
          height: 32
        },
        sizeLarge: {
          borderRadius: '28px',
        },
        contained: {
          '&.Mui-disabled': {
            color:defaultTheme.palette.common.white,
          },
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        contained: {
          borderRadius: 18,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: '0px 2px 4px 1px #0404050A, 0px 0px 1px 0px #0404051F'
        },
        rounded: {
          borderRadius: 12,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        displayEmpty: true,
        variant: 'outlined',
        color: 'secondary',
      },
      styleOverrides: {
        filled: {
          paddingTop: '4px',
        },
        outlined: {
          height: 'auto',
          minHeight: 'auto',
        },
        select: {
          height: 'auto',
          minHeight: 'auto',
        },
      },
    },

    // uncomment is future
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '1.4rem',
    //     },
    //   },
    // },

    MuiOutlinedInput: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${defaultTheme.palette.common.white} inset`,
            height: 0,
          },
        },
        root: {
          fontSize: 14,
          height: 40,
          backgroundColor: defaultTheme.palette.background.paper,
          '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: defaultTheme.palette.accent[300],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.palette.accent[300],
            borderWidth: '1px'
          },
          '& fieldset': {
            borderColor: defaultTheme.palette.accent[200],
          },
          '&.MuiInputBase-multiline': {
            height: 'auto',
            padding: '0'
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.palette.accent[200],
          }
        },
        sizeSmall: {
          height: 32,
          fontSize: 14,
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-endAdornment': {
            top: 'unset',
          } ,
          '& .MuiOutlinedInput-root': {
            padding: '0 9px',

            '&.MuiInputBase-sizeSmall':{
              padding: '0 6px'
            }
          }  
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontSize: 14,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-thumb': {
            width: 10,
            height: 10
          },
          width: 28,
          height: 14,
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(14px)',
            }
          },
         
          padding: 0,
        },
        switchBase: {
          color: defaultTheme.palette.common.white,
          '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: defaultTheme.palette.common.white,
            '& + .MuiSwitch-track': {
              backgroundColor: defaultTheme.palette.accent[900],
              opacity: 1,
              border: 0,
            },
          },
        },
        thumb: {
          boxShadow: 'none',
        },
        track: {
          width: 28,
          height: 14,
          borderRadius: 23 / 2,
          opacity: 1,
          backgroundColor: defaultTheme.palette.accent[300]
        },
        checked: {
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          heading1: 'p',
          heading2: 'p',
          heading3: 'p',
          heading4: 'p',
          heading5: 'p',
          heading6: 'p',
          subtitle1: 'p',
          subtitle2: 'p',
          color: defaultTheme.palette.accent[900],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '6px 10px',
          borderRadius: 8,
          backgroundColor: defaultTheme.palette.accent[900]
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        borderRadius: 8,
        padding: '12px 16px',
        outlinedWarning: {
          backgroundColor: defaultTheme.palette.warning[100],
          borderColor: defaultTheme.palette.warning.main
        },
        standardInfo: {
          backgroundColor: defaultTheme.palette.blue[100],
          color: defaultTheme.palette.accent[800],
          alignItems: 'center'
        },
        icon: {
          marginRight: 8,
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px 1px #0404050A, 0px 0px 1px 0px #0404051F'
        }
      }
    }
  },
};

export const mustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    h1: {
      fontSize: '8,8rem',
      lineHeight: '9,6rem',
    },
    h2: {
      fontSize: '5,7rem',
      lineHeight: '6rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '4.8rem',
      lineHeight: '5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '3.4rem',
      lineHeight: '4rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '2.3rem',
      lineHeight: '3.2rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '2rem',
      lineHeight: '3.2rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.5rem',
      lineHeight: '2.8rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    overline: {
      fontSize: '1.2rem',
      lineHeight: '3.2rem',
      fontWeight: 400,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
};

export function extendThemeWithMixins(obj) {
  const theme = createTheme(obj);
  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
  };
}
