import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DOCUMENT_CHECK_MODULE_KEY } from '../constants';
import verificationsService from './verificationsService';

const adapter = createEntityAdapter({
  selectId: (field) => field.id,
});

const updateDocumentField = createAsyncThunk(`${DOCUMENT_CHECK_MODULE_KEY}/update`, async (obj) => {
  const response = await verificationsService.updateDocumentField(obj);
  return response;
});

const documentCheckFields = createSlice({
  name: DOCUMENT_CHECK_MODULE_KEY,
  initialState: adapter.getInitialState(),
  reducers: {
    fillDocumentCheckFields: adapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(updateDocumentField.fulfilled, adapter.updateOne);
  },
});

const { actions: adapterActions } = documentCheckFields;
export const actions = { ...adapterActions, updateDocumentField };

const entityStore = (state) => state[DOCUMENT_CHECK_MODULE_KEY];

const adapterSelectors = adapter.getSelectors(entityStore);

export const selectors = {
  ...adapterSelectors,
  selectById: (id) => (state) => adapterSelectors.selectById(state, id)?.data[0],
};

export default documentCheckFields.reducer;
