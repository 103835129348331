import { useTranslation } from 'react-i18next';

import { Button, Typography, Stack } from '@mui/material';

import ComeBackButton from 'app/shared-components/ComeBackButton';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';
// import NavbarToggleButton from 'app/theme-layouts/shared-components/NavbarToggleButton';

const Toolbar = ({
  hideRightActions,
  state = {},
  disabledBtn,
  onSaveClick,
  backTitle,
  backPath,
  callback,
  customContent,
}) => {
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);

  return (
    <Stack
      bgcolor="background.paper"
      padding="8px 20px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="coal.200"
      width="100%"
      position="sticky"
      top="0"
      zIndex={1000}
      minHeight={{ sm: '48px', md: '61px' }}
    >
      <Stack flexDirection="row" gap="10px">
        {/* <Hidden lgUp>
          <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
        </Hidden> */}
        <ComeBackButton
          state={state}
          callback={callback}
          title={backTitle || t('BACK_TO_LIST')}
          path={backPath}
        />
      </Stack>

      {!hideRightActions && (
        <Stack direction="row">
          {customContent || (
            <Button variant="outlined" color="primary" onClick={onSaveClick} disabled={disabledBtn}>
              <Typography variant="button">{t('SAVE')}</Typography>
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default Toolbar;
