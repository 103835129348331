import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

class UsersService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/user');
  }

  async delete({ id, callback }) {
    try {
      await this.axios.delete(id.toString());
      if (typeof callback === 'function') {
        callback();
      }
      return id;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateStatus({ id, status }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`status/${id}`, status);
      return { id, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updatePhoto({ id, photo }) {
    const formData = new FormData();
    formData.append('photo', photo);

    try {
      const {
        data: { data },
      } = await this.axios.put(`${id}/photo`, formData);
      return { id, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new UsersService();
