import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import restorePasswordService from '../../services/restorePasswordService';

export const sendRestore = createAsyncThunk(
  'auth/sendRestore',
  restorePasswordService.sendRestore.bind(restorePasswordService)
);

export const restoreWithCode = createAsyncThunk(
  'auth/restore',
  restorePasswordService.restoreWithCode.bind(restorePasswordService)
);

const initialState = {
  successEmail: false,
  successPhone: null,
  data: null,
  errors: [],
  loading: false,
  isFulfilled: false,
};

const restoreSlice = createSlice({
  name: 'restore',
  initialState,
  extraReducers: {
    [sendRestore.pending](state, action) {
      state.loading = true;
    },
    [sendRestore.fulfilled](state, action) {
      if (!action.payload.phone) {
        state.successEmail = true;
      } else {
        state.data = action.payload.phone;
      }
      state.loading = false;
    },
    [sendRestore.rejected](state, action) {
      state.errors = [action.error.message];
      state.loading = false;
    },

    [sendRestore.pending](state, action) {
      state.loading = true;
    },

    [restoreWithCode.fulfilled](state, action) {
      state.isFulfilled = true;
      state.loading = false;
    },
    [restoreWithCode.rejected](state, action) {
      state.errors = [action.error.message];
      state.loading = false;
    },
  },
});

export default restoreSlice.reducer;
