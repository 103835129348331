import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors } from 'app/store/modeSlice';
import { useDebounce, useUpdateEffect } from '@fuse/hooks';

import { useTriggerInfinityScroll } from './useTriggerInfinityScroll';

const defaultQueryParams = { offset: 0, limit: 50, endDate: null, startDate: null, search: null };

export const useManageFilteringAndDataFetching = ({ listSelector, totalSelector, fetchList }) => {
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const total = useSelector(totalSelector);
  const currentMode = useSelector(selectors.selectModeType);
  const [queryParams, setQueryParams] = useState(defaultQueryParams);
  const fetch = useDebounce((params) => dispatch(fetchList(params)), 500);

  useTriggerInfinityScroll([list?.length], list?.length < total);

  useUpdateEffect(() => {
    fetch(queryParams);
  }, [queryParams, currentMode]);

  const onSearch = useCallback((event) => {
    setQueryParams((prev) => ({ ...prev, search: event.target.value, offset: 0 }));
  }, []);

  const onDateChange = useCallback(({ startDate, endDate }) => {
    const start = startDate && startDate._d;
    const end = endDate && endDate._d;

    if ((start && end) || (!start && !end)) {
      setQueryParams((prev) => ({ ...prev, startDate: start, endDate: end, offset: 0 }));
    }
  }, []);

  const loadMoreItems = useCallback(() => {
    setQueryParams((prev) => ({
      ...prev,
      offset: prev.offset + prev.limit,
    }));
  }, []);

  return { loadMoreItems, onDateChange, onSearch, total, list };
};
