import { lazy } from 'react';
import i18next from 'i18next';

import { PATHS, TRANSLATION_KEY } from './constants';

const NoPermissionsPage = lazy(() => import('./NoPermissionsPage'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const NoPermissionsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: null,
  routes: [
    {
      path: PATHS.NO_PERMISSIONS_PAGE,
      element: <NoPermissionsPage />,
    },
  ],
};
