import { Box, Typography, styled } from '@mui/material';
import * as SVG from 'app/shared-components/Icons';

const IconWrapper = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.accent[300],
  backgroundColor: theme.palette.accent[200],
  width: '28px',
  height: '28px',
  padding: '6px',
  borderRadius: '50%',
}));

const Wrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
}));

const NoSearchResults = ({ title, description, sx }) => (
  <Wrapper sx={sx}>
    <IconWrapper>
      <SVG.HeroIconsOutline iconId="search" size={14} />
    </IconWrapper>
    <Typography variant="body1" fontWeight={700}>
      {title}
    </Typography>
    <Typography variant="body2" color="accent.500" textAlign="center">
      {description}
    </Typography>
  </Wrapper>
);

export default NoSearchResults;
