import { AxiosManager } from '../axiosManager';

class ImageService {
  constructor() {
    this.axios = AxiosManager.getInstance(process.env.REACT_APP_ADMIN_API_URL, 'api/web/image');
    this.cache = new Map();
  }

  async _getImageUrl(endpoint, path) {
    const cacheKey = `${endpoint}/${path}`;

    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey);
    }

    try {
      const response = await this.axios.get(cacheKey, {
        responseType: 'blob',
      });

      const imageUrl = URL.createObjectURL(response.data);

      this.cache.set(cacheKey, imageUrl);

      return imageUrl;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  getUserPhoto(path) {
    return this._getImageUrl('user/photo', path);
  }

  getClientLogo(path) {
    return this._getImageUrl('client/logo', path);
  }

  getApplicationLogo(path) {
    return this._getImageUrl('application/logo', path);
  }
}

export default new ImageService();
