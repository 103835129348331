import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useState } from 'react';

const Accordion = ({ defaultOpen = true, title, children, sx = {}, ...restProps }) => {
  const [expanded, setExpanded] = useState(defaultOpen);
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiAccordion
      sx={{
        '&.MuiAccordion-root': {
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
          backgroundColor: 'unset',
          border: 'none',
        },
        ...sx,
      }}
      expanded={expanded}
      onChange={toggleAccordion}
      disableGutters
      {...restProps}
    >
      <AccordionSummary
        sx={{
          paddingLeft: 0,
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 0,
          minHeight: '30px',
          height: '30px',
        }}
        expandIcon={<ArrowDropDownIcon />}
      >
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>
      {expanded && <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>}
    </MuiAccordion>
  );
};

export default Accordion;
