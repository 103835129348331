import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { HISTORY_APPLICANT_MODULE_KEY } from '../constants';

const adapter = createEntityAdapter({
  selectId: (logs) => logs.id,
});

const verificationServices = createSlice({
  name: HISTORY_APPLICANT_MODULE_KEY,
  initialState: adapter.getInitialState({
    total: 0,
    isLoading: false,
  }),
  reducers: {
    setHistoryLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    fillHistory: (state, action) => {
      state.total = action.payload?.total || 0;
      state.isLoading = false;
      return adapter.addMany(state, action.payload?.rows || []);
    },
    removeFromHistory: (state, action) => {
      return adapter.removeOne(state, action.payload);
    },
    addToHistory: (state, action) => {
      adapter.addOne(state, action.payload);
    },
    clearHistory: (state) => {
      state.total = 0;
      return adapter.setAll(state, []);
    },
  },
});

export const { actions } = verificationServices;

const entityStore = (state) => state[HISTORY_APPLICANT_MODULE_KEY];

const baseSelectors = adapter.getSelectors(entityStore);

export const selectors = {
  ...baseSelectors,
  selectTotal: createSelector(entityStore, (state) => state.total),
  selectLoadingState: createSelector(entityStore, (state) => state.isLoading),
};

export default verificationServices.reducer;
