import { useCallback, useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import * as usersSlice from 'app/main/teams/store/usersSlice';

import Comment from './components/Comment';
import NoComments from './components/NoComments';
import { TRANSLATION_KEY } from './constants';
import CommentsInput from './components/CommentsInput';

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

const Comments = ({ permissionsScope, commentsList, sendComment, commentsBlockStyles }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const commentsRef = useRef({});
  const feedContainerEndRef = useRef(null);
  const [comment, setComment] = useState('');
  const [parentMessage, setParentMessage] = useState(null);
  const usersList = useSelector(usersSlice.selectors.selectAll);

  useEffect(() => {
    if (!usersList.length) {
      dispatch(usersSlice.actions.getAllUsers());
    }
  }, [usersList]);

  const getUserData = (id) => {
    const { fullName, photo, role } = usersList.find((item) => item.id === id);
    return { fullName, photo, role: role?.name };
  };

  const cancelReplying = useCallback(() => {
    setParentMessage(null);
  }, []);

  const handleChangeInput = useCallback((event) => {
    setComment(event.target.value);
  }, []);

  const replyClick = (parentData) => {
    setParentMessage(parentData);
    inputRef.current.focus();
  };

  const handleScrollToComment = (commentId) => {
    commentsRef.current[commentId].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handlePost = () => {
    const data = {
      content: comment,
      parentId: parentMessage?.commentId,
      callback: () => {
        setComment('');
        cancelReplying();
      },
    };

    sendComment(data);
  };

  const scrollToBottom = useCallback(() => {
    feedContainerEndRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [feedContainerEndRef]);

  useEffect(() => scrollToBottom, [commentsList, scrollToBottom]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...commentsBlockStyles,
      }}
    >
      <Box sx={{ height: '100%', overflowY: 'scroll' }}>
        {!commentsList?.length ? (
          <NoComments />
        ) : (
          commentsList.map(({ createdAt, authorId, content, id, parentComment }, index) => {
            const author = getUserData(authorId);

            return (
              <Comment
                key={index}
                text={content}
                authorId={authorId}
                commentId={id}
                createdAt={createdAt}
                replyClick={replyClick}
                authorRole={author.role}
                commentsRef={commentsRef}
                authorPhoto={author.photo}
                authorFullName={author.fullName}
                parentComment={parentComment}
                onCommentClick={handleScrollToComment}
              />
            );
          })
        )}
        <div ref={feedContainerEndRef} />
      </Box>
      <CommentsInput
        comment={comment}
        inputRef={inputRef}
        handlePost={handlePost}
        parentMessage={parentMessage}
        cancelReplying={cancelReplying}
        handleChangeInput={handleChangeInput}
        permissionsScope={permissionsScope}
      />
    </Box>
  );
};

export default Comments;
