import i18next from 'i18next';

import { QuestionnaireNavigationConfig } from 'app/main/questionnaire/QuestionnaireConfig';

import { AMLNavigationConfig } from 'app/main/aml/AMLConfig';
import { DashboardNavigationConfig } from '../main/dashboard/DashboardConfig';
import { TeamsNavigationConfig } from '../main/teams/TeamsConfig';
import { ApplicationsNavigationConfig } from '../main/applications/ApplicationsConfig';
import { BillingNavigationConfig } from '../main/billing/BillingConfig';
import { ClientDetailsNavigationConfig } from '../main/client-details/ClientDetailsConfig';
import { VerificationsNavigationConfig } from '../main/verifications/VerificationsConfig';
import { WorkflowBuilderNavigationConfig } from '../main/workflow-builder/WorkflowBuilderConfig';

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, 'navigation', require(`./navigation-i18n/${lang}`).default)
);

const navigationConfig = [
  DashboardNavigationConfig,
  VerificationsNavigationConfig,
  ClientDetailsNavigationConfig,
  TeamsNavigationConfig,
  ApplicationsNavigationConfig,
  BillingNavigationConfig,
  WorkflowBuilderNavigationConfig,
  QuestionnaireNavigationConfig,
  AMLNavigationConfig,
  // {
  //   id: 'setting',
  //   title: 'Setting',
  //   subtitle: 'You can get settings there',
  //   translate: 'SETTING',
  //   translateSubtitle: 'SUBTITLE',
  //   type: 'group',
  //   icon: 'apps',
  //   children: [],
  //   auth: authRoles.manager,
  // },
];

export default navigationConfig;
