const locale = {
  LOGIN_TITLE: 'Увійти',
  REDIRECT_SIGN_UP: 'Не зареєстровані?',
  REDIRECT_SIGN_UP_LINK: 'Зареєструватись',
  FORGOT_PASSWORD: 'Забули пароль?',
  LOGIN_BUTTON: 'Увiйти',
  LABEL_LOGIN: 'Пошта/Телефон',
  LABEL_PASS: 'Пароль',
  YOU_MUST_ENTER_CODE: 'Ви повинні ввести код',
  CODE_IS_TOO_SHORT: 'Код занадто короткий - має бути 6 символів',

  YOU_CAN_SEND_LINK_AGAIN: 'Ви зможете знову надіслати посилання через {{seconds}} секунд',
  FORGOT_PASSWORD_TITLE: 'Забули пароль?',
  FORGOT_PASSWORD_SUBTITLE: 'Заповніть форму, щоб скинути пароль',
  SEND_RESET: 'Відправити',
  GO_BACK_LOGIN: 'Повернутись до ',
  MIN_LENGTH_CODE: 'Мінімальна довжина 6 символів',

  RESET_PASSWORD_TITLE: 'Відновлення паролю',
  RESET_PASSWORD_SUBTITLE: 'Створіть новий пароль для свого облікового запису',
  RESET_PASSWORD: 'Змінити пароль',
  GO_BACK: 'Повернутись до ',
  LOGIN: ' авторизації',
  CONFIRM_PASSWORD: 'Підтвердіть пароль',
  RESTORE_CODE: 'Код для відновлення',
  VALIDATION_PASSWORD: 'Мінімальна довжина 8 символів',
  VALIDATION_CONFIRM: 'Паролі не співпадають',

  PASSWORD_BUTTON: 'Підтвердити',
  SIGN_UP: 'Зареєструйтесь',
  SIGN_IN: 'Увійти',
  SIGN_IN_SUBTITLE: 'Вже є аккаунт?',
  FULL_NAME: 'Повне імʼя',
  COMPANY_NAME: 'Компанія',
  PHONE: 'Телефон',
  POSITION: 'Посада',
  EMAIL: 'Електронна пошта',
  PASSWORD: 'Пароль',
  SIGN_UP_BUTTON: 'Зареєструватись',

  REGISTRATION_TITLE: 'Ініціалізація двофакторної автентифікації',
  REGISTRATION_TEXT: 'Будь ласка, використовуючи додаток Google Authenticator відскануйте цей QR-код.',
  VERIFICATION_CODE: 'Код підтвердження',
  VALIDATION_CODE: 'Мінімальна кількість символів - 6 ',
  SECRET_CODE: 'Ви можете використовувати ключ налаштування, щоб вручну налаштувати програму автентифікації',

  // OTP
  HELPER_TEXT_OTP: 'Код підтвердження надіслано на <strong>{{email}}</strong>',
  LABEL_OTP_CODE: 'Введіть код підтвердження',
  CONFIRM: 'Підтвердити',
  OTP_SEND_AGAIN_TEXT: 'Ви можете знову надіслати код через {{seconds}} секунд',
  REQUEST_CODE_AGAIN: 'Запросити код ще раз',

  FULL_NAME_HELPER: 'Ви повинні ввести своє повне імʼя',
  FULL_NAME_ERROR: 'Ви повинні ввести дійсне повне ім’я',

  COMPANY_NAME_HELPER: 'Ви повинні ввести назву компанії',
  COMPANY_NAME_ERROR: 'Ви повинні ввести дійсну назву компанії',

  PHONE_HELPER: 'Ведіть правильний телефон, у форматі +3800000000000',
  PHONE_ERROR: 'Ви повинні ввести дійсний номер телефону',

  POSITION_HELPER: 'Ви повинні ввести свою позицію',
  POSITION_ERROR: 'Ви повинні ввести дійсну позицію',

  EMAIL_HELPER: 'Ви повинні ввести електронну адресу',
  EMAIL_ERROR: 'Ви повинні ввести дійсну адресу електронної пошти',

  PASSWORD_HELPER: 'Будь ласка, введіть свій пароль',
  PASSWORD_ERROR: 'Пароль закороткий – має містити мінімум 8 символів',

  ERROR_LOGIN: 'Номер телефону або електронна пошта',
  HELPER_LOGIN: 'Ви повинні ввести електронну адресу або номер телефону',

  TERMS_CONDITIONS_CHECK: 'Необхідно прийняти умови конфеденційності',
  TERMS_CONDITIONS_AGREE: 'Я погоджуюсь з',
  PRIVACY_STATEMENT: 'Положенням про конфеденційність',

  TERMS_SERVICE_CHECK: 'Необхідно прийняти умови обслуговування',
  TERMS_SERVICE_AGREE: 'Реєструючись на сайті, Ви погоджуєтесь з',
  TERMS_USE: 'Правилами обслуговування',
  AUTH_CONTENT_TITLE_FIRST: 'Ласкаво просимо',
  AUTH_CONTENT_TITLE_SECOND: 'allpass.ai Workplace',
  AUTH_CONTENT_SUBTITLE:
    'Швидко та ефективно залучайте нових клієнтів за допомогою ElKYC. Увійдіть на робоче місце адміністратора, щоб переглядати свої програми, підтвердження та користувачів і керувати ними.',
  AUTH_CONTENT_TITLE: 'Автоматизоване рішення KYC & AML для фінтеху.',
  AUTH_CONTENT_DESCRIPTION: 'Дотримуйтесь вимог разом з вашими клієнтами та світом.',
};

export default locale;
