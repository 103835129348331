import Typography from '@mui/material/Typography';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleChecked from '@mui/icons-material/RadioButtonCheckedOutlined';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

const Checkbox = ({ name, type, label, checked, onChange, ...rest }) => {
  const isCircle = type === 'circle';
  return (
    <FormControlLabel
      control={
        isCircle ? (
          <MUICheckbox
            size="small"
            name={name}
            checked={checked}
            onChange={onChange}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            {...rest}
          />
        ) : (
          <MUICheckbox name={name} checked={checked} onChange={onChange} {...rest} />
        )
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
};

export default Checkbox;
