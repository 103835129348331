import _ from 'lodash';

import { AML_SCAN_SEARCH_BY } from './constants';

export const getValueFieldPlaceholder = (searchBy, isOrganization) => {
  switch (searchBy) {
    case AML_SCAN_SEARCH_BY.NAME_SURNAME:
      return 'FULL_NAME';
    case AML_SCAN_SEARCH_BY.IDENTITY_NUMBER:
      return isOrganization ? 'REGISTER_NUMBER' : 'IDENTITY_NUMBER_P';
    case AML_SCAN_SEARCH_BY.PASSPORT_NUMBER:
      return 'PASSPORT_NUMBER_P';
    default:
      return '';
  }
};

export const getAMLSummaryAndDetailsData = (amlData) => ({
  ..._.pick(amlData, ['value', 'matchStatus', 'isDeepSearch']),
  ..._.pick(amlData.data, [
    'scanId',
    'scanBy',
    'scanType',
    'scanDate',
    'caseStatus',
    'minMatchRate',
    'maxMatchRate',
    'nameSearchType',
    'referenceNumber',
    'totalProfileCount',
    'assignedUserGuidId',
  ]),
});

export const updateCommentsListInProfile = (amlScanResults, newComment) => ({
  ...amlScanResults,
  amlProfiles: amlScanResults.amlProfiles.map((profile) => {
    if (profile.id === newComment.amlProfileId) {
      return { ...profile, comments: [newComment, ...profile.comments] };
    }
    return profile;
  }),
});

export const updateProfileStatus = (amlScanResults, payload) => ({
  ...amlScanResults,
  amlProfiles: amlScanResults.amlProfiles.map((profile) => {
    if (payload.id === profile.id) {
      return payload;
    }
    return profile;
  }),
});
