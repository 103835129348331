const locale = {
  CLIENT_NOT_FOUND: 'Клієнт не знайдено',
  CLIENT_DISABLED: 'Клієнт заблоковано',
  CLIENT_CREATION_ERROR: 'Помилка створення нового клієнта',
  UNABLE_DELETE_ROLE_ASSIGNED_TO_USER: 'Не вдалося видалити роль, призначену користувачеві',
  UNABLE_TO_DELETE_QUESTIONNAIRE:
    'Анкету неможливо видалити, оскільки вона застосовується до активних аплікантів.',

  TRANSLATION_LOCALE_NOT_FOUND: 'Переклади відсутні',
  TRANSLATION_LOCALE_ALREADY_EXISTS: 'Переклади вже додані',
  APP_SETTINGS_NOT_FOUND: 'Налаштування додатку не знайдено',
  APP_SDK_SETTINGS_NOT_FOUND: 'Налаштування SDK додатку не знайдено',

  USER_EMAIL_ALREADY_EXISTS: 'Ця адреса електронної пошти вже існує',
  USER_NOT_FOUND: 'Користувачів не знайдено!',
  USER_WRONG_CREDENTIALS: 'Недійсний пароль', // ???
  USER_WRONG_STATUS: 'Користувач із обліковими даними для входу є. Будь ласка, напишіть своєму менеджеру!', // ???
  USER_EMPTY_CREDENTIALS: 'Електронна пошта та номер телефону порожні',
  USER_EMAIL_NOT_FOUND: 'Електронна пошта не знайдена',
  USER_PHONE_ALREADY_EXIST: 'Цей номер телефону вже існує. Будь ласка, використовуйте авторизацію',
  USER_CREATION_ERROR: 'Не вдалося створити користувача',
  USER_NOT_UPDATED: 'Користувач не оновлений',
  USER_RESTORE_NOT_FOUND: 'Відновлення з цим токеном не знайдено',
  USER_RESTORE_EXPIRED: 'Відновлення з цим токеном не знайдено', // ???
  USER_DESTROYING_ERROR: 'Не вдалося видалити користувача',

  QR_ALREADY_SENT: 'QR-код уже створено та надіслано вам',
  REACHED_MAX_ATTEMPTS: 'Користувач досяг максимальної кількості спроб',

  APPLICATION_DEMO_EXPIRED: 'Демо-період для цього додатку закінчився!',
  APPLICATION_NOT_FOUND: 'Платформу додатку не знайдено', // ???
  APPLICATION_LICENSE_NOT_FOUND: 'Ліцензія не знайдена',
  APPLICATION_NOT_OWNED: 'Запитаний додаток не належить наданому клієнту',
  APPLICATION_PLATFORM_NOT_FOUND: 'Платформу додатку не знайдено',
  APPLICATION_SETTINGS_NOT_FOUND: 'Налаштування видалення не передбачено',
  APPLICATION_SDK_SETTINGS_NOT_FOUND: '', // ???
  APPLICATION_PLATFORM_NOT_REMOVABLE: 'Не вдалося видалити платформу додатку',
  ADMIN_USER_NOT_FOUND: 'Користувач не знайдений!', // ???
  ADMIN_USER_CREATE_ERROR: '', // ???
  ADMIN_USER_UPDATE_ERROR: 'Користувач не оновлений',
  ADMIN_USER_DELETE_ERROR: 'Не вдалося видалити користувача',
  ADMIN_USER_NOT_ACTIVE: 'Користувач не активний!', // ???
  ADMIN_BAD_REQUEST: 'Щось пішло не так...', // ???
  ADMIN_EMAIL_ALREADY_EXISTS:
    'На жаль, не вдалось створити запрошення, оскільки електронна пошта вже використовується',
  ADMIN_PHONE_ALREADY_EXISTS: 'Цей номер телефону вже існує. Будь ласка, використовуйте авторизацію',
  ADMIN_INVITE_ERROR: 'На жаль, не вдалось створити запрошення',
  ADMIN_INVITE_EXPIRED: 'Термін дії запрошення з токеном минув',
  ADMIN_INVITE_NOT_FOUND: 'Запрошення не знайдено',
  ADMIN_RESTORE_WITH_TOKEN_NOT_FOUND: 'Відновлення не знайдено',
  ADMIN_RESTORE_TOKEN_EXPIRED: 'Термін відновлення минув',
  AUTH_ERROR: '', // ???
  ALREADY_VERIFIED: 'Двофакторна верифікація вже перевірена',
  TWO_FACTOR_VERIFICATION_NEEDED: 'Неможливо перевірити код, пройдіть двофакторну реєстрацію', // ???

  VERIFICATIONS_NOT_FOUND: 'Веріфікацію не знайдено',
  VERIFICATION_ALREADY_FINISHED: 'Верифікація вже завершена',
  SERVICE_NOT_FOUND: 'Сервіс не знайдено',
  INVOICE_NOT_FOUND: 'Запис рахунку-фактури за цим ідентифікатором не знайдено',
  TARIFF_PLAN_ALREADY_EXISTS: 'Тарифний план з такою назвою вже існує',
  TARIFF_PLAN_NOT_FOUND: 'Тарифного плану не існує', // ???
  TARIFF_PLAN_BAD_REQUEST: 'Щось пішло не так...', // ???
  TARIFF_PRICE_NOT_FOUND: '', // ???
  OTP_PASSWORD_ALREADY_SEND: '', // ???
  DOCUMENT_BAD_REQUEST: '', // ???
  SMS_SENDING_ERROR: '', // ???
  WEBHOOKS_NOT_FOUND: 'Вебхуків такого типу не знайдено',
  WEBHOOKS_BAD_REQUEST: '', // ???
  WEBHOOK_NOT_AVAILABLE: '', // ???
  VERIFICATION_FLOW_NOT_EXIST: 'Флоу не знайдено', // ???
  VERIFICATION_STEP_NOT_EXIST: 'Крок не знайдено во флоу',
  VERIFICATION_SETTINGS_NOT_EXIST: 'Відсутні переклади', // ???
  VERIFICATION_FLOW_PASS_STEP_FAILED: 'Не вдалося пройти етап верифікації',
  VERIFICATION_STEP_NOT_ACCESSIBLE: 'Крок недоступний з цього кроку',
  MISSING_VERIFICATION_STEP_ID: 'Відсутній параметр ідентифікатора кроку',
  MISSING_UNCOMPLETED_VERIFICATION_STEPS: 'Для цієї транзакції немає незавершених кроків', // ???
  BROWSER_VERIFICATION_FAILED: 'Не вдалося перевірити поточний браузер',
  VERIFICATION_SETTINGS_ALREADY_EXIST: 'Переклади вже існують',
  VERIFICATIONS_ALREADY_PROCESSED: 'Верифікація вже оброблена',
  DOCUMENT_TYPE_NOT_FOUND: 'Запис DocumentType не знайдено',
  LIVENESS_ATTEMPT_LIMIT_EXCEEDED: 'Перевищено ліміт лайвнесс перевірок',
  WORKFLOW_CANNOT_BE_DELETED:
    'Робочий процес неможливо видалити, оскільки він застосовується до активних аплікантів.',

  DOCUMENT_RECORD_NOT_FOUND: '', // ???
  FACE_RECORD_NOT_FOUND: 'Запис обличчя не знайдено',
  IMAGE_RECORD_NOT_FOUND: 'Запис зображення не знайдено',
  INN_RECORD_NOT_FOUND: 'Запис ІНН не знайдено', // inn ???
  OTP_RECORD_NOT_FOUND: 'Запис Otp не знайдено', // ??? no error
  PASSIVE_RECORD_NOT_FOUND: 'Результатів біометрії не знайдено',
  WORKPLACE_RECORD_NOT_FOUND: '', // ???
  MEDIA_NOT_FOUND: '', // ???
  UNKNOWN_CLIENT_TYPE: 'Невідомий тип клієнта',

  UNDEFINED_STEP_TYPE: '', // ???
  DOCUMENT_READER_ERROR: '', // ???
  DOCUMENT_NOT_FOUND: '', // ???

  SEND_MAIL_ERROR: '', // ???
  ONE_TIME_PASSWORD_ALREADY_SENT: 'Одноразовий пароль вже надіслано. Зачекайте 60 секунд',

  SIGN_NOT_FOUND: 'Одноразовий пароль вже надіслано. Зачекайте 60 секунд', // ???
  SIGN_CREATE_ATTEMPTS_EXCEEDED: 'Не вдалося створити підпис. Перевищено максимальну кількість спроб',

  SEND_SMS_ERROR: 'Не вдалося створити підпис. Перевищено максимальну кількість спроб', // ???

  REMOTE_FILE_NOT_FOUND: 'Файл не знайдено', // ???

  UNDEFINED_EXCEPTION: '', // ???
  AXIOS_ERROR: 'Немає відповіді. Обробка...',
  SERVER_RESPONSE_TIMEOUT: 'Сервер не обробляє дані занадто довго',
  OZ_HTTP_SERVICE_ACCESS_ERROR: 'Помилка доступу до служби oz http',

  DO_NOT_ALLOW_ASSIGN_REVIEWER: 'Ви можете призначити рецензента лише собі',
  DO_NOT_ALLOW_REASSIGN: 'Лише адміністратор і менеджер можуть перепризначити рецензента',
  VERSION_DOES_NOT_MATCH: 'Дані оновлено. Знову запитайте дані, щоб побачити зміни',
  REVIEW_NOT_FOUND: 'Запис перевірки не знайдено',
  ONLY_REVIEWER_ALLOW_APPROVED: 'Лише рецензент може схвалити рецензію',
  ONLY_REVIEWER_ALLOW_DECLINE: '', // ???
  UNABLE_CHANGE_FINAL_STATUS: 'Неможливо змінити остаточний статус',
  VERIFICATION_DOES_NOT_BELONG_CLIENT: 'Верифікація не належить клієнту',
  REVIEWER_DOES_NOT_BELONG_CLIENT: 'Рецензент не належить клієнту',

  SEARCH_SERVICE_ERROR: '', // ???

  CANNOT_LINK_FLOW_WITH_APPLICANT:
    'Неможливо застосувати цей флоу, спочатку потрібно застосувати регулярний флоу верифікації.',
  AML_PROCESSING_DOES_NOT_FIND: 'Обробка Aml не знаходить',
  TRIAL_LIMIT_ERROR:
    'Ви досягли ліміту тестового періоду для створення нових верифікацій. Будь ласка, перейдіть на платний план, щоб продовжити користуватися цією функцією.',
};

export default locale;
