export const TRANSLATION_KEY = 'auth-pages';

export const PATHS = {
  OTP: '/otp',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  RESTORE_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/restore/password/',
  RESET_PASSWORD_WITH_TOKEN: '/restore/password/:restoreToken',
  CONFIRM_INVITE: '/confirm-invite/:inviteToken',
};

export const EMAIL_SESSION_KEY = 'email-session-key';
