import { createSelector, createSlice } from '@reduxjs/toolkit';
import { MODE } from 'app/constants/mode';
import { setUser } from 'app/auth/store/userSlice';
import { AxiosManager } from '../services/axiosManager';

AxiosManager.setHeader('X-Workmode', MODE.TEST);

export const changeMode = (mode) => (dispatch) => {
  dispatch(modeSlice.actions.modeChanged(mode));
  AxiosManager.setHeader('X-Workmode', mode);
};

const modeSlice = createSlice({
  name: 'mode',
  initialState: {
    type: MODE.TEST,
    clientKey: null,
    clientStatus: null,
    liveMode: true,
  },
  reducers: {
    modeChanged: (state, action) => {
      state.type = action.payload;
      const workModeKey = state.clientKey;
      localStorage.setItem(workModeKey, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, { payload }) => {
      const clientData = payload.data;

      if (clientData) {
        const { clientKey, clientStatus, liveMode } = clientData;
        state.clientKey = clientKey;
        state.clientStatus = clientStatus;
        state.liveMode = liveMode;
        state.type = localStorage.getItem(clientKey) || MODE.TEST;
        AxiosManager.setHeader('X-Workmode', state.type);
      }
    });
  },
});

const selectMode = (state) => state.mode;
export const selectors = {
  selectLiveMode: createSelector(selectMode, (mode) => mode.liveMode),
  selectModeType: createSelector(selectMode, (mode) => mode.type),
  selectClientStatus: createSelector(selectMode, (mode) => mode.clientStatus),
};

export default modeSlice.reducer;
