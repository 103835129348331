export default {
  APPLICATIONS: 'Applications',
  KEY: 'Key',
  CLIENT_KEY: 'Client key',
  NAME: 'Application name',
  DESCRIPTION: 'Description',
  STATUS: 'Status',
  CREATE: 'Create',
  CREATED_AT: 'Creation date',
  UPDATED_AT: 'Update date',
  DEMO_EXPIRE: 'Demo expire',
  SAVE: 'Save',
  SAVE_CHANGES: 'Save changes',
  PLATFORMS: 'Platforms',
  COPY: 'Copy',
  COMEBACK: 'Back to applications',
  DELETE: 'Delete',
  EDIT: 'Edit',
  ADD: 'Add',
  TYPE: 'Type',
  ADD_PLATFORM: 'Add platform',
  CANCEL: 'Cancel',
  CREATE_AT: 'Create at',
  APPLICATION_ID: 'application Id',

  STATUS_DEVELOP: 'Development',
  STATUS_PRODUCTION: 'Production',
  BUNDLE_ID: 'Bundle id',
  BUNDLE_HELPER: 'Origin must be in URI format',
  ORIGIN: 'Origin',
  ORIGIN_LABEL: 'Origin',

  NAME_HELPER: 'You must enter application name',
  NAME_ERROR: 'You must enter a valid application name',

  DESCRIPTION_HELPER: 'You must enter description',
  DESCRIPTION_ERROR: 'You must enter a valid description',

  ADD_APP: 'Add application',
  UPDATE_APP: 'Update application',
  DELETE_APP: 'Delete application',
  EDIT_APP: 'Edit application',
  NEW_APP: 'New application',

  ADD_ORIGIN: 'Add Origin',
  EDIT_ORIGIN: 'Edit Origin list',
  ERROR_ORIGIN: 'Enter a valid domain name',

  EDIT_PLATFORM: 'Edit platform',
  REDIRECT_URL: 'Redirect URL',
  URL_ERROR: 'You must enter a valid URL',

  CRYPTO_KEY: 'Crypto key',
  WEBHOOK_SETTING: 'Webhook settings',
  WEBHOOK_URL: 'Webhook URL',
  CONFIRM_UPDATE_CRYPTO_KEY: 'Are you sure to update crypto key?',
  UPDATE: 'Update',
  ADD_HEADER: 'Add response headers',
  PRIVATE_APP_KEY: 'Private app key',
  SEARCH_BY_NAME: 'Search by name',

  EMPTY_STATE_TITLE: 'Applications',
  EMPTY_STATE_DESCRIPTION:
    'No applications created yet. Applications help you manage projects across your organization. Get started by creating one.',
};
