const locale = {
  COMPANY: 'Company',
  COMPANY_STATISTICS: 'Analytics dashboard',
  COMPANY_STATISTICS_DESCRIPTION: 'Monitor metrics, check reports and performance',
  SEE_INFO: 'View info',
  PHONE: 'Phone number',
  EMAIL: 'Email',
  ADDRESS: 'Address',
  DESCRIPTION: 'Description',

  ALL_APPLICATIONS: 'All applications',

  TRENDING_UP: 'above target',
  TRENDING_DOWN: 'below target',
  STATISTICS_NOT_FOUND: 'No statistics found',
  STATISTICS_DESCRIPTION: 'Total count',

  DATE_RANGE_COMPARED: 'Successful verifications number compared by date range',
  AVG_COUNT_COMPARED: 'Total verifications compared by average number',
  FAILS_COMPARED: 'Successful verifications number compared with failed',
  AVG_SERVICE_STATS: 'Successful services',

  VERIFICATIONS_OVERVIEW: 'Verifications overview',
  VERIFICATIONS_SUBTITLE: 'Number of successful & failed verifications',
  SUCCESS_STATISTICS_DESCRIPTION: 'Successful verifications number',
  FAILED_STATISTICS_DESCRIPTION: 'Failed verifications number',

  PLATFORM_VERIFICATIONS_OVERVIEW: 'Platform verifications overview',
  PLATFORM_VERIFICATIONS_SUBTITLE: 'Number of verifications for each supported platform',

  FAILED_STAGES_OVERVIEW: 'Failed verifications stages overview',
  FAILED_STAGES_SUBTITLE: 'Number of verifications by each failed stage',

  APPLICATION: 'Application',

  TODAY: 'Today',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  YESTERDAY: 'Yesterday',
  LAST_7_DAYS: 'Last 7 days',
  LAST_28_DAYS: 'Last 28 days',
  DATE_RANGE: 'Choose date range',

  ANDROID: 'Android',
  WEB: 'Web',
  IOS: 'IOS',

  DOCUMENT_CAPTURE: 'Document capture',
  CLEAN_DOCUMENT_CAPTURE: 'Clean document capture',
  BARCODE_CAPTURE: 'Barcode capture',
  CREDIT_CARD_CAPTURE: 'Credit card capture',
  RFID_DOCUMENT_CAPTURE: 'Rfid document capture',
  SPECIFIC_DOCUMENT_CAPTURE: 'Specific document capture',
  SELFIE_CAPTURE: 'Selfie capture',
  FACE_CAPTURE: 'Face capture',
  PASSIVE_FACE_MATCH: 'Face match',
  FACE_LIVENESS: 'Liveness',
  SIGNATURE: 'Signature',
  OTP_VERIFICATION: 'Otp verification',
  UTILITY_BILL: 'Utility bill',
  DIIA: 'Diia',
  PASSIVE_LIVENESS: 'Liveness',

  NO_SERVICE: 'Session has expired',

  // WARNINGS
  AML_RESULT_UPDATED: 'AML result updated',
  AML_CANNOT_STARTED: 'AML cannot started',
  AML_POSITIVE_CASES: 'AML positive cases',
  APPLICANT_DUPLICATE_PHONE: 'Applicant duplicate phone',
  APPLICANT_DUPLICATE_DOCUMENT: 'Applicant duplicate document',
  EXPIRED_DOCUMENT: 'Expired document',
  APPLICANT_DUPLICATE_EMAIL: 'Applicant duplicate email',

  // APPLICANTS STATISTICS
  APPLICANTS_CREATED: 'Applicants Created',
  REJECTED: 'Rejected',
  ACCEPTED: 'Accepted',
  RESOLVED: 'Resolved',
  UNRESOLVED: 'Unresolved',
  DOCUMENT_ORIGIN: 'Document origin',
  PHONE_NUMBER_ORIGIN: 'Phone number origin',
  APPLICANTS_DISTRIBUTION: 'Applicants distribution',
  TOTAL_APPLICANTS_NUMBER: 'Applicants Number',
  TOTAL_APPLICANTS_APPROVED: 'Applicants Approved',
  TOTAL_APPLICANTS_REJECTED: 'Applicants Rejected',
  TOTAL_APPLICANTS_COMPLETED: 'Applicants Completed',
  TOTAL_APPLICANTS_IN_REVIEW: 'Applicants In Review',
  PHONE_NUMBER_ORIGIN_BY_COUNTRY: 'Phone Number Origin by Country',
  PHONE_NUMBER_ORIGIN_BY_COUNTRY_DESC: 'Number of applicant phone numbers categorized by country code.',
  APPLICANTS_STATUS_OVERVIEW: 'Applicant Status Overview',
  APPLICANTS_STATUS_OVERVIEW_DESC: 'Breakdown of applicants based on their current verification status.',
  APPLICANTS_DISTRIBUTION_BY_COUNTRY: 'Applicants Distribution by Country',
  APPLICANTS_DISTRIBUTION_BY_COUNTRY_DESC:
    'Geographical distribution of applicants based on their country of residence.',
  TOTAL_APPLICANT_WARNINGS: 'Total Applicant Warnings',
  TOTAL_APPLICANT_WARNINGS_DESC: 'Count of warnings triggered across all applicants.',
  DOCUMENT_ORIGIN_BY_COUNTRY: 'Document Origin by Country',
  DOCUMENT_ORIGIN_BY_COUNTRY_DESC: 'Number of documents categorized by country of issuance.',
  APPLICANTS_CREATED_PER_DAY: 'Number of Applicants Created Per Date',
  APPLICANTS_CREATED_PER_DAY_DESC:
    'This chart shows the daily count of applicants created, highlighting trends and peak activity dates.',
};

export default locale;
