import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODE } from 'app/constants/mode';
import { CLIENT_STATUS } from 'app/constants/client';
import { Box, Switch, Typography } from '@mui/material';
import { selectors, changeMode } from 'app/store/modeSlice';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';

const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true';

export default function SwitchStatusApplication() {
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);
  const currentMode = useSelector(selectors.selectModeType);
  const status = useSelector(selectors.selectClientStatus);
  const liveMode = useSelector(selectors.selectLiveMode);

  const handleChangeStatus = () => {
    if (liveMode) {
      const newMode = currentMode === MODE.LIVE ? MODE.TEST : MODE.LIVE;
      dispatch(changeMode(newMode));
    }
  };

  const isProduction = currentMode === MODE.LIVE;

  if (status !== CLIENT_STATUS.ACTIVE && !DEV_MODE) {
    return null;
  }

  return (
    <Box
      sx={{
        gap: '4px',
        height: '30px',
        display: 'flex',
        cursor: !liveMode ? 'not-allowed' : 'pointer',
        padding: '8px 10px',
        borderRadius: '6px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'accent.100',
      }}
      onClick={handleChangeStatus}
    >
      <Switch
        disabled={!liveMode}
        size="small"
        checked={isProduction}
        value={isProduction}
        inputProps={{ 'aria-label': 'controlled' }}
      />

      <Typography variant="body2" fontWeight={600} color={isProduction ? 'accent.900' : 'text.disabled'}>
        {t('STATUS_APP')}
      </Typography>
    </Box>
  );
}
