import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const STORAGE_KEY = 'language';

export const languageList = [
  { id: 'en-US', title: 'English', flag: 'US', shortName: 'en' },
  { id: 'uk-UA', title: 'Ukrainian', flag: 'UA', shortName: 'ua' },
];

export const setLanguageToStorage = (lang) => localStorage.setItem(STORAGE_KEY, lang);

const getDefaultLanguage = () => {
  const defaultLocale = languageList[0].id;
  const langFromStorage = localStorage.getItem(STORAGE_KEY) || defaultLocale;
  const isCorrectLang = languageList.find((item) => item.id === langFromStorage);
  if (!isCorrectLang) setLanguageToStorage(defaultLocale);
  return isCorrectLang ? langFromStorage : defaultLocale;
};

export const defaultLanguage = getDefaultLanguage();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: defaultLanguage,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  });

export default i18n;
