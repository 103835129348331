import React from 'react';
import Grid from '@mui/material/Grid';

const ListGridWrapper = ({ uniqKey, children, list, ...restProps }) => (
  <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12, lg: 16 }} {...restProps}>
    {list.map((item, index) => (
      <Grid item xs={4} sm={4} md={4} key={item[uniqKey] || index}>
        {React.cloneElement(children, { ...item, keyValue: item?.key })}
      </Grid>
    ))}
  </Grid>
);

export default ListGridWrapper;
