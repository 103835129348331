const locale = {
  CANCEL: 'Скасувати',
  QUESTIONNAIRE: 'Анкетy',
  QUESTIONNAIRES: 'Анкети',
  YES_DELETE: 'Так, видалити',
  SEARCH_BY_NAME: 'Пошук за назвою',
  CREATE_QUESTIONNAIRE: 'Створити анкету',
  DELETE_QUESTIONNAIRE: 'Видалити анкету',
  DELETE_DRAFT_QUESTIONNAIRE: 'Видалити чорнову анкету',
  SURVEY_TITLE_IS_REQUIRED_FIELD: 'Заголовок є обов"язковим полем',
  CONFIRM_QUESTIONNAIRE_DELETE: 'Ви впевнені, що хочете видалити анкету?',
  CONFIRM_DRAFT_QUESTIONNAIRE_DELETE: 'Ви впевнені, що хочете видалити чорнову анкету?',
  EMPTY_STATE_DESCRIPTION:
    'Ще не створено жодної анкети. Анкети допомагають збирати детальну інформацію про заявників. Створіть анкету, щоб розпочати.',
};

export default locale;
