import { combineReducers } from '@reduxjs/toolkit';

import auth from 'app/auth/store';
import logsSlice from 'app/main/logs/store/logsSlice';
import verifications from 'app/main/verifications/store';
import { LOGS_MODULE_KEY } from 'app/main/logs/constants';
import amlScanSlice from 'app/main/aml/store/amlScanSlice';
import { AML_SCAN_MODULE_KEY } from 'app/main/aml/constants';
import users, { USERS_MODULE_KEY } from 'app/main/teams/store/usersSlice';
import invites, { INVITES_MODULE_KEY } from 'app/main/teams/store/invitesSlice';
import billing, { BILLING_STATISTICS } from 'app/main/billing/store/billingSlice';
import invoice, { INVOICE_MODULE_KEY } from 'app/main/billing/store/invoiceSlice';
import flow, { FLOW_MODULE_KEY } from 'app/main/workflow-builder/store/flowSlice';
import tariffPlan, { TARIFF_PLAN_MODULE_KEY } from 'app/main/billing/store/tariffPlanSlice';
import dashboardSlice, { DASHBOARD_MODULE_KEY } from 'app/main/dashboard/store/dashboardSlice';
import applications, { APPLICATIONS_MODULE_KEY } from 'app/main/applications/store/applicationsSlice';
import roleManagement, { ROLE_MANAGEMENT_MODULE_KEY } from 'app/main/teams/store/roleManagementSlice';
import clientDetails, { CLIENT_DETAILS_MODULE_KEY } from 'app/main/client-details/store/clientDetailsSlice';
import questionnaire, { QUESTIONNAIRES_MODULE_KEY } from 'app/main/questionnaire/store/questionnaireSlice';

import fuse from './fuse';
import i18n from './i18nSlice';
import mode from './modeSlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    [USERS_MODULE_KEY]: users,
    [INVITES_MODULE_KEY]: invites,
    [CLIENT_DETAILS_MODULE_KEY]: clientDetails,
    [DASHBOARD_MODULE_KEY]: dashboardSlice,
    [APPLICATIONS_MODULE_KEY]: applications,
    [TARIFF_PLAN_MODULE_KEY]: tariffPlan,
    [BILLING_STATISTICS]: billing,
    [INVOICE_MODULE_KEY]: invoice,
    [FLOW_MODULE_KEY]: flow,
    [QUESTIONNAIRES_MODULE_KEY]: questionnaire,
    [ROLE_MANAGEMENT_MODULE_KEY]: roleManagement,
    [AML_SCAN_MODULE_KEY]: amlScanSlice,
    [LOGS_MODULE_KEY]: logsSlice,
    ...verifications,
    auth,
    fuse,
    i18n,
    mode,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
