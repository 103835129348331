import { isValidElement } from 'react';
import { Box } from '@mui/material';
import PermissionsGate from 'app/main/teams/components/PermissionsGate';
import { PERMISSIONS } from 'app/main/teams/constants/permissions';
import SwitchStatusApplication from 'app/theme-layouts/shared-components/SwitchStatusApplication';
import UserMenu from 'app/theme-layouts/shared-components/UserMenu';

export default function DefaultHeaderContent({ content: Content }) {
  if (Content && typeof Content === 'function' && isValidElement(<Content />)) {
    return <Content />;
  }

  return (
    <Box display="flex" gap="20px" alignItems="center">
      <PermissionsGate scopesExclude={[PERMISSIONS.OnlyTestMode.value]}>
        <SwitchStatusApplication />
      </PermissionsGate>
      <UserMenu />
    </Box>
  );
}
