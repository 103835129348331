export default {
  STATISTICS_TITLE: 'Статистика транзакцій по додатках',
  PRICING_TITLE: 'Тарифні плани клієнта',
  PRICING_PLAN_PER_ITEMS: 'Ціна за одиницю',
  PRICING_MONTHLY_FEE: 'Щомісячна плата',
  PRICING_MAX_VERIFICATION: 'Максимальна кількість верифікацій',

  INVOICE_TITLE: 'Рахунок-фактура',
  INVOICE_ACCOUNT: 'Номер рахунку',
  BANK_TRANSFER: 'Банківський трансфер',
  NEW_INVOICE: 'новий',
  BILLING_STATISTICS: 'Статистика рахунків клієнта',
  STATISTICS_COUNT: 'Рахунок',

  PRICING_MONTHLY_TOTAL: 'Всього за місяць',
  PRICING_EXTRA_FEE: 'Додаткова плата',
  PRICING_TEST_VERIFICATION: 'Тестова кількість верифікацій',
};
