import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import FuseDialog from '@fuse/core/FuseDialog';
import * as SVG from 'app/shared-components/Icons';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';

const Dialog = ({
  id,
  title,
  onClose,
  titleColor = 'accent.900',
  titleIcon,
  children,
  actions,
  showCloseIcon = true,
  justifyButtons = 'flex-start',
  submitButtonProps,
  cancelButtonProps,
  content,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);

  const onCloseHandler = useCallback(() => {
    onClose?.();
    dispatch(closeDialog());
  }, [dispatch, onClose]);

  return (
    <FuseDialog {...restProps} fullWidth dialogId={id} onClose={onCloseHandler}>
      <Box padding="22px">
        <DialogTitle
          sx={{ '&.MuiDialogTitle-root': { paddingX: 0, paddingTop: 0 } }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="static"
        >
          <Box display="flex" alignItems="center" gap="5px">
            {titleIcon && titleIcon}
            <Typography variant="heading5" color={titleColor}>
              {title}
            </Typography>
          </Box>
          {showCloseIcon && (
            <IconButton sx={{ color: 'accent.900' }} onClick={onCloseHandler}>
              <SVG.HeroIconsOutline iconId="x" size={24} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ paddingX: 0 }}>{content || children}</DialogContent>
        {actions && actions}
        {submitButtonProps && (
          <DialogActions sx={{ paddingX: 0, paddingBottom: 0, justifyContent: justifyButtons }}>
            <Button variant="contained" {...submitButtonProps}>
              <Typography variant="button">{submitButtonProps?.children}</Typography>
            </Button>
            <Button color="gray" variant="contained" onClick={onCloseHandler} {...cancelButtonProps}>
              <Typography variant="button">{cancelButtonProps?.children || t('CANCEL')}</Typography>
            </Button>
          </DialogActions>
        )}
      </Box>
    </FuseDialog>
  );
};

export default Dialog;
