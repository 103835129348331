import Box from '@mui/material/Box';
import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import PermissionsGate from 'app/main/teams/components/PermissionsGate';

const ActionsMenu = ({
  id = 'actions-menu',
  closeMenu,
  open,
  scopes,
  anchorEl,
  menuItems = [],
  children,
  subMenuId,
  iconsSize,
  subMenuOpen,
  closeSubMenu,
  closeOnItemClick = false,
  subMenuAnchorEl,
  ...restProps
}) => (
  <MenuMui
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={closeMenu}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    {...restProps}
  >
    {(children && children) ||
      menuItems.map(({ text, onClick, icon, isRemove, subMenu, subMenuLabel, withDivider }) => (
        <PermissionsGate scopes={scopes} key={text}>
          <MenuItem
            onClick={(e) => {
              if (closeOnItemClick) {
                closeMenu();
              }

              onClick(e);
            }}
            sx={{
              color: isRemove ? 'warning.500' : 'accent.900',
              borderTop: withDivider ? `1px solid` : 'none',
              borderColor: 'accent.200',
              backgroundColor: subMenu && subMenuOpen ? 'accent.100' : 'inherit',
            }}
          >
            <FuseSvgIcon size={iconsSize || 13} className="mr-8">
              {icon}
            </FuseSvgIcon>
            <Box display="flex" alignItems="center" gap="40px">
              <Typography variant="body2">{text}</Typography>
              {subMenuLabel && (
                <Typography variant="caption" display="flex" alignItems="center">
                  {subMenuLabel}
                  <FuseSvgIcon size={12}>material-outline:keyboard_arrow_right</FuseSvgIcon>
                </Typography>
              )}
            </Box>

            {subMenu && (
              <MenuMui
                id={subMenuId}
                open={subMenuOpen}
                onClose={closeSubMenu}
                anchorEl={subMenuAnchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
              >
                {subMenu.map(({ text: subMenuText, onClick: subMenuAction, icon: subMenuIcon }) => (
                  <MenuItem onClick={subMenuAction} key={subMenuIcon}>
                    <FuseSvgIcon size={iconsSize || 13} className="mr-8">
                      {subMenuIcon}
                    </FuseSvgIcon>
                    <Typography variant="body2">{subMenuText}</Typography>
                  </MenuItem>
                ))}
              </MenuMui>
            )}
          </MenuItem>
        </PermissionsGate>
      ))}
  </MenuMui>
);

export default ActionsMenu;
