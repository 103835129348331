import { useEffect, useMemo } from 'react';

export const useTriggerInfinityScroll = (deps, hasMoreItems) => {
  const mainElement = useMemo(() => document.querySelector('main'), []);

  useEffect(() => {
    const hasScroll = mainElement ? mainElement.scrollHeight > mainElement.clientHeight : false;
    if (!hasScroll && hasMoreItems) {
      window.dispatchEvent(new CustomEvent('scroll'));
    }
  }, deps);
};
