import { AxiosManager } from 'app/services/axiosManager';
import BaseApi from 'app/services/common/base-api';

class RoleManagementService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/role');
  }

  async updateRole({ role, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`/${role.roleId}`, role);

      callback();

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async createRole({ role, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(``, role);

      callback();

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getRoleById(roleId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`/${roleId}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deleteRole(roleId) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`/${roleId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getRoles(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/', { params: queryParams });

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new RoleManagementService();
