import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

const PATHS_COMMENTS = '/applicants';

class CommentsService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_SERVICE);
  }

  async getAllComments({ applicantId }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_COMMENTS}/${applicantId}/comments`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getById({ applicantId, commentId }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_COMMENTS}/${applicantId}/comments/${commentId}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async addComment({ applicantId, content, parentId, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_COMMENTS}/${applicantId}/comments`, { content, parentId });
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new CommentsService();
