import { lazy } from 'react';
import i18next from 'i18next';

import { PATHS, TRANSLATION_KEY } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

const Dashboard = lazy(() => import('./Dashboard'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const DashboardNavigationConfig = {
  id: 'dashboard',
  title: 'Dashboard',
  translate: 'DASHBOARD',
  type: 'item',
  icon: 'navigation:dashboard',
  url: PATHS.DASHBOARD,
  auth: [PERMISSIONS.ListAnalytics.value, PERMISSIONS.AnalyticsAll.value, PERMISSIONS.OnlyTestMode.value],
};

export const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.ListAnalytics.value, PERMISSIONS.AnalyticsAll.value, PERMISSIONS.OnlyTestMode.value],
  routes: [
    {
      path: PATHS.DASHBOARD,
      element: <Dashboard />,
    },
  ],
};
