import {
  SERVICES_MODULE_KEY,
  VERIFICATION_MODULE_KEY,
  DOCUMENT_CHECK_MODULE_KEY,
  APPLICANT_MODULE_KEY,
  COMMENTS_MODULE_KEY,
  HISTORY_APPLICANT_MODULE_KEY,
  AML_MODULE_KEY,
} from '../constants';
import aml from './amlSlice';
import applicants from './applicantsSlice';
import comments from './commentsSlice';
import verifications from './verificationsSlice';
import verificationServices from './servicesSlice';
import historyApplicant from './historySlice';
import documentCheckFields from './DocumentCheckSlice';

export default {
  [APPLICANT_MODULE_KEY]: applicants,
  [HISTORY_APPLICANT_MODULE_KEY]: historyApplicant,
  [COMMENTS_MODULE_KEY]: comments,
  [VERIFICATION_MODULE_KEY]: verifications,
  [SERVICES_MODULE_KEY]: verificationServices,
  [DOCUMENT_CHECK_MODULE_KEY]: documentCheckFields,
  [AML_MODULE_KEY]: aml,
};
