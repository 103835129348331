import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/utils/FuseUtils';
import { AxiosManager } from '../axiosManager';
/* eslint-disable camelcase */

export const ACCESS_TOKEN_KEY = 'jwt_access_token';
const REFRESH_TOKEN_KEY = 'jwt_refresh_token';

class JwtService extends FuseUtils.EventEmitter {
  constructor() {
    super();
    this.axios = AxiosManager.getInstance(process.env.REACT_APP_ADMIN_API_URL, 'api/web');
    this.axios_v1 = AxiosManager.getInstance(process.env.REACT_APP_ADMIN_API_URL, 'v1/api/web');
    AxiosManager.setInterceptors(this.axios);
  }

  init() {
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  /**
   * @deprecated Should be reworked, as such endpoint does not exists
   */
  createUser = (data) => {
    return new Promise((resolve, reject) => {
      this.axios.post('/user/register', data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  updateUserData = (user) => this.axios.put(`/user/${user.id}`, { user });

  signInWithEmailAndPassword = async ({ reCaptcha, ...model }) => {
    try {
      const {
        data: { data },
      } = await this.axios_v1.post('/auth/signIn', model, addRecaptchaHeader(reCaptcha));
      this.setSession(data.access_token);
      this.setRefresh(data.refresh_token);

      return data;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  signIn2faCode = async (model) => {
    try {
      const {
        data: { data },
      } = await this.axios.post('/auth/2fa/signIn', model);
      this.setSession(data.access_token);
      this.setRefresh(data.refresh_token);

      return data.user;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  signUp = async ({ reCaptcha, ...model }) => {
    try {
      const {
        data: { data },
      } = await this.axios_v1.post('auth/signUp', model, addRecaptchaHeader(reCaptcha));

      return data;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  sendOtpCode = async ({ reCaptcha, ...model }) => {
    try {
      const {
        data: { statusCode },
      } = await this.axios_v1.post('auth/otp', model, addRecaptchaHeader(reCaptcha));
      return statusCode;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  turnOff2fa = async () => {
    try {
      const {
        data: { data },
      } = await this.axios.put('auth/2fa/disable', {
        accessToken: this.getAccessToken(),
      });
      return data;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  register2faCode = async (model) => {
    try {
      const {
        data: { data },
      } = await this.axios.put('/auth/2fa/register', {
        accessToken: this.getAccessToken(),
      });
      return data;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  confirm2fAuth = async (model) => {
    try {
      const {
        data: { data },
      } = await this.axios.put('/auth/2fa/confirm', model);
      this.setSession(data.access_token);
      this.setRefresh(data.refresh_token);

      return data.user;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  signInWithToken = async () => {
    try {
      const {
        data: { data },
      } = await this.axios.post('/auth/access-token', {
        accessToken: this.getAccessToken(),
      });

      return data.user;
    } catch (err) {
      this.logout();
      throw new Error('Failed to login with token.');
    }
  };

  signInWithRefreshToken = async () => {
    const refreshToken = this.getRefreshToken();
    try {
      const {
        data: { data },
      } = await this.axios.post('/auth/refreshAccessToken', { refreshToken });
      this.setSession(data.access_token);
      this.setRefresh(data.refresh_token);

      return data.user;
    } catch (err) {
      this.logout();
      throw new Error('Failed to refresh token and login.');
    }
  };

  confirmInvite = async ({ token, newUser, reCaptcha }) => {
    try {
      const {
        data: { data },
      } = await this.axios_v1.post(`invite/confirm/${token}`, newUser, addRecaptchaHeader(reCaptcha));
      this.setSession(data.access_token);
      this.setRefresh(data.refresh_token);

      return data.user;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  verifyEmail = async ({ reCaptcha, ...model }) => {
    try {
      const {
        data: { data },
      } = await this.axios_v1.post(`auth/verify-email`, model, addRecaptchaHeader(reCaptcha));
      this.setSession(data.access_token);
      this.setRefresh(data.refresh_token);

      return data.user;
    } catch (err) {
      throw new Error(AxiosManager.stringifyError(err));
    }
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
      this.axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      delete this.axios.defaults.headers.common.Authorization;
    }
  };

  setRefresh = (refreshToken) => {
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      return;
    }
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  };

  logout = () => {
    this.setSession(null);
    this.setRefresh(null);
  };

  getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

  getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
}

export const addRecaptchaHeader = (token, config = {}) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers['X-Recaptcha-Token'] = token;

  return config;
};

const instance = new JwtService();

export default instance;
