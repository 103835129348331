export const ExceptionCode = {
  BadRequest: 400,
  Unauthorized: 401, // Don't translate
  Forbidden: 403,

  ClientNotFound: 1000,
  ClientDisabled: 1001,
  ClientCreationError: 1002,
  TrialLimitError: 1003,

  TranslationLocaleNotFound: 1005,
  TranslationLocaleAlreadyExists: 1006,
  AppSettingsNotFound: 1007,
  AppSdkSettingsNotFound: 1008,
  UserEmailAlreadyExists: 1009,
  UserNotFound: 1010,
  UserWrongCredentials: 1011, // несколько значений?
  UserWrongStatus: 1012, // что писать в ошибке?
  UserEmptyCredentials: 1013,
  UserEmailNotFound: 1014,
  UserPhoneAlreadyExist: 1015,
  UserCreationError: 1016,
  UserNotUpdated: 1017,
  UserRestoreNotFound: 1018,
  UserRestoreExpired: 1019,
  UserDestroyingError: 1020,
  UserNotVerifiedEmail: 1022,

  QRAlreadySent: 1050,
  ReachedMaxAttempts: 1051,

  ApplicationDemoExpired: 2000,
  ApplicationNotFound: 2001,
  ApplicationLicenseNotFound: 2002, // ApplicationLicenceNotFound
  ApplicationNotOwned: 2003,
  ApplicationPlatformNotFound: 2004,
  ApplicationSettingsNotFound: 2005,
  ApplicationSDKSettingsNotFound: 2006,
  ApplicationPlatformNotRemovable: 2007,
  AdminUserNotFound: 3000,
  AdminUserCreateError: 3001,
  AdminUserUpdateError: 3002,
  AdminUserDeleteError: 3003,
  AdminUserNotActive: 3004,
  AdminBadRequest: 3005,
  AdminEmailAlreadyExists: 3006,
  AdminPhoneAlreadyExists: 3007,
  AdminInviteError: 3008,
  AdminInviteExpired: 3009,
  AdminInviteNotFound: 3010,
  AdminRestoreWithTokenNotFound: 3011,
  AdminRestoreTokenExpired: 3012,
  AuthError: 4001, // Don't translate
  AlreadyVerified: 4004,
  TwoFactorVerificationNeeded: 4005,

  VerificationsNotFound: 5000,
  VerificationAlreadyFinished: 5001,
  ServiceNotFound: 5002,
  InvoiceNotFound: 5003,
  TariffPlanAlreadyExists: 5004,
  TariffPlanNotFound: 5005,
  TariffPlanBadRequest: 5006,
  TariffPriceNotFound: 5007,
  OtpPasswordAlreadySend: 5008,
  DocumentBadRequest: 5009,
  SmsSendingError: 5010,
  WorkflowCannotBeDeleted: 5028,
  WebhooksNotFound: 5011,
  WebhooksBadRequest: 5012,
  WebhookNotAvailable: 5013,
  VerificationFlowNotExist: 5014,
  VerificationStepNotExist: 5015,
  VerificationSettingsNotExist: 5016,
  VerificationFlowPassStepFailed: 5017,
  VerificationStepNotAccessible: 5018, // VerificationStepNotAccesible
  MissingVerificationStepId: 5019,
  MissingUncompletedVerificationSteps: 5020,
  BrowserVerificationFailed: 5021,
  VerificationSettingsAlreadyExist: 5022,
  VerificationsAlreadyProcessed: 5024,
  DocumentTypeNotFound: 5025,
  LivenessAttemptLimitExceeded: 5026,

  DocumentRecordNotFound: 6001,
  FaceRecordNotFound: 6002,
  ImageRecordNotFound: 6003,
  InnRecordNotFound: 6004,
  OtpRecordNotFound: 6005,
  PassiveRecordNotFound: 6006,
  WorkplaceRecordNotFound: 6007,
  MediaNotFound: 6008,
  UnknownClientType: 6009, // UnknowClientType

  UndefinedStepType: 7000, // UndefinedStepTyoe
  DocumentReaderError: 7001,
  DocumentNotFound: 7002,

  SendMailError: 8000,
  OneTimePasswordAlreadySent: 8001,

  SignNotFound: 8100,
  SignCreateAttemptsExceeded: 8101,

  SendSmsError: 8200,

  RemoteFileNotFound: 8500,

  UndefinedException: 9000,
  AxiosError: 9002,
  ServerResponseTimeout: 9003,
  OzHttpServiceAccessError: 9004,

  DoNotAllowAssignReviewer: 10000,
  DoNotAllowReassign: 10001,
  VersionDoesNotMatch: 10002,
  ReviewNotFound: 10003,
  OnlyReviewerAllowApproved: 10004,
  OnlyReviewerAllowDecline: 10005,
  UnableChangeFinalStatus: 10006,
  VerificationDoesNotBelongClient: 10007,
  ReviewerDoesNotBelongClient: 10008,

  SearchServiceError: 11000,

  CannotLinkFlowWithApplicant: 12005,

  UnableToDeleteRoleAssignedToUser: 14002,
  AmlProcessingDoesNotFind: 12018,
  UnableToDeleteQuestionnaire: 6010,
};
