import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';
import { PATHS } from '../constants';

class AMLScanService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_SERVICE);
  }

  async _baseAmlScanRequest(method = 'get', endpoint, body) {
    const { callback, ...rest } = { ...body };

    try {
      const {
        data: { data },
      } = await this.axios[method](`${PATHS.AML_SCAN}/${endpoint}`, rest);

      callback?.();

      return data;
    } catch (err) {
      callback?.(err?.response?.statusText);
      throw AxiosManager.stringifyError(err);
    }
  }

  getAMLList(filters) {
    return this._baseAmlScanRequest('get', '', { params: filters });
  }

  getAMLScanDetails(scanId) {
    return this._baseAmlScanRequest('get', `${scanId}/details`);
  }

  startAMLProcess(body) {
    return this._baseAmlScanRequest('post', 'start-process', body);
  }

  getAMlScanProfileInfo({ amlScanId, amlProfileId }) {
    return this._baseAmlScanRequest('get', `${amlScanId}/${amlProfileId}/details`);
  }

  createAMLScanProfileComment({ amlScanId, amlProfileId, body }) {
    return this._baseAmlScanRequest('post', `${amlScanId}/${amlProfileId}/comment`, body);
  }

  enableOngoingMonitoring({ body, amlScanId }) {
    return this._baseAmlScanRequest('post', `${amlScanId}/enable-monitoring`, body);
  }

  disableOngoingMonitoring({ amlScanId, body }) {
    return this._baseAmlScanRequest('post', `${amlScanId}/disable-monitoring`, body);
  }

  updateOngoingMonitoring({ amlScanId, body }) {
    return this._baseAmlScanRequest('put', `${amlScanId}/update-monitoring`, body);
  }

  checkAMLScanStatus(amlScanId) {
    return this._baseAmlScanRequest('get', `${amlScanId}/check-status`);
  }

  exportAMLScanReport(amlScanId) {
    return this._baseAmlScanRequest('get', `${amlScanId}/export-data`);
  }

  updateAMLProfileStatus({ amlScanId, amlProfileId, status, callback }) {
    return this._baseAmlScanRequest('put', `${amlScanId}/${amlProfileId}/status`, { status, callback });
  }

  changeMatchStatus({ amlScanId, status }) {
    return this._baseAmlScanRequest('put', `${amlScanId}/match-status`, { matchStatus: status });
  }

  changeCaseStatus({ amlScanId, status }) {
    return this._baseAmlScanRequest('put', `${amlScanId}/case-status`, { status });
  }

  getAmlScanLogHistory({ amlScanId, queryParams }) {
    return this._baseAmlScanRequest('get', `${amlScanId}/log-history`, { queryParams });
  }
}

export default new AMLScanService();
