import { createAsyncThunk } from '@reduxjs/toolkit';
import _isEmpty from 'lodash/isEmpty';
import verificationsService from './verificationsService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';
import { SERVICES, VERIFICATION_MODULE_KEY } from '../constants';
import { actions as serviceActions } from './servicesSlice';
import { actions as documentCheckActions } from './DocumentCheckSlice';

const storeBuilder = new BaseEntityStoreBuilder(
  VERIFICATION_MODULE_KEY,
  verificationsService,
  (verification) => verification.transactionId
);

export const selectors = {
  ...storeBuilder.selectors,
  selectById: (transactionId) => (state) => storeBuilder.selectors.selectById(state, transactionId),
};

export const actions = {
  getVerificationById: createAsyncThunk(`${VERIFICATION_MODULE_KEY}/getById`, async (id, { dispatch }) => {
    const { servicesData, histories, webhookDeliveryState, error, device, ...restVerification } =
      await verificationsService.getById(id);
    if (servicesData) {
      dispatch(serviceActions.fillDataServices(servicesData));
      const documentCapture = servicesData.find(({ service }) => service === SERVICES.DOCUMENT_CAPTURE);
      if (documentCapture) {
        const test = documentCapture?.data
          .map(({ fields }, index) => fields.map((item) => ({ id: `${index}-${item.fieldType}`, ...item })))
          .flat();
        dispatch(documentCheckActions.fillDocumentCheckFields(test));
      }
    }
    if (error) {
      dispatch(serviceActions.fillError(error));
    }
    if (device && !_isEmpty(device)) {
      dispatch(serviceActions.fillDevice(device));
    }
    if (histories) {
      dispatch(serviceActions.fillHistories(histories));
    }
    if (webhookDeliveryState && !_isEmpty(webhookDeliveryState)) {
      dispatch(serviceActions.fillWebhookState(webhookDeliveryState));
    }
    dispatch(serviceActions.fillInfo(restVerification));
    return restVerification;
  }),
  getAllVerifications: storeBuilder.actions.getAll,
  deleteVerification: storeBuilder.actions.delete,

  updateDocumentField: storeBuilder.createAction('updateDocumentField'),
  assignReviewer: storeBuilder.createAction('assignReviewer'),
  batchAssign: storeBuilder.createAction('batchAssign'),
  approve: storeBuilder.createAction('approve'),
  decline: storeBuilder.createAction('decline'),
};

const verificationsSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.assignReviewer.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.approve.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.decline.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.batchAssign.fulfilled, storeBuilder.adapter.updateMany)
);

export default verificationsSlice.reducer;
