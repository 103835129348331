import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Clear } from '@mui/icons-material';

const RenderOptionChip = ({
  label,
  value,
  params,
  multiple,
  placeholder,
  deleteOption,
  labelPositionLeft,
}) => {
  return (
    <>
      <Box
        display={labelPositionLeft ? 'flex' : 'block'}
        alignItems="center"
        gap={labelPositionLeft ? '10px' : 0}
      >
        <Typography variant="caption" color="accent.500">
          {label}
        </Typography>
        <TextField {...params} placeholder={placeholder} />
      </Box>
      {value && multiple ? (
        <Box marginTop="8px" display="flex" flexWrap="wrap" gap="3px">
          {value.map((option) => (
            <Chip
              key={option.value}
              label={option.label}
              variant="outlined"
              onDelete={() => deleteOption(option.value)}
              sx={{ color: 'violet.900', borderColor: 'violet.900' }}
              deleteIcon={
                <Clear
                  fontSize="small"
                  sx={{
                    '&.MuiSvgIcon-root': {
                      color: 'violet.900',
                    },
                  }}
                />
              }
              size="small"
            />
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default RenderOptionChip;
