import { lazy } from 'react';
import i18next from 'i18next';

import { PERMISSIONS } from '../teams/constants/permissions';
import { PATHS, QUESTIONNAIRE_KEY } from './constants';

const Questionnaires = lazy(() => import('./Questionnaires'));
const QuestionnaireList = lazy(() => import('./QuestionnaireList'));
const QuestionnaireBuilder = lazy(() => import('./QuestionnaireBuilder'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, QUESTIONNAIRE_KEY, require(`./i18n/${lang}`).default)
);

export const QuestionnaireNavigationConfig = {
  id: QUESTIONNAIRE_KEY,
  title: 'Questionnaires',
  translate: 'QUESTIONNAIRES',
  type: 'item',
  icon: 'navigation:questionnaire',
  url: PATHS.QUESTIONNAIRE_LIST,
  auth: [
    PERMISSIONS.ListQuestionnaireSchemas.value,
    PERMISSIONS.QuestionnaireAll.value,
    PERMISSIONS.OnlyTestMode.value,
  ],
};

export const QuestionnaireListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [
    PERMISSIONS.ListQuestionnaireSchemas.value,
    PERMISSIONS.QuestionnaireAll.value,
    PERMISSIONS.OnlyTestMode.value,
  ],
  routes: [
    {
      path: PATHS.QUESTIONNAIRE_LIST,
      element: <Questionnaires />,
      children: [
        {
          path: '',
          element: <QuestionnaireList />,
        },
        {
          path: PATHS.QUESTIONNAIRE_BUILDER,
          element: <QuestionnaireBuilder />,
        },
      ],
    },
  ],
};
