const locale = {
  VIEW: 'View',
  OTHER: 'Other',
  INVITES: 'Invites',
  UPDATE_INVITE: 'Update role',
  UPDATE_TOKEN: 'Extend for 24h',
  HIDE_FILTER: 'Hide filter',
  SHOW_FILTER: 'Show filter',
  SEARCH: 'Search',
  EXPIRE_DATE: 'Expire at',
  INVITER: 'Inviter',
  USER: 'User',
  ADD_NEW: 'Add new',
  ROLE_MANAGEMENT: 'Role management',
  AML: 'AML Scan',

  USERS: 'Users',
  FULL_NAME: 'Full name',
  PHONE: 'Phone',
  PHOTO: 'Photo',
  EMAIL: 'Email',
  POSITION: 'Position',
  ROLE: 'Role',
  STATUS: 'Status',
  CREATED_AT: 'Date of creation',
  UPDATED_AT: 'Date of update',
  SAVE: 'Save',
  SEND: 'Send',

  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
  DEACTIVATED: 'Deactivated',
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  OPERATOR: 'Operator',

  FULL_NAME_HELPER: 'You must enter your full name',
  FULL_NAME_ERROR: 'You must enter a valid full name',

  PHONE_HELPER: 'You must enter a phone number in format +380000000000',
  PHONE_ERROR: 'You must enter a valid phone number',

  POSITION_HELPER: 'You must enter a role',
  POSITION_ERROR: 'You must enter a valid role',

  EMAIL_HELPER: 'You must enter a email',
  EMAIL_ERROR: 'You must enter a valid email',

  EDIT_BUTTON: 'Edit',
  BLOCK_BUTTON: 'Block user',
  DELETE_BUTTON: 'Delete user',
  INFO_BUTTON: 'Detail info',
  LEGEND_INFO: 'Contact details',
  LEGEND_INVITER: 'Inviter',
  USER_UPDATE: 'Update user',
  TWO_FACTOR_AUTH: 'Two factor authorization',

  CHANGE_PASSWORD: 'Change password',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  CHANGE: 'Change',
  CANCEL: 'Cancel',
  YES_DELETE: 'Yes delete',
  DELETE: 'Delete',
  SEARCH_BY_NAME: 'Search by name',

  INVITE_TOKEN_UPDATE: 'An invitation token has been updated',
  INVITE_UPDATE: 'An invitation has been updated',
  INVITE_CREATE: 'An invitation has been created',
  INVITE_DELETE: 'An invitation has been deleted',

  USER_UPDATED: 'Changes have been updated',
  USER_DELETED: 'A profile has been deleted',

  ROLE_SUCCESSFULLY_CREATED: 'Role successfully created',
  ROLE_SUCCESSFULLY_UPDATED: 'Role successfully updated',
  MAX_NAME_LENGTH: 'Name must be at most 50 characters',
  NAME_REQUIRED_FIELD: 'Name is a required field',
  CONFIRM_ROLE_DELETE: 'Are you sure you want to delete role?',
  CONFIRM_DRAFT_ROLE_DELETE: 'Are you sure you want to delete draft role?',

  ANALYTICS: 'Analytics',
  APPLICANT: 'Applicants',
  COMPANY: 'Company',
  TEAM: 'Team',
  APPLICATION: 'Application',
  WORKFLOWS: 'Workflows',
  QUESTIONNAIRE: 'Questionnaire',
  TARIFF: 'Billing plan',
  BILLING: 'Billing',
  VERIFICATION: 'Transactions',
  // PERMISSIONS
  ALL: 'All',
  GENERAL: 'Access Preferences & Modes',
  ENABLE_TO_ALL: 'Enable Access to All Functionality',
  SANDBOX_MODE: 'Sandbox Mode Access',
  ONLY_ASSIGNED: 'Only assigned',
  TEST_MODE_ONLY: 'Test mode only',

  // AML
  GET_AML: 'Get AML',
  VIEW_LIST_AML_RESULTS: 'View list aml results',
  UPDATE_AML_MATCH_STATUS: 'Update AML Match status',
  UPDATE_AML_CASE_STATUS: 'Update AML Case status',

  /* Applicant */
  VIEW_ALL_APPLICANTS: 'View all applicants',
  GET_APPLICANT: 'View applicant details',
  DELETE_APPLICANT: 'Delete applicant',
  DOWNLOAD_APPLICANT: 'Download applicant',
  ASSIGN_REVIEWER_TO_APPLICANT: 'Assign reviewer to applicant',
  BATCH_ASSIGN_REVIEWER_TO_APPLICANTS: 'Batch assign reviewer to applicants',
  BATCH_APPROVE_APPLICANT_REVIEWS: 'Batch approve applicant reviews',
  BATCH_REJECT_APPLICANT_REVIEWS: 'Batch reject applicant reviews',
  RESOLVE_APPLICANT_REVIEW_WARNING: 'Resolve applicant review warning',
  CREATE_APPLICANT_COMMENT: 'Create applicant comment',
  LIST_APPLICANT_COMMENTS: 'View applicant comments',
  GET_APPLICANT_COMMENT: 'View applicant comment',
  UPDATE_APPLICANT_DOCUMENT_TYPE: 'Update applicant document type',
  UPDATE_APPLICANT_DOCUMENT_GRAPHIC: 'Update applicant document graphic',
  UPDATE_APPLICANT_DOCUMENT_TEXT_FIELD: 'Update applicant document text field',
  UPDATE_APPLICANT_FLOW: 'Update applicant flow',
  DEACTIVATE_APPLICANT_SERVICE: 'Deactivate applicant service',
  LIST_APPLICANT_HISTORY_LOGS: 'View applicant history logs',
  LIST_APPLICANT_HISTORY_SERVICES: 'View applicant history services',
  GET_APPLICANT_HISTORY_SERVICE: 'View applicant history service',
  GET_AML_RESULT: 'View AML result',
  START_AML_PROCESS: 'Start AML process',
  ENABLE_AML_MONITORING: 'Enable AML monitoring',
  DISABLE_AML_MONITORING: 'Disable AML monitoring',
  UPDATE_AML_MONITORING: 'Update AML monitoring',
  CREATE_APPLICANT_VERIFICATION_TOKEN: 'Create applicant verification token',
  CREATE_AML_PROFILE_COMMENT: 'Create AML profile comment',
  LIST_AML_PROFILE_COMMENTS: 'View AML profile comments',
  LIST_AML_PROFILE: 'View the list of AML results',
  GET_AML_PROFILE: 'Get AML profile results',
  UPDATE_AML_PROFILE_STATUS: 'Update AML profile status',
  EXPORT_AML_DATA: 'Export AML data',
  VIEW_ONLY_ASSIGNED_APPLICANTS: 'View only assigned applicants',

  /* Application */
  VIEW_APPLICATION: 'View application details',
  CREATE_APPLICATIONS: 'Create applications',
  VIEW_ALL_APPLICATIONS: 'View all applications',
  VIEW_ONLY_CREATED_APPLICATIONS: 'View only created applications',
  EDIT_ALL_APPLICATIONS: 'Edit all applications',
  EDIT_ONLY_CREATED_APPLICATIONS: 'Edit only created applications',
  DELETE_ALL_APPLICATIONS: 'Delete all applications',
  DELETE_ONLY_CREATED_APPLICATIONS: 'Delete only created applications',
  UPDATE_SECRET_API_KEY: 'Update Secret API Key',

  /* Verifications */
  VIEW_VERIFICATIONS: 'View verifications',
  VIEW_VERIFICATION_DETAILS: 'View verification details',
  DELETE_VERIFICATIONS: 'Delete verifications',
  DOWNLOAD_VERIFICATIONS: 'Download verifications',
  ASSIGN_REVIEWER_TO_VERIFICATION: 'Assign reviewer to verification',
  BATCH_REVIEWER_TO_VERIFICATION: 'Batch reviewer to verification',
  BATCH_APPROVE_VERIFICATION_REVIEWS: 'Batch approve verification reviews',
  BATCH_REJECT_VERIFICATION_REVIEWS: 'Batch reject verification reviews',
  UPDATE_VERIFICATION_DOCUMENT_TYPE: 'Update verification document type',
  UPDATE_VERIFICATION_DOCUMENT_GRAPHIC: 'Update verification document graphic',
  UPDATE_VERIFICATION_DOCUMENT_TEXT_FIELD: 'Update verification document text field',
  LIST_VERIFICATION_HISTORY_LOGS: 'List verification history logs',
  RESEND_LAST_FAILED_VERIFICATION_WEBHOOKS: 'Resend last failed verification webhooks',

  /* Company */
  VIEW_COMPANY_INFO: 'View company info',
  EDIT_COMPANY_INFO: 'Edit company info',
  DELETE_COMPANY_INFO: 'Delete company info',

  /* Workflows */
  VIEW_FLOW_DETAILS: 'View flow details',
  EDIT_FLOW: 'Edit flow',
  DELETE_FLOW: 'Delete flow',
  DUPLICATE_FLOW: 'Duplicate flow',
  CREATE_FLOW: 'Create a flow',
  VIEW_FLOWS: 'View flow list',

  /* Analytics */
  VIEW_ANALYTICS: 'View analytics',

  /* Billing */
  VIEW_BILLING_STATISTICS: 'View billing statistics',
  VIEW_INVOICES: 'View invoices',

  /* Tariff */
  VIEW_CURRENT_TARIFF_PLAN_PRICE: 'View current billing plan',

  /* Questionnaire */
  CREATE_QUESTIONNAIRE_SCHEMA: 'Create questionnaire',
  UPDATE_QUESTIONNAIRE_SCHEMA: 'Update questionnaire',
  VIEW_QUESTIONNAIRE_SCHEMA: 'View questionnaire',
  VIEW_LIST_OF_QUESTIONNAIRES: 'View list of questionnaire',
  DELETE_QUESTIONNAIRE_SCHEMA: 'Delete questionnaire',

  /* Team */
  VIEW_USERS: 'View list of users',
  VIEW_USER_DETAILS: 'View users details',
  DELETE_USER: 'Delete user',
  VIEW_INVITES: 'View invites',
  UPDATE_USER: 'Update user',
  UPDATE_USER_PHOTO: 'Update user photo',
  UPDATE_USER_STATUS: 'Update user status',
  CHANGE_USER_PASSWORD: 'Change user password',
  VIEW_INVITE_DETAILS: 'View invite details',
  UPDATE_INVITE_DATA: 'Update invite',
  DELETE_INVITE: 'Delete invite',
  CREATE_INVITE: 'Create invite',
  VIEW_ROLES: 'View list of roles',
  VIEW_ROLE_DETAILS: 'View role details',
  CREATE_ROLE: 'Create role',
  UPDATE_ROLE: 'Update role',
  DELETE_ROLE: 'Delete role',
  DELETE_DRAFT_ROLE: 'Delete draft role',

  EDIT_ALL_APPLICANTS: 'Edit all applicants',
  EDIT_ONLY_ASSIGNED_APPLICANTS: 'Edit only assigned applicants',
  ASSIGN_REVIEWERS_TO_APPLICANTS: 'Assign reviewers to applicants',
  DOWNLOAD_ALL_APPLICANTS: 'Download all applicants',
  DOWNLOAD_ONLY_ASSIGNED_APPLICANTS: 'Download only assigned applicants',
  CREATE_VERIFICATION_LINK: 'Create verification link',
  DELETE_ALL_APPLICANTS: 'Delete all applicants',
  DELETE_ASSIGNED_APPLICANTS: 'Delete assigned applicants',
  VIEW_ALL_TRANSACTIONS: 'View all transactions',
  VIEW_ONLY_ASSIGNED_TRANSACTIONS: 'View only assigned transactions',
  VIEW_ALL_TRANSACTIONS_DETAILS: 'View all transaction details',
  VIEW_ONLY_ASSIGNED_TRANSACTION_DETAILS: 'View only assigned transaction details',
  EDIT_ALL_TRANSACTIONS: 'Edit all transactions',
  EDIT_ONLY_ASSIGNED_TRANSACTIONS: 'Edit only assigned transactions',
  ASSIGN_REVIEWERS_TO_TRANSACTIONS: 'Assign reviewers to transactions',
  DOWNLOAD_ALL_TRANSACTIONS: 'Download all transactions',
  DOWNLOAD_ONLY_ASSIGNED_TRANSACTIONS: 'Download only assigned transactions',
  DELETE_ASSIGNED_TRANSACTIONS: 'Delete assigned transactions',
  DELETE_ALL_TRANSACTIONS: 'Delete all transactions',
  UPDATE_DOCUMENT_DETAILS: 'Update document details',
  INVITE_NEW_TEAM_MEMBERS: 'Invite new team members',
  EDIT_EXISTING_TEAM_MEMBERS: 'Edit existing team members',
  VIEW_INVITATIONS_LIST: 'View invitations list',
  VIEW_INVITATION_DETAILS: 'View invitation details',
  EDIT_INVITATIONS: 'Edit invitations',
  DELETE_INVITATIONS: 'Delete invitations',
  DELETE_TEAM_MEMBERS: 'Delete team members',
  CREATED_A_QUESTIONNAIRE: 'Create a questionnaire',
  VIEW_PERSONAL_DATA_OF_ASSIGNED_APPLICANTS_ONLY: 'View personal data of assigned applicants only',
  VIEW_PERSONAL_DATA_OF_ALL_APPLICANTS: 'View personal data of all applicants',
  EDIT_DOCUMENT_DATA: 'Edit document data',
  CREATE_COMMENTS: 'Create comments',
  VIEW_LOGS: 'View logs',
  VIEW_HISTORY: 'View history',
  DEACTIVATE_SERVICES: 'Deactivate services',
  CREATE_ROLE_FIRST: 'Please create a role first before generating an invite.',

  // EMPTY STATE
  USERS_EMPTY_STATE_TITLE: 'Users',
  USERS_EMPTY_STATE_DESCRIPTION:
    'No users have been added to the workspace. Invite users to collaborate and manage your team’s tasks.',
  INVITES_EMPTY_STATE_TITLE: 'Invites',
  INVITES_EMPTY_STATE_DESCRIPTION: 'No invites sent yet. Start by inviting new users to join your workspace.',
  ROLES_EMPTY_STATE_TITLE: 'Role Management',
  ROLES_EMPTY_STATE_DESCRIPTION:
    'No roles created yet. Create roles to manage access and permissions within your organization.',
};

export default locale;
