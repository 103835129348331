import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  width: '100%',

  '&.warning': {
    backgroundColor: theme.palette.warning[100],
    color: theme.palette.warning.main,
  },

  '&.violet': {
    backgroundColor: theme.palette.violet[200],
    color: theme.palette.violet[900],
  },
  '&.teal': {
    backgroundColor: theme.palette.violet[400],
    color: theme.palette.violet[700],
  },
  '&.rating': {
    backgroundColor: theme.palette.rating[200],
  },
  '&.accent': {
    backgroundColor: theme.palette.accent[50],
  },
}));

const CardColored = ({ loading, value, name, variant = 'accent' }) => (
  <StyledCard className={variant}>
    {loading ? (
      <Skeleton variant="rounded" height="90px" sx={{ borderRadius: '12px' }} />
    ) : (
      <Box sx={{ textAlign: 'center', paddingY: '10px' }}>
        <Typography variant="subtitle1">{value}</Typography>
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
    )}
  </StyledCard>
);

export default CardColored;
