import { useController } from 'react-hook-form';
import { FormControl, Typography } from '@mui/material';

import { MuiColorInput } from 'mui-color-input';

const ControlledColorInput = ({ name, control, label, ...restProps }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <FormControl className="w-full">
      <Typography variant="caption" color="accent.500">
        {label}
      </Typography>
      <MuiColorInput
        format="hex"
        placeholder={label}
        error={!!error}
        value={value}
        onChange={onChange}
        {...restProps}
      />

      {error && (
        <Typography variant="caption" color="error.main">
          {error.message}
        </Typography>
      )}
    </FormControl>
  );
};

export default ControlledColorInput;
