import { cloneElement } from 'react';
import Grid from '@mui/material/Grid';

export const ListGridWrapper = ({ list, draft, children, ...gridProps }) => (
  <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12, lg: 16 }} {...gridProps}>
    {list.map((item, index) => (
      <Grid item xs={4} sm={4} md={4} key={draft ? index : item?.id}>
        {cloneElement(children, { ...item, index, draft })}
      </Grid>
    ))}
  </Grid>
);
