import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Avatar from 'app/shared-components/Avatar';
import { AVATAR_TYPES } from 'app/constants/client';
import * as SVG from 'app/shared-components/Icons/Icons';
import PermissionsGate from 'app/main/teams/components/PermissionsGate';

import CommentReply from './CommentReply';
import { TRANSLATION_KEY } from '../constants';

const CommentsInput = ({
  inputId,
  inputRef,
  comment,
  handlePost,
  parentMessage,
  cancelReplying,
  handleChangeInput,
  permissionsScope = [],
}) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const currentUser = useSelector(({ auth }) => auth.user);

  return (
    <PermissionsGate scopes={permissionsScope}>
      <Box
        display="flex"
        padding="10px"
        border="1px solid"
        position="relative"
        alignItems="center"
        borderRadius="30px"
        borderColor="accent.200"
        justifyContent="space-between"
      >
        {parentMessage && (
          <CommentReply
            onClear={cancelReplying}
            text={parentMessage.text}
            userName={parentMessage.authorFullName}
          />
        )}

        <Avatar
          type={AVATAR_TYPES.ADMIN_USER}
          path={currentUser?.data?.photoURL}
          alt={currentUser?.data?.displayName}
          sx={{ width: 34, height: 34 }}
        />
        <input
          id={inputId}
          type="text"
          style={{ minWidth: '140px', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
          placeholder={t('WRITE_COMMENT')}
          onChange={handleChangeInput}
          value={comment}
          ref={inputRef}
        />
        <IconButton
          onClick={handlePost}
          disabled={!comment?.length}
          sx={{
            backgroundColor: 'violet.900',
            padding: '10px',
            '&:hover': {
              backgroundColor: 'violet.700',
            },
            '&.Mui-disabled': {
              backgroundColor: 'accent.200',
            },
          }}
        >
          <SVG.CommonIcons size={14} iconId="send" />
        </IconButton>
      </Box>
    </PermissionsGate>
  );
};
export default CommentsInput;
