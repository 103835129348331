import { AxiosManager } from 'app/services/axiosManager';
import BaseApi from 'app/services/common/base-api';

class FlowService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/flow');
  }

  async updateFlow({ flow, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`/${flow.id}`, flow);

      callback(true);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async createNewFlow({ flow, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(``, flow);

      callback();

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getFlowDocType() {
    try {
      const {
        data: { data },
      } = await this.axios.get(`builder/doc-type`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getFlowById(flowId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`/${flowId}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAllFlows(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/', { params: queryParams });

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async duplicateFlow(body) {
    try {
      const {
        data: { data },
      } = await this.axios.post('/duplicate', body);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deleteFlow(flowId) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`/${flowId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new FlowService();
