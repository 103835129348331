import { lazy } from 'react';
import i18next from 'i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { PATHS, TRANSLATION_KEY } from './constants';

const Otp = lazy(() => import('./Otp'));
const SignUp = lazy(() => import('./SignUp'));
const SignIn = lazy(() => import('./SignIn'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const RestorePassword = lazy(() => import('./RestorePassword'));
const ConfirmInvite = lazy(() => import('./ConfirmInvite'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const AuthConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [],
  routes: [
    { path: PATHS.OTP, element: <Otp /> },
    {
      path: PATHS.SIGN_UP,
      element: <SignUp />,
    },
    {
      path: PATHS.SIGN_IN,
      element: <SignIn />,
    },
    {
      path: PATHS.RESET_PASSWORD_WITH_TOKEN,
      element: <ResetPassword />,
    },
    {
      path: PATHS.RESTORE_PASSWORD,
      element: <RestorePassword />,
    },
    {
      path: PATHS.CONFIRM_INVITE,
      element: <ConfirmInvite />,
    },
  ],
};

AuthConfig.routes.forEach((route) => {
  route.element = (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}>
      {route.element}
    </GoogleReCaptchaProvider>
  );
});
