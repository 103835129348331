import { lazy } from 'react';
import i18next from 'i18next';
import { TRANSLATION_KEY, PATHS } from './constants';
import { PERMISSIONS } from './constants/permissions';

const Roles = lazy(() => import('./Roles'));
const Users = lazy(() => import('./Users'));
const Profile = lazy(() => import('./Profile'));
const Invites = lazy(() => import('./Invites'));
const RolesList = lazy(() => import('./components/RolesList'));
const RoleBuilder = lazy(() => import('./components/RoleBuilder'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const TeamsNavigationConfig = {
  id: 'team',
  title: 'Team',
  translate: 'TEAM',
  type: 'collapse',
  icon: 'navigation:team',
  auth: [
    PERMISSIONS.ListUsers.value,
    PERMISSIONS.ListInvites.value,
    PERMISSIONS.ListRoles.value,
    PERMISSIONS.TeamAll.value,
  ],
  children: [
    {
      id: 'users',
      title: 'Users',
      translate: 'USERS',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.USERS,
      auth: [PERMISSIONS.ListUsers.value, PERMISSIONS.TeamAll.value],
    },
    {
      id: 'invites1',
      title: 'Invites',
      translate: 'INVITES',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.INVITES,
      auth: [PERMISSIONS.ListInvites.value, PERMISSIONS.TeamAll.value],
    },
    {
      id: 'role_management',
      title: 'Role management',
      translate: 'ROLE_MANAGEMENT',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.ROLES,
      auth: [PERMISSIONS.ListRoles.value, PERMISSIONS.TeamAll.value],
    },
  ],
};

export const UsersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.ListUsers.value, PERMISSIONS.TeamAll.value],
  routes: [
    {
      path: PATHS.USERS,
      exact: true,
      element: <Users />,
    },
    {
      path: PATHS.USER_DETAILS,
      exact: true,
      element: <Users />,
    },
    {
      path: PATHS.PROFILE,
      exact: true,
      element: <Profile />,
    },
  ],
};

export const InvitesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.ListInvites.value, PERMISSIONS.TeamAll.value],
  routes: [
    {
      path: PATHS.INVITES,
      element: <Invites />,
    },
    {
      path: PATHS.INVITE_DETAILS,
      element: <Invites />,
    },
  ],
};

export const RoleManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.ListRoles.value, PERMISSIONS.TeamAll.value],
  routes: [
    {
      path: PATHS.ROLES,
      element: <Roles />,
      children: [
        {
          path: '',
          element: <RolesList />,
        },
        {
          path: PATHS.ROLE_BUILDER,
          element: <RoleBuilder />,
        },
      ],
    },
  ],
};
