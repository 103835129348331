const locale = {
  VIEW: 'Перегляд',
  OTHER: 'Інші',
  INVITES: 'Запрошення',
  UPDATE_INVITE: 'Оновити запрошення',
  UPDATE_TOKEN: 'Подовжити на 24 год',
  HIDE_FILTER: 'Сховати фiльтер',
  SHOW_FILTER: 'Вiдобразити фiльтер',
  SEARCH: 'Пошук',
  EXPIRE_DATE: 'Дійсна до',
  INVITER: 'Запрошувач',
  USER: 'Користувач',
  ADD_NEW: 'Додати новий',
  AML: 'AML Cканування',

  USERS: 'Користувачі',
  FULL_NAME: 'Повне iмʼя',
  PHONE: 'Телефон',
  PHOTO: 'Фотографія',
  EMAIL: 'Поштова адреса',
  ROLE: 'Роль',
  POSITION: 'Посада',
  STATUS: 'Статус',
  CREATED_AT: 'Дата створення',
  UPDATED_AT: 'Дата оновлення',
  SAVE: 'Зберегти',
  SEND: 'Відправити',

  ACTIVE: 'Активний',
  BLOCKED: 'Заблокований',
  DEACTIVATED: 'Деактивований',
  ADMIN: 'Адмін',
  MANAGER: 'Менеджер',
  OPERATOR: 'Оператор',
  ROLE_MANAGEMENT: 'Управління ролями',

  FULL_NAME_HELPER: 'Ви повинні ввести своє повне імʼя',
  FULL_NAME_ERROR: 'Ви повинні ввести дійсне повне ім’я',

  PHONE_HELPER: 'Ведіть правильний телефон, у форматі +3800000000000',
  PHONE_ERROR: 'Ви повинні ввести дійсний номер телефону',

  POSITION_HELPER: 'Ви повинні ввести свою позицію',
  POSITION_ERROR: 'Ви повинні ввести дійсну позицію',

  EMAIL_HELPER: 'Ви повинні ввести email',
  EMAIL_ERROR: 'Ви повинні ввести дійсний email',

  EDIT_BUTTON: 'Редагувати',
  BLOCK_BUTTON: 'Блокувати',
  DELETE_BUTTON: 'Видалити',
  INFO_BUTTON: 'Детальна информація',
  LEGEND_INFO: 'Контактні данні',
  LEGEND_INVITER: 'Запрошувач',
  USER_UPDATE: 'Оновити користувача',
  TWO_FACTOR_AUTH: 'Двох факторна авторизація',

  CHANGE_PASSWORD: 'Змінити пароль',
  PASSWORD: 'Пароль',
  CONFIRM_PASSWORD: 'Підтвердьте пароль',
  CHANGE: 'Змінити',
  CANCEL: 'Скасувати',
  YES_DELETE: 'Так, видалити',
  DELETE: 'Видалити',
  SEARCH_BY_NAME: 'Пошук за назвою',

  INVITE_TOKEN_UPDATE: 'Токен запрошення оновлено',
  INVITE_UPDATE: 'Запрошення оновлено',
  INVITE_CREATE: 'Запрошення створено',
  INVITE_DELETE: 'Запрошення видалено',

  USER_UPDATED: 'Зміни збережено',
  USER_DELETED: 'Профіль видалено',

  ROLE_SUCCESSFULLY_CREATED: 'Роль успішно створено',
  ROLE_SUCCESSFULLY_UPDATED: 'Роль успішно оновлено',
  MAX_NAME_LENGTH: 'Назва має містити не більше 50 символів',
  NAME_REQUIRED_FIELD: 'Назва є обов`язковим полем для заповнення',
  CONFIRM_ROLE_DELETE: 'Ви впевнені, що хочете видалити роль?',
  CONFIRM_DRAFT_ROLE_DELETE: 'Ви впевнені, що хочете видалити чорнову роль?',

  ANALYTICS: 'Аналітика',
  APPLICANT: 'Заявники',
  COMPANY: 'Компанія',
  TEAM: 'Команда',
  APPLICATION: 'Додаток',
  WORKFLOWS: 'Робочі процеси',
  QUESTIONNAIRE: 'Анкета',
  TARIFF: 'Тариф',
  BILLING: 'Рахунки',
  VERIFICATION: 'Транзакції',
  GENERAL: 'Налаштування доступу та режими',

  // AML
  GET_AML: 'Отримати AML',
  VIEW_LIST_AML_RESULTS: 'Переглянути список результатів AML',
  UPDATE_AML_MATCH_STATUS: 'Оновити статус відповідності AML',
  UPDATE_AML_CASE_STATUS: 'Оновити статус справи про AML',

  ALL: 'Всі',
  ENABLE_TO_ALL: 'Надати доступ до всього функціоналу',
  SANDBOX_MODE: 'Доступ до функціоналу в тестовому режимі',
  ONLY_ASSIGNED: 'Тільки призначені',
  TEST_MODE_ONLY: 'Тільки тестовий режим',

  /* Applicant */
  VIEW_ALL_APPLICANTS: 'Переглянути всіх заявників',
  GET_APPLICANT: 'Переглянути деталі заявника',
  DELETE_APPLICANT: 'Видалити заявника',
  DOWNLOAD_APPLICANT: 'Завантажити звіт по заявнику',
  ASSIGN_REVIEWER_TO_APPLICANT: 'Призначити оператора для заявника',
  BATCH_ASSIGN_REVIEWER_TO_APPLICANTS: 'Призначити оператора для заявника - масова дія',
  BATCH_APPROVE_APPLICANT_REVIEWS: 'Схвалити заявку - масова для',
  BATCH_REJECT_APPLICANT_REVIEWS: 'Відхилити заявку - масова дія',
  RESOLVE_APPLICANT_REVIEW_WARNING: 'Усунути попередження системи в профілі заявника',
  CREATE_APPLICANT_COMMENT: 'Створити коментар в профілі заявника',
  LIST_APPLICANT_COMMENTS: 'Переглянути коментарі в профілі заявника',
  GET_APPLICANT_COMMENT: 'Отримати коментар профілі заявника',
  UPDATE_APPLICANT_DOCUMENT_TYPE: 'Оновити тип документа заявника',
  UPDATE_APPLICANT_DOCUMENT_GRAPHIC: 'Оновити графічні елементи в документі заявника',
  UPDATE_APPLICANT_DOCUMENT_TEXT_FIELD: 'Оновити текстове поле в документі заявника',
  UPDATE_APPLICANT_FLOW: 'Оновити робочий процес для заявника',
  DEACTIVATE_APPLICANT_SERVICE: 'Деактивувати сервіс заявника',
  LIST_APPLICANT_HISTORY_LOGS: 'Переглянути історію змін в профілі заявника',
  LIST_APPLICANT_HISTORY_SERVICES: 'Переглянути історію сервісів в профілі заявника',
  GET_APPLICANT_HISTORY_SERVICE: 'Отримати історію сервісів заявника',
  GET_AML_RESULT: 'Переглянути результат AML',

  ENABLE_AML_MONITORING: 'Увімкнути моніторинг AML',
  DISABLE_AML_MONITORING: 'Вимкнути моніторинг AML',
  UPDATE_AML_MONITORING: 'Оновити моніторинг AML',
  CREATE_APPLICANT_VERIFICATION_TOKEN: 'Створити токен верифікації заявника',
  CREATE_AML_PROFILE_COMMENT: 'Створити коментар до профілю AML',
  LIST_AML_PROFILE_COMMENTS: 'Перелік коментарів до профілю AML',
  LIST_AML_PROFILE: 'Перелік профілів AML',
  GET_AML_PROFILE: 'Отримати профіль AML',
  UPDATE_AML_PROFILE_STATUS: 'Оновити статус профілю AML',
  EXPORT_AML_DATA: 'Експортувати дані AML',
  START_AML_PROCESS: 'Запустити процес AML',
  VIEW_ONLY_ASSIGNED_APPLICANTS: 'Перегляд лише призначених заявників',

  /* Application */
  VIEW_APPLICATION: 'Переглянути деталі додатоку',
  CREATE_APPLICATIONS: 'Створити додаток',
  VIEW_ALL_APPLICATIONS: 'Переглянути всі додатки',
  VIEW_ONLY_CREATED_APPLICATIONS: 'Перегляд лише створених додатків',
  EDIT_ALL_APPLICATIONS: 'Редагувати всі додатки',
  EDIT_ONLY_CREATED_APPLICATIONS: 'Редагувати лише створені додатки',
  DELETE_ALL_APPLICATIONS: 'Видалити всі додатки',
  DELETE_ONLY_CREATED_APPLICATIONS: 'Видалити лише створені додатки',
  UPDATE_SECRET_API_KEY: 'Оновити секретний ключ API',

  /* Verifications */
  VIEW_VERIFICATIONS: 'Переглянути перевірки',
  VIEW_VERIFICATION_DETAILS: 'Переглянути деталі перевірки',
  DELETE_VERIFICATIONS: 'Видалити перевірки',
  DOWNLOAD_VERIFICATIONS: 'Завантажити перевірки',
  ASSIGN_REVIEWER_TO_VERIFICATION: 'Призначити рецензента для перевірки',
  BATCH_REVIEWER_TO_VERIFICATION: 'Призначення рецензента для перевірки - масова дія',
  BATCH_APPROVE_VERIFICATION_REVIEWS: 'Схвалення перевірки - масова дія',
  BATCH_REJECT_VERIFICATION_REVIEWS: 'Відхилення перевірки - масова дія',
  UPDATE_VERIFICATION_DOCUMENT_TYPE: 'Оновити тип документа користувача',
  UPDATE_VERIFICATION_DOCUMENT_GRAPHIC: 'Оновити графічні елементи документа користувача',
  UPDATE_VERIFICATION_DOCUMENT_TEXT_FIELD: 'Оновити текстове поле документа користувача',
  LIST_VERIFICATION_HISTORY_LOGS: 'Переглянути історію перевірки',
  RESEND_LAST_FAILED_VERIFICATION_WEBHOOKS: 'Повторно надіслати останні невдалі вебхуки перевірки',

  /* Company */
  VIEW_COMPANY_INFO: 'Переглянути інформацію про компанію',
  EDIT_COMPANY_INFO: 'Редагувати інформацію про компанію',
  DELETE_COMPANY_INFO: 'Видалити інформацію про компанію',

  /* Workflows */
  VIEW_FLOW_DETAILS: 'Переглянути деталі процесу перевірки',
  EDIT_FLOW: 'Редагувати процес перевірки',
  DELETE_FLOW: 'Видалити процес перевірки',
  DUPLICATE_FLOW: 'Дублювати процес перевірки',
  CREATE_FLOW: 'Створити процес перевірки',
  VIEW_FLOWS: 'Переглянути список процесів перевірки',

  /* Analytics */
  VIEW_ANALYTICS: 'Переглянути аналітику',

  /* Billing */
  VIEW_BILLING_STATISTICS: 'Переглянути статистику оплати',
  VIEW_INVOICES: 'Переглянути рахунки',

  /* Tariff */
  VIEW_CURRENT_TARIFF_PLAN_PRICE: 'Переглянути поточний тарифний план',

  /* Questionnaire */
  CREATE_QUESTIONNAIRE_SCHEMA: 'Створити опитувальник',
  UPDATE_QUESTIONNAIRE_SCHEMA: 'Оновити опитувальник',
  VIEW_QUESTIONNAIRE_SCHEMA: 'Переглянути опитувальник',
  VIEW_LIST_OF_QUESTIONNAIRES: 'Переглянути список опитувальників',
  DELETE_QUESTIONNAIRE_SCHEMA: 'Видалити опитувальник',

  /* Team */
  VIEW_USERS: 'Переглянути список користувачів',
  VIEW_USER_DETAILS: 'Переглянути деталі користувача',
  DELETE_USER: 'Видалити користувача',
  VIEW_INVITES: 'Переглянути запрошення',
  UPDATE_USER: 'Оновити користувача',
  UPDATE_USER_PHOTO: 'Оновити фото користувача',
  UPDATE_USER_STATUS: 'Оновити статус користувача',
  CHANGE_USER_PASSWORD: 'Змінити пароль користувача',
  VIEW_INVITE_DETAILS: 'Переглянути деталі запрошення',
  UPDATE_INVITE_DATA: 'Оновити запрошення',
  DELETE_INVITE: 'Видалити запрошення',
  CREATE_INVITE: 'Створити запрошення',
  VIEW_ROLES: 'Переглянути список ролей',
  VIEW_ROLE_DETAILS: 'Переглянути деталі ролі',
  CREATE_ROLE: 'Створити роль',
  UPDATE_ROLE: 'Оновити роль',
  DELETE_ROLE: 'Видалити роль',
  DELETE_DRAFT_ROLE: 'Видалити чорнову роль',

  // ДОЗВОЛИ
  EDIT_ALL_APPLICANTS: 'Редагування всіх заявників',
  EDIT_ONLY_ASSIGNED_APPLICANTS: 'Редагування лише призначених заявників',
  ASSIGN_REVIEWERS_TO_APPLICANTS: 'Призначення операторів для заявників',
  DOWNLOAD_ALL_APPLICANTS: 'Завантаження звітів по всіх заявників',
  DOWNLOAD_ONLY_ASSIGNED_APPLICANTS: 'Завантаження звітів лише по призначених заявників',
  CREATE_VERIFICATION_LINK: 'Створення посилання на перевірку',
  DELETE_ALL_APPLICANTS: 'Видалення всіх заявників',
  DELETE_ASSIGNED_APPLICANTS: 'Видалення призначених заявників',
  VIEW_ALL_TRANSACTIONS: 'Перегляд всіх транзакцій',
  VIEW_ONLY_ASSIGNED_TRANSACTIONS: 'Перегляд лише призначених транзакцій',
  VIEW_ALL_TRANSACTIONS_DETAILS: 'Перегляд всіх деталей транзакцій',
  VIEW_ONLY_ASSIGNED_TRANSACTION_DETAILS: 'Перегляд лише деталей призначених транзакцій',
  EDIT_ALL_TRANSACTIONS: 'Редагування всіх транзакцій',
  EDIT_ONLY_ASSIGNED_TRANSACTIONS: 'Редагування лише призначених транзакцій',
  ASSIGN_REVIEWERS_TO_TRANSACTIONS: 'Призначення операторів для транзакцій',
  DOWNLOAD_ALL_TRANSACTIONS: 'Завантаження всіх транзакцій',
  DOWNLOAD_ONLY_ASSIGNED_TRANSACTIONS: 'Завантаження лише призначених транзакцій',
  DELETE_ASSIGNED_TRANSACTIONS: 'Видалення призначених транзакцій',
  DELETE_ALL_TRANSACTIONS: 'Видалення всіх транзакцій',
  UPDATE_DOCUMENT_DETAILS: 'Оновлення деталей документа',
  VIEW_lIST_OF_USERS: 'Перегляд списку користувачів',
  INVITE_NEW_TEAM_MEMBERS: 'Запрошення нових членів команди',
  EDIT_EXISTING_TEAM_MEMBERS: 'Редагування існуючих членів команди',
  VIEW_INVITATIONS_LIST: 'Перегляд списку запрошень',
  VIEW_INVITATION_DETAILS: 'Перегляд деталей запрошення',
  EDIT_INVITATIONS: 'Редагування запрошень',
  DELETE_INVITATIONS: 'Видалення запрошень',
  DELETE_TEAM_MEMBERS: 'Видалення членів команди',
  ACCESS_FLOW_BUILDER: 'Доступ до конструктора робочих процесів',
  EDIT_ANY_FLOW: 'Редагування будь-якого робочого процесу',
  DELETE_ANY_FLOW: 'Видалення будь-якого робочого процесу',
  DUPLICATE_ANY_FLOW: 'Дублювання будь-якого робочого процесу',
  CREATED_A_QUESTIONNAIRE: 'Створення опитувальника',
  VIEW_PERSONAL_DATA_OF_ASSIGNED_APPLICANTS_ONLY: 'Перегляд особистих даних лише призначених заявників',
  VIEW_PERSONAL_DATA_OF_ALL_APPLICANTS: 'Перегляд особистих даних всіх заявників',
  EDIT_DOCUMENT_DATA: 'Редагування даних документа',
  CREATE_COMMENTS: 'Створення коментарів',
  VIEW_LOGS: 'Перегляд логів',
  VIEW_HISTORY: 'Перегляд історії',
  DEACTIVATE_SERVICES: 'Деактивація сервісів',
  CREATE_ROLE_FIRST: 'Спочатку створіть роль, щоб мати можливість створити запрошення.',

  // EMPTY STATE
  USERS_EMPTY_STATE_TITLE: 'Користувачі',
  USERS_EMPTY_STATE_DESCRIPTION:
    'Жодного користувача ще не додано до системи. Запросіть користувачів для співпраці та управління завданнями вашої команди.',
  INVITES_EMPTY_STATE_TITLE: 'Запрошення',
  INVITES_EMPTY_STATE_DESCRIPTION:
    'Жодних запрошень ще не надіслано. Почніть із запрошення нових користувачів приєднатися до системи.',
  ROLES_EMPTY_STATE_TITLE: 'Управління ролями',
  ROLES_EMPTY_STATE_DESCRIPTION:
    'Ще не створено жодних ролей. Створіть ролі для управління доступом і дозволами в межах вашої організації.',
};

export default locale;
