import _replace from 'lodash/replace';
import _upperCase from 'lodash/upperCase';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as SVG from '../Icons';

function Select({
  name,
  value,
  values,
  children,
  iconColor,
  valueProps,
  allDisabled,
  defaultValue,
  iconSize = 16,
  translationKey,
  translatePrefix = '',
  ...restProps
}) {
  const { t } = useTranslation(translationKey);

  return (
    <SelectMui
      name={name}
      size="small"
      value={value}
      sx={{ minWidth: 144 }}
      defaultValue={defaultValue}
      renderValue={(valueOb) => (
        <Typography variant="body2" {...valueProps}>
          {valueOb === 'all'
            ? t(`ALL_${_replace(_upperCase(name), /\s/g, '_')}`)
            : t(`${translatePrefix}${valueOb}`)}
        </Typography>
      )}
      IconComponent={(props) => <SVG.CommonIcons size={iconSize} iconId="cheveron-down" {...props} />}
      {...restProps}
    >
      {!allDisabled && (
        <MenuItem value="all">
          <Typography variant="body2">{t(`ALL_${_replace(_upperCase(name), /\s/g, '_')}`)}</Typography>
        </MenuItem>
      )}

      {children ||
        Object.entries(values)?.map(([key, objValue]) => (
          <MenuItem key={key} value={key}>
            <Typography variant="body2">{t(`${translatePrefix}${objValue}`)}</Typography>

            {value === objValue && (
              <Box marginLeft="auto">
                <SVG.HeroIconsSolid size={16} iconId="check" sx={{ color: 'violet.900' }} />
              </Box>
            )}
          </MenuItem>
        ))}
    </SelectMui>
  );
}

export default Select;
