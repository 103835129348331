import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './signUpSlice';
import user from './userSlice';
import restore from './restoreSlice';

const authReducers = combineReducers({
  user,
  login,
  register,
  restore,
});

export default authReducers;
