import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Box, IconButton } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

export default function CopyTextfield({ children, valueToCopy, icon }) {
  const dispatch = useDispatch();
  const handleShowMessage = (text) =>
    dispatch(
      showMessage({
        message: text,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'success',
      })
    );

  const handleAction = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(valueToCopy);
    handleShowMessage('Copied');
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" overflow="hidden">
      {children}

      <IconButton onClick={handleAction}>
        <FuseSvgIcon size={14}>heroicons-outline:clipboard-copy</FuseSvgIcon>
      </IconButton>
    </Box>
  );
}
