import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

const PATHS_APPLICANTS = '/applicants';

class AMLService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_SERVICE);
  }

  async startAMLProcess({ applicantId }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${applicantId}/aml-process`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async startOngoingMonitoring({ applicantId, amlId, body }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/monitoring/enable`, body);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async stopOngoingMonitoring({ applicantId, amlId }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(`${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/monitoring/disable`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateOngoingMonitoring({ applicantId, monitoringId, amlId, body }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(
        `${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/monitoring/${monitoringId}`,
        body
      );

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async checkAMLUpdate(applicantId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${applicantId}/aml-process/check-update`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAmlProfilesList({ amlId, applicantId, queryParams }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/profiles`, {
        params: queryParams,
      });

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAMLProfileDetails({ applicantId, amlId, profileId }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/profiles/${profileId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async exportAMLData({ applicantId, amlId }) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/export-data`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateAMLProfileStatus({ applicantId, amlId, profileId, body }) {
    const url = `${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/profiles/${profileId}/status`;
    try {
      const {
        data: { data },
      } = await this.axios.put(url, body);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async createCommentInAMLProfile({ applicantId, amlId, profileId, body: { callback, ...restBody } }) {
    const url = `${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/profiles/${profileId}/comments`;
    try {
      const {
        data: { data },
      } = await this.axios.post(url, restBody);
      if (typeof callback === 'function') {
        callback();
      }

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAMLProfileComments({ applicantId, amlId, profileId, body }) {
    const url = `${PATHS_APPLICANTS}/${applicantId}/aml/${amlId}/profiles/${profileId}/comments`;
    try {
      const {
        data: { data },
      } = await this.axios.get(url, body);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new AMLService();
