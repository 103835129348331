export default {
  SAVE: 'Зберегти',
  UPDATE_CLIENT: 'Оновити',
  CLIENT_KEY: 'Клієнтський ключ',
  ACCOUNT_NUMBER: 'Номер рахунку',
  EMAIL: 'Поштова адреса',
  PHONE: 'Телефон',
  ADDRESS: 'Адреса',
  CONTACT: 'Контакт',
  INFO: 'Інфо',
  INTEGRATION: 'Інтеграція',
  STATUS: 'Статус',
  CREATED_AT: 'Дата створення',
  UPDATED_AT: 'Дата оновлення',
  EDIT_COMPANY: 'Редагувати',
  COMPANY: 'Компанія',

  NAME_HELPER: 'Ви повинні ввести назву компанії',
  NAME_ERROR: 'Ви повинні ввести дійсну назву компанії',

  PHONE_HELPER: 'Ведіть правильний телефон, у форматі +3800000000000',
  PHONE_ERROR: 'Ви повинні ввести дійсний номер телефону',

  EMAIL_HELPER: 'Ви повинні ввести електронну адресу',
  EMAIL_ERROR: 'Ви повинні ввести дійсну адресу електронної пошти',
  COMPANY_UPDATE: 'Зміни збережено',
};
