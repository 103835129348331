export const CLIENT_STATUS = {
  DISABLED: 'disabled',
  ACTIVE: 'active',
  DEMO: 'demo',
};

export const AVATAR_TYPES = {
  ADMIN_USER: 'admin_user',
  CLIENT_LOGO: 'client_logo',
  APPLICATION_LOGO: 'application_logo',
};
