import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent, CardHeader, IconButton, Typography, styled } from '@mui/material';

import * as SVG from 'app/shared-components/Icons';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';
import PermissionsGate from 'app/main/teams/components/PermissionsGate';

const AboveHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '1px solid',
  backgroundColor: theme.palette.accent[100],
  borderColor: theme.palette.accent[200],
  padding: '3px 0',
}));

const CardWithActions = ({
  name,
  info,
  draft,
  openMenu,
  updatedAt,
  appendContent,
  permissionsForMenu = [],
}) => {
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: 'accent.200',
        justifyContent: appendContent ? 'unset' : 'space-between',
        backgroundColor: draft ? 'accent.50' : 'white',
      }}
    >
      {draft && (
        <AboveHeader>
          <SVG.CommonIcons iconId="file" size={12} sx={{ color: 'accent.500' }} />
          <Typography variant="caption" fontWeight={500} color="accent.700">
            {t('DRAFT')}
          </Typography>
        </AboveHeader>
      )}
      <CardHeader
        sx={{ paddingBottom: '10px' }}
        title={
          <Typography sx={{ wordBreak: 'break-word' }} variant="subtitle1" fontWeight={600}>
            {name}
          </Typography>
        }
        action={
          <PermissionsGate scopes={permissionsForMenu}>
            <IconButton sx={{ zIndex: 1 }} onClick={openMenu}>
              <SVG.HeroIconsOutline iconId="dots-horizontal" size={16} />
            </IconButton>
          </PermissionsGate>
        }
        subheader={
          <Typography variant="body2" color="accent.500" sx={{ wordBreak: 'break-word' }}>
            {info}
          </Typography>
        }
      />
      <CardContent sx={{ padding: '0', '&:last-child': { paddingBottom: '16px' } }}>
        {appendContent || (
          <Box
            display="flex"
            gap="5px"
            marginTop="auto"
            alignItems="center"
            color="accent.500"
            paddingX="15px"
          >
            <SVG.HeroIconsOutline iconId="clock" size={16} />
            <Typography variant="caption">{format(new Date(updatedAt), 'HH:mm MMMM dd')}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CardWithActions;
