import commentsService from './commentsService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';
import { COMMENTS_MODULE_KEY } from '../constants';

const storeBuilder = new BaseEntityStoreBuilder(COMMENTS_MODULE_KEY, commentsService);

export const selectors = {
  ...storeBuilder.selectors,
  selectById: (id) => (state) => storeBuilder.selectors.selectById(state, id),
};

export const actions = {
  getAllComments: storeBuilder.createAction('getAllComments'),
  getCommentById: storeBuilder.actions.getById,
  postComment: storeBuilder.createAction('addComment'),
};

const applicantsSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.getAllComments.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    })
    .addCase(actions.getAllComments.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    })
    .addCase(actions.getAllComments.fulfilled, storeBuilder.adapter.setAll)
    .addCase(actions.postComment.pending, (state, action) => {
      state.loading = true;
      state.success = false;
    })
    .addCase(actions.postComment.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    })
    .addCase(actions.postComment.fulfilled, storeBuilder.adapter.addOne)
);

export default applicantsSlice.reducer;
