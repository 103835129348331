import { lazy } from 'react';
import i18next from 'i18next';

// import { PERMISSIONS } from '../teams/constants/permissions';
import { PATHS, TRANSLATION_KEY } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

const AMLScanSearchPage = lazy(() => import('./AMLScanSearchPage'));
const AMLScanResultsPage = lazy(() => import('./AMLScanResultsPage'));
const AMLScanResultLogsPage = lazy(() => import('./AMLScanResultLogsPage'));
const AMLScanResultDetailsPage = lazy(() => import('./AMLScanResultDetailsPage'));

[('uk-UA', 'en-US')].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const AMLNavigationConfig = {
  id: 'aml',
  title: 'Aml scan',
  translate: 'AML_SCAN',
  type: 'collapse',
  icon: 'navigation:aml',
  auth: ['*'],
  children: [
    {
      id: 'search-page',
      title: 'Search Page',
      translate: 'SEARCH_PAGE',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.AML_SCAN,
      auth: [PERMISSIONS.AmlScanAll.value, PERMISSIONS.StartAmlProcess.value, PERMISSIONS.OnlyTestMode.value],
    },
    {
      id: 'results',
      title: 'Results',
      translate: 'RESULTS',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.AML_SCAN_RESULTS,
      auth: [PERMISSIONS.AmlScanAll.value, PERMISSIONS.ListAmls.value, PERMISSIONS.OnlyTestMode.value],
    },
  ],
};

export const AMLConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [
    PERMISSIONS.AmlScanAll.value,
    PERMISSIONS.ListAmls.value,
    PERMISSIONS.StartAmlProcess.value,
    PERMISSIONS.OnlyTestMode.value,
  ],
  routes: [
    {
      path: PATHS.AML_SCAN,
      element: <AMLScanSearchPage />,
    },
    {
      path: PATHS.AML_SCAN_RESULTS,
      children: [
        {
          path: '',
          element: <AMLScanResultsPage />,
        },
        {
          path: PATHS.AML_SCAN_RESULT_DETAILS,
          element: <AMLScanResultDetailsPage />,
        },
        {
          path: PATHS.AML_SCAN_RESULT_LOGS,
          element: <AMLScanResultLogsPage />,
        },
      ],
    },
  ],
};
