import { lazy } from 'react';
import i18next from 'i18next';

import { PATHS, TRANSLATION_KEY } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

const Billing = lazy(() => import('./Billing'));

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const BillingNavigationConfig = {
  id: 'billing',
  title: 'Billing',
  translate: 'BILLING',
  type: 'item',
  icon: 'navigation:billing',
  url: PATHS.BILLING,
  auth: [PERMISSIONS.ListInvoices.value, PERMISSIONS.BillingAll.value],
};

export const BillingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.ListInvoices.value, PERMISSIONS.BillingAll.value],
  routes: [
    {
      path: PATHS.BILLING,
      element: <Billing />,
    },
  ],
};
