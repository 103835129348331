export default {
  ORGANIZATION: 'Організація',
  INDIVIDUAL: 'Індивідуальний',
  // NOTIFICATIONS
  PROFILE_STATUS_UPDATED: 'Статус профілю AML успішно оновлено',

  TIMELINE: 'Хронологія',
  LOGS: 'Логи AML Сканування',
  BACK_TO_RESULT: 'Повернутися до результатів',
  MONITORING_PERIODICITY: 'Періодичність моніторингу',
  PERIOD: 'Період',
  CONFIRM: 'Підтвердити',

  // SCAN DETAILS
  SCAN_DATE: 'Дата сканування',
  TOTAL_PROFILE_COUNT: 'Збіги профілю',
  SCAN_BY: 'Скановано',
  SCAN_ID: 'ID сканування',
  REFERENCE_NUMBER: 'Номер довідки',
  SCAN_QUERY: 'Запит сканування',
  SCAN_TYPE: 'Тип сканування',
  NAME_SEARCH_TYPE: 'Тип сканування імені',
  SCAN_MATCH_RATES: 'Рівень збігів сканування',
  FILTERED: 'Відфільтровано',
  NATIONALITIES: 'Національності',
  MATCH_STATUS: 'Статус збігу',
  RISK_LEVEL: 'Рівень ризику',
  ASSIGNED_USER_GUID_ID: 'Призначений користувач',
  CASE_STATUS: 'Статус справи',
  IS_DEEP_SEARCH: 'Глибокий пошук',

  SEARCH: 'Пошук',
  AML_HISTORY_RESULTS: 'Результати історії AML',
  MAX_MATCH_RATE: 'Максимальний відсоток відповідності',
  SEARCHER: 'Пошуковик',
  LAST_MODIFIED_DATE: 'Дата останньої зміни',
  SEARCH_PAGE: 'Сторінка пошуку',
  RESULTS: 'Результати',
  BACK_TO_SEARCH: 'Повернутися до пошуку',
  MONITORING_PERIOD_SUCCESSFULLY_UPDATED: 'Період моніторингу успішно оновлено',

  ALL_MATCH_STATUS: 'Усі статуси збігів',
  ALL_STATUS: 'Усі статуси справ',
  ALL_ENTITY_TYPE: 'Усі типи сутностей',
  ALL_SEARCH_BY: 'Пошук за - Усі',

  // DIALOGS MONITORING
  ENABLE: 'Увімкнути',
  DISABLE: 'Вимкнути',
  DISABLE_MONITORING_TITLE: 'Вимкнути поточний моніторинг?',
  DISABLE_MONITORING_DESCRIPTION: 'Ця дія вимкне поточний моніторинг. Будь ласка, підтвердьте своє рішення.',
  ENABLE_MONITORING_TITLE: 'Увімкнути поточний моніторинг?',
  ENABLE_MONITORING_DESCRIPTION:
    'Ця дія розпочне поточний моніторинг з періодичністю, яку ви визначили. Будь ласка, підтвердьте своє рішення.',

  SCAN: 'Сканувати',
  CLEAR: 'Очистити',
  CANCEL: 'Скасувати',
  NAME_SURNAME: 'По імені',
  AML_SCAN: 'AML Сканування',
  SCAN_RESULT: 'Результат сканування',
  PASSPORT_NUMBER: 'За паспортом',
  IDENTITY_NUMBER: 'По ідентичності',
  BY_REGISTER_NUMBER: 'За реєстровим номером',
  DOWNLOAD_PDF: 'Завантажити PDF',
  CUSTOMIZE_SCAN: 'Налаштувати сканування',
  HEADER_TITLE: 'Знайдіть та запобігайте фінансовим шахрайствам з',
  HEADER_TIP:
    'Заповніть поля нижче та почніть пошук для ефективного виявлення і запобігання фінансовим шахрайствам',

  // PERIODICITY
  DAILY: 'Щодня',
  WEEKLY: 'Один раз на тиждень',
  MONTHLY: 'Один раз на місяць',
  QUARTERLY: 'Щоквартально',
  HALF_EARLY: 'На половину раніше',
  YEARLY: 'Щороку',

  // PLACEHOLDERS
  FULL_NAME: 'Повне ім’я',
  IDENTITY_NUMBER_P: 'Ідентифікаційний номер',
  PASSPORT_NUMBER_P: 'Номер паспорта',
  REGISTER_NUMBER: 'Реєстраційний номер',

  // AML PROFILE STATUSES
  UNKNOWN: 'Невідомий',
  NO_MATCH: 'Немає збігу',
  POTENTIAL_MATCH: 'Можливий збіг',
  FALSE_POSITIVE: 'Хибнопозитивний',
  TRUE_POSITIVE: 'Істиннопозитивний',
  TRUE_POSITIVE_APPROVE: 'Істиннопозитивний, схвалити',
  TRUE_POSITIVE_REJECT: 'Істиннопозитивний, відхилити',
  AML_POSITIVE_CASES: 'Позитивні випадки AML',

  // AML CASE STATUSES
  UNCONFIRMED: 'Unconfirmed',
  CONFIRMED_FALSE_POSITIVE: 'Підтверджено помилковий результат',
  CONFIRMED_TRUE_POSITIVE: 'Підтверджено позитивний результат',

  SUMMARY_AND_DETAILS: 'Сканування підсумків і деталей',
  NO_RESULTS_FOUND: 'Результатів не знайдено',
  NO_RESULTS_FOUND_DESCRIPTION:
    'Сканування завершено, ваш пошук не дав результатів. Спробуйте інші критерії або перевірте написання',

  // FILTERS
  DEEP_SEARCH_LABEL: 'Глибокий пошук',
  DEEP_SEARCH_TOOLTIP:
    'Глибокий пошук — це сканування широкого діапазону, яке працює на основі літер і слів. Наприклад, якщо сканувати &quot;Grace Doe&quot; для &quot;Джона Грейс Доу Хьюбо&quot; запису, система не отримає результат без вибору параметра Deep-Search. Глибокий пошук виконуватиметься довше, ніж звичайне сканування.',
  COUNTRIES_PLACEHOLDER: 'Виберіть країну',
  COUNTRIES_TOOLTIP: 'Вибір країни для відповідної сутності (Максимум 5 країн)',
  EXACT_MATCH_TOOLTIP:
    'Інтервал поступової кількості, що обмежується відношенням між пошуковим запитом і можливим результатом. Результати сканування більш точні, якщо нечіткість вибирається ближче до "Точного збігу".',
  BIRTH_PLACEHOLDER: 'Рік народження або діапазон років',
  BIRTH_FILTER_TOOLTIP:
    '<span>Поле для введення інформації про рік народження.</span><span>Приклад року народження: 1995</span><span>Приклад діапазону років народження: 1995-2000</span><span>Формат дати народження: ДД.ММ.РРРР</span>',

  // CONFIRM DIALOGS
  CONFIRM_CHANGE_CASE_STATUS: 'Підтвердження зміни статусу справи',
  CONFIRM_CHANGE_CASE_STATUS_DESCRIPTION:
    'Ви впевнені, що хочете змінити статус справи для цього AML профілю?',

  CONFIRM_CHANGE_MATCH_STATUS: 'Підтвердження зміни статусу співпадіння',
  CONFIRM_CHANGE_MATCH_STATUS_DESCRIPTION:
    'Ви впевнені, що хочете змінити статус співпадіння для цього AML профілю?',

  // SCAN BY
  SCAN_NAME_SURNAME: "Ім'я Прізвище",
  SCAN_PASSPORT_NUMBER: 'Номер паспорта',
  SCAN_IDENTITY_NUMBER: 'Ідентифікаційний номер',

  EMPTY_STATE_TITLE: 'AML результати',
  EMPTY_STATE_DESCRIPTION:
    'Результатів AML поки немає. Виконайте ручний AML-скан, щоб переглянути результати для заявників.',
};
