import { lazy } from 'react';
import i18next from 'i18next';
import { TRANSLATION_KEY, PATHS } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

['uk-UA', 'en-US'].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

const ClientDetails = lazy(() => import('./ClientDetails'));

export const ClientDetailsNavigationConfig = {
  id: 'company',
  title: 'Company',
  translate: 'COMPANY',
  type: 'item',
  icon: 'navigation:company',
  url: PATHS.DETAILS,
  auth: [PERMISSIONS.GetCompanyInfo.value, PERMISSIONS.CompanyAll.value],
};

export const ClientDetailsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [PERMISSIONS.GetCompanyInfo.value, PERMISSIONS.CompanyAll.value],
  routes: [
    {
      path: PATHS.DETAILS,
      element: <ClientDetails />,
    },
  ],
};
