const { AxiosManager } = require('../axiosManager');

export default class BaseApi {
  constructor(host, path) {
    this.axios = AxiosManager.getInstance(host, path);
  }

  async getById(id) {
    try {
      const {
        data: { data },
      } = await this.axios.get(id);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAll(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('', { params: queryParams });
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async create(record) {
    try {
      const {
        data: { data },
      } = await this.axios.post('', record);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async update({ id, changes, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`${id}`, changes);
      if (typeof callback === 'function') {
        callback();
      }
      return { id, changes: data };
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async delete(id) {
    try {
      await this.axios.delete(id.toString());
      return id;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}
