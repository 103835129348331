import moment from 'moment';
import MobileDetect from 'mobile-detect';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const StyledDateRangePicker = styled(Box)(({ theme }) => ({
  '.DateRangePicker_picker': {
    zIndex: 99999,
  },

  '.DateInput_input': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'inherit',
    textAlign: 'center',
    height: '40px',

    '&__small': {
      padding: '5px 7px 3px',
    },

    '&__focused': {
      borderColor: theme.palette.violet[900],
    },
  },

  '.DateInput:last-of-type': {
    borderRadius: '0 4px 4px 0',
    marginRight: '4px',
  },

  '.DateRangePickerInput': {
    '&_calendarIcon': {
      padding: 0,
    },

    '&__withBorder': {
      borderColor: theme.palette.accent[200],
      borderRadius: 4,

      '&:hover': {
        borderColor: theme.palette.accent[300],
      },
    },
  },

  '.CalendarDay': {
    '&__highlighted_calendar': {
      backgroundColor: theme.palette.teal[500],
      color: theme.palette.background.paper,

      '&:hover': {
        backgroundColor: theme.palette.teal[600],
        color: theme.palette.background.paper,
      },
    },

    '&__hovered_span': {
      backgroundColor: theme.palette.violet[400],
      color: theme.palette.violet[900],

      '&:hover': {
        backgroundColor: theme.palette.violet[400],
        color: theme.palette.violet[900],
      },
    },
    '&__default': {
      borderRadius: '50%',
      border: 'none',

      '&:hover': { border: 'none' },
    },
    '&__selected': {
      background: theme.palette.violet[900],
      color: theme.palette.background.paper,
      border: 'none',

      '&_span': {
        backgroundColor: theme.palette.violet[400],

        '&:hover': {
          backgroundColor: theme.palette.violet[600],
        },
      },

      '&:hover': {
        backgroundColor: theme.palette.violet[900],
      },
    },
  },
}));

const md = new MobileDetect(window.navigator.userAgent);
const ranges = {
  Today: { startDate: moment().startOf('day'), endDate: moment().endOf('day') },
  'This week': { startDate: moment().startOf('week'), endDate: moment().endOf('week') },
  'This month': { startDate: moment().startOf('month'), endDate: moment().endOf('month') },
};
const isMobile = md.mobile();

const defaultDate = { endDate: null, startDate: null };

const DatesPicker = ({ values = null, clearDates, defaultValue, onChange, size = 'small', ...restProps }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [date, setDate] = useState(defaultValue || defaultDate);

  function handleDateChange(dateValue) {
    const { startDate, endDate } = dateValue;
    const start = startDate && moment(startDate._d).startOf('day');
    const end = endDate && moment(endDate._d).endOf('day');
    const data = { startDate: start, endDate: end };

    setDate(data);
    onChange(data);
  }

  useEffect(() => {
    if (values) {
      setDate(values);
    }
  }, [values]);

  return (
    <StyledDateRangePicker sx={{ '.DateInput_input': { height: size === 'medium' ? '38px' : 'auto' } }}>
      <DateRangePicker
        renderCalendarInfo={() => (
          <Stack direction="row" gap="5px" justifyContent="center" padding="10px">
            {Object.entries(ranges).map(([key, value]) => (
              <Button
                key={key}
                size="small"
                variant="text"
                color="secondary"
                sx={{ minWidth: 'unset' }}
                onClick={() => {
                  handleDateChange(value);
                  setFocusedInput(null);
                }}
              >
                {key}
              </Button>
            ))}
          </Stack>
        )}
        isOutsideRange={(day) => moment(day._d).isAfter(new Date())}
        isDayHighlighted={(day) => moment(day._d).isSame(new Date(), 'day')}
        numberOfMonths={1}
        minimumNights={0}
        small
        displayFormat="DD-MM-yyyy"
        startDate={date.startDate}
        startDateId="start_date_id"
        endDate={date.endDate}
        endDateId="end_date_id"
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        showClearDates
        showDefaultInputIcon
        hideKeyboardShortcutsPanel
        {...restProps}
      />
    </StyledDateRangePicker>
  );
};

export default DatesPicker;
