import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';
import * as SVG from 'app/shared-components/Icons';

import { TRANSLATION_KEY } from '../constants';

const IconWrapper = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.accent[300],
  backgroundColor: theme.palette.accent[200],
  width: '28px',
  height: '28px',
  padding: '6px',
  borderRadius: '50%',
}));

const Wrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
}));

const NoComments = () => {
  const { t } = useTranslation(TRANSLATION_KEY);
  return (
    <Wrapper>
      <IconWrapper>
        <SVG.CommonIcons iconId="messenger" size={14} />
      </IconWrapper>
      <Typography variant="body1" fontWeight={700}>
        {t('COMMENTS')}
      </Typography>
      <Typography variant="body2" color="accent.500">
        {t('THERE_ARE_NO_COMMENTS')}
      </Typography>
    </Wrapper>
  );
};

export default NoComments;
