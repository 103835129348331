import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

class InvoiceService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/invoice');
  }

  async getInvoices() {
    try {
      const {
        data: { data },
      } = await this.axios.get('/');
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async downloadInvoice({ filePath }) {
    const config = {
      responseType: 'blob',
    };
    try {
      const response = await this.axios.get(`${filePath}/download`, config);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new InvoiceService();
