import FuseUtils from '@fuse/utils';
import { Navigate } from 'react-router-dom';

import settingsConfig from 'app/configs/settingsConfig';
import { QuestionnaireListConfig } from 'app/main/questionnaire/QuestionnaireConfig';

import { AMLConfig } from 'app/main/aml/AMLConfig';
import { AuthConfig } from '../main/auth/AuthConfig';
import { UsersConfig, InvitesConfig, RoleManagementConfig } from '../main/teams/TeamsConfig';
import { ClientDetailsConfig } from '../main/client-details/ClientDetailsConfig';
import { ApplicationsConfig } from '../main/applications/ApplicationsConfig';
import { BillingConfig } from '../main/billing/BillingConfig';
import { DashboardConfig } from '../main/dashboard/DashboardConfig';
import { NoPermissionsPageConfig } from '../main/no-permissions-page/NoPermissionsConfig';

import { VerificationsConfig } from '../main/verifications/VerificationsConfig';
import { WorkflowsConfig } from '../main/workflow-builder/WorkflowBuilderConfig';

const routeConfigs = [
  AuthConfig,
  DashboardConfig,
  VerificationsConfig,
  ClientDetailsConfig,
  UsersConfig,
  InvitesConfig,
  RoleManagementConfig,
  ApplicationsConfig,
  BillingConfig,
  WorkflowsConfig,
  QuestionnaireListConfig,
  AMLConfig,

  NoPermissionsPageConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
];

export default routes;
