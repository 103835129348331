import { useCallback } from 'react';

import { AVATAR_TYPES } from 'app/constants/client';
import imageService from 'app/services/imageService';

const fetchImage = {
  [AVATAR_TYPES.ADMIN_USER]: imageService.getUserPhoto.bind(imageService),
  [AVATAR_TYPES.CLIENT_LOGO]: imageService.getClientLogo.bind(imageService),
  [AVATAR_TYPES.APPLICATION_LOGO]: imageService.getApplicationLogo.bind(imageService),
};

export const useGetImageUrl = (type, path) => useCallback(() => fetchImage[type](path), [type, path]);
