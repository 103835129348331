import history from '@history';
import { EMAIL_SESSION_KEY } from 'app/main/auth/constants';

export const updateOtpStorage = (token, login) => {
  sessionStorage.setItem(
    EMAIL_SESSION_KEY,
    JSON.stringify({ tokenCreated: new Date(), email: login, token })
  );
  history.push('/otp');
};

export const clearOtpStorage = () => sessionStorage.removeItem(EMAIL_SESSION_KEY);
