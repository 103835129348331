import { useEffect, useRef } from 'react';

export const useEffectOnce = (cb, deps, isReady = () => true) => {
  const didRun = useRef(false);
  const result = useRef();

  useEffect(() => {
    if (!didRun.current && isReady()) {
      didRun.current = true;
      result.current = cb();
    }

    return result.current;
  }, deps);
};
