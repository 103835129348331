import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

import { TRANSLATION_KEY } from './constants';

const NoPermissionsPage = () => {
  const { t } = useTranslation(TRANSLATION_KEY);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="100px 150px 0">
      <FuseSvgIcon size={200}>common:error-page</FuseSvgIcon>
      <Typography variant="heading5" textAlign="center">
        {t('ERROR_TEXT')}
      </Typography>
    </Box>
  );
};

export default NoPermissionsPage;
