import { useSelector } from 'react-redux';

import { authSelectors } from 'app/auth/store/userSlice';
import NoPermissionsPage from 'app/main/no-permissions-page/NoPermissionsPage';

import { hasPermission } from '../utils/hasPermission';

const PermissionsGate = ({ children, scopes = [], scopesExclude = [], errorPage = false }) => {
  const permissions = useSelector(authSelectors.selectUserPermissions);

  const permissionGranted = hasPermission(permissions, scopes);
  const permissionRejected = permissions?.some((permission) => scopesExclude.includes(permission.name));

  if (permissionRejected && !permissionGranted) {
    return <></>;
  }

  if (scopes.length && !permissionGranted) {
    if (errorPage) return <NoPermissionsPage />;
    return <></>;
  }

  return <>{children}</>;
};

export default PermissionsGate;
