export default {
  PRICING_TITLE: 'PRICING PLAN',
  STATISTICS_TITLE: 'Transactions statistic by apps',
  PRICING_PLAN_PER_ITEMS: 'Price per item',
  PRICING_MONTHLY_FEE: 'Monthly Fee',
  PRICING_MAX_VERIFICATION: 'Max verifications number',

  INVOICE_TITLE: 'Invoices',
  INVOICE_ACCOUNT: 'Account number',
  BANK_TRANSFER: 'Bank transfer',
  NEW_INVOICE: 'new',
  BILLING_STATISTICS: 'Client billing statistics',
  STATISTICS_COUNT: 'Count',

  PRICING_MONTHLY_TOTAL: 'Monthly Total',
  PRICING_EXTRA_FEE: 'Extra fee',
  PRICING_TEST_VERIFICATION: 'Test verifications number',
};
