import _ from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import Avatar from 'app/shared-components/Avatar';
import * as SVG from 'app/shared-components/Icons';
import { AVATAR_TYPES } from 'app/constants/client';

import CommentReply from './CommentReply';
import { TRANSLATION_KEY } from '../constants';

const Comment = ({
  text,
  authorPhoto,
  authorFullName,
  authorRole,
  replyClick,
  authorId,
  commentId,
  parentComment,
  onCommentClick,
  commentsRef,
  createdAt,
}) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const handleReply = () => {
    replyClick({ text, commentId, authorId, authorFullName });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '16px 16px 8px 16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '12px',
        border: '1px solid',
        borderColor: 'accent.100',
        marginBottom: '8px',
        overflow: 'hidden',
        gap: '8px',
      }}
      ref={(ref) => {
        if (commentsRef) {
          commentsRef.current[commentId] = ref;
        }
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Avatar
          type={AVATAR_TYPES.ADMIN_USER}
          path={authorPhoto}
          alt={authorFullName}
          sx={{ width: 40, height: 40 }}
        />
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography fontSize="14px" fontWeight="600">
              {authorFullName}
            </Typography>
            <Typography variant="caption" color="accent.400">
              {format(new Date(createdAt), 'HH:mm MMMM dd')}
            </Typography>
          </Box>
          <Typography variant="caption" color="accent.500">
            {_.capitalize(authorRole)}
          </Typography>
        </Box>
      </Box>
      {parentComment && (
        <CommentReply
          sx={{ cursor: 'pointer' }}
          bottom="0"
          minHeight="35px"
          padding="4px 10px"
          position="relative"
          dividerHeight="80%"
          onCommentClick={() => onCommentClick(parentComment.id)}
          text={parentComment.content}
          userName={parentComment.authorName}
          contentProps={{ lineHeight: '14px', fontSize: '10px' }}
          userNameProps={{ variant: 'caption', lineHeight: '16px' }}
        />
      )}
      <Typography
        variant="body2"
        sx={{ color: 'accent.700', wordWrap: 'break-word', wordBreak: 'break-word' }}
      >
        {text}
      </Typography>
      {!parentComment && (
        <Button
          variant="text"
          onClick={handleReply}
          size="small"
          sx={{ padding: 0, color: 'accent.500', gap: '5px' }}
        >
          <SVG.CommonIcons size={12} iconId="reply" />
          <Typography variant="button">{t('REPLY')}</Typography>
        </Button>
      )}
    </Box>
  );
};

export default Comment;
