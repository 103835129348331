const locale = {
  COMPANY: 'Компанія',
  COMPANY_STATISTICS: 'Аналітика',
  COMPANY_STATISTICS_DESCRIPTION: 'Відстежуйте показники, перевіряйте звіти та ефективність',
  SEE_INFO: 'Дивитись інформацію',
  PHONE: 'Номер телефону',
  EMAIL: 'Поштова адреса',
  ADDRESS: 'Адреса',
  DESCRIPTION: 'Опис',

  ALL_APPLICATIONS: 'Всі додатки',

  DATE_RANGE_COMPARED: 'Кількість успішних верифікацій в порівнянні за проміжком часу',
  AVG_COUNT_COMPARED: 'Середня кількість всіх верифікацій в порівнянні за проміжком часу',
  FAILS_COMPARED: 'Кількість успішних верифікацій в порівнянні з кількістю невдалих',
  AVG_SERVICE_STATS: 'Успішні сервіси',

  TRENDING_UP: 'Приріст',
  TRENDING_DOWN: 'Падіння',
  STATISTICS_NOT_FOUND: 'Статистики не знайдено',
  STATISTICS_DESCRIPTION: 'Загальна кількість',

  VERIFICATIONS_OVERVIEW: 'Статистика верифікацій',
  VERIFICATIONS_SUBTITLE: 'Кількість успішних й невдалих верифікацій',
  SUCCESS_STATISTICS_DESCRIPTION: 'Кількість успішних верифікацій',
  FAILED_STATISTICS_DESCRIPTION: 'Кількість невдалих верифікацій',

  PLATFORM_VERIFICATIONS_OVERVIEW: 'Статистика верифікацій за платформами',
  PLATFORM_VERIFICATIONS_SUBTITLE: 'Кількість верифікацій по кожній підтримуваній платформі',

  FAILED_STAGES_OVERVIEW: 'Статистика невдалих верифікацій за етапом',
  FAILED_STAGES_SUBTITLE: 'Кількість невдалих верифікацій по кожному етапу',

  APPLICATION: 'Додаток',

  ANDROID: 'Android',
  WEB: 'Web',
  IOS: 'IOS',
  TODAY: 'Сьогодні',
  DAY: 'День',
  WEEK: 'Тиждень',
  MONTH: 'Місяць',
  YEAR: 'Рік',
  YESTERDAY: 'Вчора',
  LAST_7_DAYS: 'Останні 7 днів',
  LAST_28_DAYS: 'Останні 28 днів',
  DATE_RANGE: 'Вибрати діапазон дат',

  DOCUMENT_CAPTURE: 'Обробка документу',
  CLEAN_DOCUMENT_CAPTURE: 'Очистити обробку документу',
  BARCODE_CAPTURE: 'Обробка штріхкоду',
  CREDIT_CARD_CAPTURE: 'Обробка кредитної картки',
  RFID_DOCUMENT_CAPTURE: 'Обробка Rfid документу',
  SPECIFIC_DOCUMENT_CAPTURE: 'Обробка спеціалізованого документу',
  SELFIE_CAPTURE: 'Обробка селфі',
  FACE_CAPTURE: 'Обробка обличчя',
  PASSIVE_FACE_MATCH: 'Розпізнавання обличчя',
  FACE_LIVENESS: 'Перевірка автентичності',
  SIGNATURE: 'Підпис',
  OTP_VERIFICATION: 'Верифікація одноразового пароля',
  UTILITY_BILL: 'Рахунок за комунальні послуги',
  DIIA: 'Дія',

  NO_SERVICE: 'Сеанс закінчився',

  // WARNINGS
  AML_RESULT_UPDATED: 'Результати AML оновлено',
  AML_CANNOT_STARTED: 'Неможливо розпочати AML',
  AML_POSITIVE_CASES: 'Позитивні випадки AML',
  APPLICANT_DUPLICATE_PHONE: 'Дубльований телефон заявника',
  APPLICANT_DUPLICATE_DOCUMENT: 'Дубльований документ заявника',
  EXPIRED_DOCUMENT: 'Термін дії документа закінчився',
  APPLICANT_DUPLICATE_EMAIL: 'Дубльована електронна пошта заявника',

  // APPLICANTS STATISTICS
  APPLICANTS_CREATED: 'Претендентів створено',
  REJECTED: 'Відхилено',
  ACCEPTED: 'Прийнято',
  RESOLVED: 'Вирішено',
  UNRESOLVED: 'Не вирішено',
  DOCUMENT_ORIGIN: 'Походження документа',
  APPLICANTS_DISTRIBUTION: 'Розподіл заявників',
  PHONE_NUMBER_ORIGIN: 'Походження номер телефону',
  TOTAL_APPLICANTS_NUMBER: 'Кількість заявників',
  TOTAL_APPLICANTS_APPROVED: 'Кількість заявників затверджено',
  TOTAL_APPLICANTS_REJECTED: 'Кількість відхилених заявок',
  TOTAL_APPLICANTS_COMPLETED: 'Кількість поданих заявок',
  TOTAL_APPLICANTS_IN_REVIEW: 'Кількість заявників на розгляді',
  PHONE_NUMBER_ORIGIN_BY_COUNTRY: 'Походження номерів телефонів за країною',
  PHONE_NUMBER_ORIGIN_BY_COUNTRY_DESC: 'Кількість номерів телефонів заявників, згрупованих за кодом країни.',
  APPLICANTS_STATUS_OVERVIEW: 'Огляд статусу заявників',
  APPLICANTS_STATUS_OVERVIEW_DESC: 'Розподіл заявників за їх поточним статусом перевірки.',
  APPLICANTS_DISTRIBUTION_BY_COUNTRY: 'Розподіл заявників за країною',
  APPLICANTS_DISTRIBUTION_BY_COUNTRY_DESC: 'Географічний розподіл заявників за країною їх проживання.',
  TOTAL_APPLICANT_WARNINGS: 'Загальна кількість застережень серед заявників',
  TOTAL_APPLICANT_WARNINGS_DESC: 'Кількість застережень, через проблеми з даними заявників.',
  DOCUMENT_ORIGIN_BY_COUNTRY: 'Походження документів за країною',
  DOCUMENT_ORIGIN_BY_COUNTRY_DESC: 'Кількість документів, згрупованих за країною видачі.',
  APPLICANTS_CREATED_PER_DAY: 'Кількість створених заявників за день',
  APPLICANTS_CREATED_PER_DAY_DESC:
    'Ця діаграма показує кількість створених заявників за день, відображаючи тенденції та дні з найбільшою активністю.',
};

export default locale;
