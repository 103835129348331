import { createAction, createSelector } from '@reduxjs/toolkit';
import { BaseEntityStoreBuilder } from 'app/store/helpers';
import flowService from './flowService';

export const FLOW_MODULE_KEY = 'flow';

const storeBuilder = new BaseEntityStoreBuilder(FLOW_MODULE_KEY, flowService);

const entityStore = (state) => state[FLOW_MODULE_KEY];

export const actions = {
  getFlowById: storeBuilder.createAction('getFlowById'),
  createNewFlow: storeBuilder.createAction('createNewFlow'),
  updateFlow: storeBuilder.createAction('updateFlow'),
  deleteFlow: storeBuilder.createAction('deleteFlow'),
  duplicateFlow: storeBuilder.createAction('duplicateFlow'),
  getAllFlows: storeBuilder.createAction('getAllFlows'),
  getFlowDocType: storeBuilder.createAction('getFlowDocType'),
  setFlowIndex: createAction(`${FLOW_MODULE_KEY}/setFlowIndex`),
  clearErrors: createAction(`${FLOW_MODULE_KEY}/clearErrors`),
};

export const selectors = {
  ...storeBuilder.selectors,
  selectFlow: createSelector(entityStore, (state) => state.flow),
  selectFlowError: createSelector(entityStore, (state) => state.flowError),
  selectFlowIndex: createSelector(entityStore, (state) => state.flowIndex),
};

const flowSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.clearErrors, (state) => {
      state.error = null;
      state.success = null;
    })
    .addCase(actions.setFlowIndex, (state, { payload }) => {
      state.flowIndex = { index: payload };
    })
    .addCase(actions.updateFlow.pending, (state) => {
      state.flowError = null;
      state.loading = true;
    })
    .addCase(actions.updateFlow.fulfilled, (state) => {
      state.flowError = null;
      state.loading = false;
    })
    .addCase(actions.updateFlow.rejected, (state, action) => {
      state.flowError = action.error;
      state.loading = false;
    })
    .addCase(actions.createNewFlow.pending, (state) => {
      state.flowError = null;
      state.loading = true;
    })
    .addCase(actions.createNewFlow.rejected, (state, action) => {
      state.flowError = action.error;
      state.loading = false;
    })
    .addCase(actions.createNewFlow.fulfilled, (state) => {
      state.flowError = null;
      state.loading = false;
    })
    .addCase(actions.getFlowById.pending, (state) => {
      state.flowError = null;
      state.error = null;
      state.loading = true;
    })
    .addCase(actions.getFlowById.rejected, (state, { error }) => {
      state.error = error.message;
      state.loading = false;
    })
    .addCase(actions.getFlowById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.flow = payload;
    })
    .addCase(actions.duplicateFlow.fulfilled, storeBuilder.adapter.addOne)
    .addCase(actions.getAllFlows.pending, (state) => {
      state.flowError = null;
      state.flow = null;
    })
    .addCase(actions.getAllFlows.fulfilled, (state, { payload, meta }) => {
      const { rows = [], total = 0 } = payload;
      const { arg } = meta;
      const { offset } = { ...arg };

      state.total = total;

      if (!offset) {
        return storeBuilder.adapter.setAll(state, rows);
      }
      return storeBuilder.adapter.addMany(state, rows);
    })
    .addCase(actions.deleteFlow.fulfilled, (state, { meta }) => {
      state.total -= 1;

      return storeBuilder.adapter.removeOne(state, meta.arg);
    })
);

export default flowSlice.reducer;
