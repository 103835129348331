import { createAction } from '@reduxjs/toolkit';

import applicationsService from './applicationsService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const APPLICATIONS_MODULE_KEY = 'applications';

const storeBuilder = new BaseEntityStoreBuilder(
  APPLICATIONS_MODULE_KEY,
  applicationsService,
  (app) => app.key
);
export const { selectors } = storeBuilder;
export const actions = {
  getApplicationById: storeBuilder.actions.getById,
  getAllApplications: storeBuilder.actions.getAll,
  createApplication: storeBuilder.createAction('createApplication'),
  clearData: createAction(`${APPLICATIONS_MODULE_KEY}/clearData`),
  deleteApplication: storeBuilder.actions.delete,
  forceDeleteApp: storeBuilder.createAction('forceDelete'),
  updateApplication: storeBuilder.actions.update,
  updateSecretKey: storeBuilder.createAction('updateSecretKey'),
  updateApplicationByKey: createAction(`${APPLICATIONS_MODULE_KEY}/updateOneByKey`),
  createRSAKey: storeBuilder.createAction('addRSAKey'),
  deleteRSAKey: storeBuilder.createAction('deleteRSAKey'),
  updateApplicationLogo: storeBuilder.createAction('updateLogo'),
  clearErrors: createAction(`${APPLICATIONS_MODULE_KEY}/clearError`),
};

const applicationsSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.updateApplicationByKey, storeBuilder.adapter.upsertOne)
    .addCase(actions.forceDeleteApp.fulfilled, storeBuilder.adapter.removeOne)
    .addCase(actions.forceDeleteApp.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    })
    .addCase(actions.updateSecretKey.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.updateApplicationLogo.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.createRSAKey.fulfilled, (state, action) => {
      state.successCreateRSAKey = true;
      storeBuilder.adapter.upsertOne(state, action.payload);
    })
    .addCase(actions.createRSAKey.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    })
    .addCase(actions.createRSAKey.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.deleteRSAKey.fulfilled, storeBuilder.adapter.upsertOne)
    .addCase(actions.clearErrors, (state) => {
      state.error = null;
    })
    .addCase(actions.clearData, (state) => {
      state.isFulfilled = false;
      state.appKey = undefined;
    })
    .addCase(actions.createApplication.fulfilled, (state, action) => {
      state.appKey = action.payload.key;
      storeBuilder.adapter.addOne(state, action.payload);
    })
);

export default applicationsSlice.reducer;
