import { useEffect, useState } from 'react';

export default (key, initValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : initValue;
  });

  const setValue = (value) => {
    localStorage.setItem(key, JSON.stringify(value));
    setStoredValue(value);
  };

  useEffect(() => {
    const value = localStorage.getItem(key);
    setStoredValue(value ? JSON.parse(value) : initValue);
  }, [key]);

  return [storedValue, setValue];
};
