import { AxiosManager } from '../axiosManager';
import BaseApi from '../common/base-api';
import { addRecaptchaHeader } from '../jwtService';

class RestorePasswordService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'v1/api/web/user');
  }

  async sendRestore({ reCaptcha, ...model }) {
    try {
      const { data } = await this.axios.post('sendRestore', model, addRecaptchaHeader(reCaptcha));
      if (data.status && data.statusCode === 200) {
        return model;
      }
      return new Error('Unknown error');
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async restoreWithCode({ reCaptcha, ...model }) {
    try {
      const { data } = await this.axios.post('restore', model, addRecaptchaHeader(reCaptcha));
      if (data.status && data.statusCode === 200) {
        return model;
      }
      return new Error('Unknown error');
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new RestorePasswordService();
