import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

class ClientDetailsService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/client');
  }

  async update({ changes, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put('/', changes);
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async updateLogo({ logo }) {
    const formData = new FormData();
    formData.append('logo', logo);

    try {
      const {
        data: { data },
      } = await this.axios.put('/logo', formData);
      return data.logo;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new ClientDetailsService();
