import { useCallback, useState } from 'react';
import { selectors } from 'app/store/modeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, useUpdateEffect } from '@fuse/hooks';

const initialFilters = { offset: 0, limit: 50, endDate: null, startDate: null, search: null };

const useFilters = ({ defaultFilters, fetchAction, onFiltersChange }) => {
  const dispatch = useDispatch();
  const currentMode = useSelector(selectors.selectModeType);
  const [filters, setFilters] = useState(defaultFilters || initialFilters);
  const fetch = useDebounce((params) => dispatch(fetchAction(params)), 500);

  useUpdateEffect(() => {
    fetch(filters);
  }, [filters, currentMode]);

  useUpdateEffect(() => {
    onFiltersChange?.(filters);
  }, [filters]);

  const onSearch = useCallback((event) => {
    setFilters((prev) => ({ ...prev, search: event.target.value, offset: 0 }));
  }, []);

  const onDateChange = useCallback(({ startDate, endDate }) => {
    const start = startDate && startDate._d;
    const end = endDate && endDate._d;

    if ((start && end) || (!start && !end)) {
      setFilters((prev) => ({ ...prev, startDate: start, endDate: end, offset: 0 }));
    }
  }, []);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;

    if (value === 'all') {
      setFilters((prev) => ({ ...prev, [name]: undefined }));
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  }, []);

  return { onSearch, onDateChange, onChange };
};

export default useFilters;
