export default {
  SAVE: 'Save',
  UPDATE_CLIENT: 'Update company info',
  CLIENT_KEY: 'Client key',
  ACCOUNT_NUMBER: 'Account number',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ADDRESS: 'Address',
  CONTACT: 'Contact',
  INFO: 'Info',
  INTEGRATION: 'Integration',
  STATUS: 'Status',
  CREATED_AT: 'Creation date',
  UPDATED_AT: 'Update date',
  EDIT_COMPANY: 'Edit company',
  COMPANY: 'Company',

  NAME_HELPER: 'You must enter your company name',
  NAME_ERROR: 'You must enter a valid company name',

  PHONE_HELPER: 'You must enter a phone number in format +380000000000',
  PHONE_ERROR: 'You must enter a valid phone number',

  EMAIL_HELPER: 'You must enter a email',
  EMAIL_ERROR: 'You must enter a valid email',
  COMPANY_UPDATE: 'Changes have been saved',
};
