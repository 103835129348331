import uniqueId from 'lodash/uniqueId';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { LOGS_MODULE_KEY } from '../constants';

const adapter = createEntityAdapter({
  selectId: (logs) => logs.id,
});

const logsSlice = createSlice({
  name: LOGS_MODULE_KEY,
  initialState: adapter.getInitialState({
    total: 0,
  }),
  reducers: {
    setLogs: (state, action) => {
      const { total, rows } = { ...action.payload };
      state.total = total;
      return adapter.setAll(state, rows.map((row) => ({ id: uniqueId(), ...row })) || []);
    },
    addLogs: (state, action) => {
      state.total = action.payload?.total || 0;
      return adapter.addMany(state, action.payload?.rows.map((row) => ({ id: uniqueId(), ...row })) || []);
    },
    clearLogs: (state) => {
      state.total = 0;
      return adapter.setAll(state, []);
    },
  },
});

export const { actions } = logsSlice;

const entityStore = (state) => state[LOGS_MODULE_KEY];

const baseSelectors = adapter.getSelectors(entityStore);

export const selectors = {
  ...baseSelectors,
  selectTotal: createSelector(entityStore, (state) => state.total),
};

export default logsSlice.reducer;
