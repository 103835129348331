import { createSelector, createSlice } from '@reduxjs/toolkit';

import dashboardService from './dashboardService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const DASHBOARD_MODULE_KEY = 'dashboard';

const ACTIONS = {
  FAILS_STAGE: 'failsStageStatistics',
  FAILED: 'failedStatistics',
  SUCCESSFULL: 'successStatistics',
  DATE_COMPARED: 'dateComparedStatistics',
  FAILS_COMPARED: 'failsComparedStatistics',
  AVG_COUNT_COMPARED: 'averageCountComparedStatistics',
  AVG_COUNT_SERVICE_STATS: 'averageCountServicesStats',
  TOTAL_NUMBER_OF_APPLICANTS: 'getTotalNumberOfApplicants',
  IN_REVIEW_COUNT_OF_APPLICANTS: 'getInReviewApplicantsCount',
  APPLICANTS_WARNINGS_COUNT: 'getApplicantsWarningsCount',
  APPLICANTS_COUNT_BY_STATUS: 'getApplicantsCountByStatus',
  NEW_APPLICANTS_PER_DAY: 'getNewApplicantsPerDay',
  APPLICANTS_TIME_PASS_FLOW: 'getApplicantsTimePassFlow',
  APPLICANTS_DOC_COUNTRY_COUNT: 'getApplicantsDocCountryCount',
  APPLICANTS_PHONE_COUNTRY_COUNT: 'getApplicantsPhoneCountryCount',
  APPLICANTS_COUNTRY_COUNT: 'getApplicantsCountryCount',
};

const entityStore = (state) => state[DASHBOARD_MODULE_KEY];

export const selectors = {
  selectFailsStageStatistics: createSelector(entityStore, (state) => state[ACTIONS.FAILS_STAGE]),
  selectFailedStatistics: createSelector(entityStore, (state) => state[ACTIONS.FAILED]),
  selectSuccessStatistics: createSelector(entityStore, (state) => state[ACTIONS.SUCCESSFULL]),
  selectDateComparedStatistics: createSelector(entityStore, (state) => state[ACTIONS.DATE_COMPARED]),
  selectFailsComparedStatistics: createSelector(entityStore, (state) => state[ACTIONS.FAILS_COMPARED]),
  selectAverageCountComparedStatistics: createSelector(
    entityStore,
    (state) => state[ACTIONS.AVG_COUNT_COMPARED]
  ),
  selectAverageCountServicesStats: createSelector(
    entityStore,
    (state) => state[ACTIONS.AVG_COUNT_SERVICE_STATS]
  ),
  selectTotalNumberOfApplicants: createSelector(
    entityStore,
    (state) => state[ACTIONS.TOTAL_NUMBER_OF_APPLICANTS]
  ),
  selectInReviewTotalOfApplicants: createSelector(
    entityStore,
    (state) => state[ACTIONS.IN_REVIEW_COUNT_OF_APPLICANTS]
  ),
  selectApplicantsWarningsCount: createSelector(
    entityStore,
    (state) => state[ACTIONS.APPLICANTS_WARNINGS_COUNT]
  ),
  selectApplicantsCountByStatus: createSelector(
    entityStore,
    (state) => state[ACTIONS.APPLICANTS_COUNT_BY_STATUS]
  ),
  selectNewApplicantsPerDay: createSelector(entityStore, (state) => state[ACTIONS.NEW_APPLICANTS_PER_DAY]),
  selectApplicantsTimePassFlow: createSelector(
    entityStore,
    (state) => state[ACTIONS.APPLICANTS_TIME_PASS_FLOW]
  ),
  selectApplicantsDocCountryCount: createSelector(
    entityStore,
    (state) => state[ACTIONS.APPLICANTS_DOC_COUNTRY_COUNT]
  ),
  selectApplicantsPhoneCountryCount: createSelector(
    entityStore,
    (state) => state[ACTIONS.APPLICANTS_PHONE_COUNTRY_COUNT]
  ),
  selectApplicantsCountryCount: createSelector(
    entityStore,
    (state) => state[ACTIONS.APPLICANTS_COUNTRY_COUNT]
  ),
};

const storeBuilder = new BaseEntityStoreBuilder(DASHBOARD_MODULE_KEY, dashboardService);

export const actions = {
  getInReviewApplicantsCount: storeBuilder.createAction(ACTIONS.IN_REVIEW_COUNT_OF_APPLICANTS),
  getTotalNumberOfApplicants: storeBuilder.createAction(ACTIONS.TOTAL_NUMBER_OF_APPLICANTS),
  getFailsStageStatistics: storeBuilder.createAction(ACTIONS.FAILS_STAGE),
  getFailedStatistic: storeBuilder.createAction(ACTIONS.FAILED),
  getSuccessStatistic: storeBuilder.createAction(ACTIONS.SUCCESSFULL),
  getDateComparedStatistic: storeBuilder.createAction(ACTIONS.DATE_COMPARED),
  getFailsComparedStatistic: storeBuilder.createAction(ACTIONS.FAILS_COMPARED),
  getAverageCountComparedStatistics: storeBuilder.createAction(ACTIONS.AVG_COUNT_COMPARED),
  getAverageCountServicesStats: storeBuilder.createAction(ACTIONS.AVG_COUNT_SERVICE_STATS),
  getApplicantsWarningsCount: storeBuilder.createAction(ACTIONS.APPLICANTS_WARNINGS_COUNT),
  getApplicantsCountByStatus: storeBuilder.createAction(ACTIONS.APPLICANTS_COUNT_BY_STATUS),
  getNewApplicantsPerDay: storeBuilder.createAction(ACTIONS.NEW_APPLICANTS_PER_DAY),
  getApplicantsTimePassFlow: storeBuilder.createAction(ACTIONS.APPLICANTS_TIME_PASS_FLOW),
  getApplicantsDocCountryCount: storeBuilder.createAction(ACTIONS.APPLICANTS_DOC_COUNTRY_COUNT),
  getApplicantsPhoneCountryCount: storeBuilder.createAction(ACTIONS.APPLICANTS_PHONE_COUNTRY_COUNT),
  getApplicantsCountryCount: storeBuilder.createAction(ACTIONS.APPLICANTS_COUNTRY_COUNT),
};

const defaultState = {
  loading: true,
  error: null,
};

const dashboardSlice = createSlice({
  name: DASHBOARD_MODULE_KEY,
  initialState: {
    [ACTIONS.FAILED]: defaultState,
    [ACTIONS.FAILS_STAGE]: defaultState,
    [ACTIONS.SUCCESSFULL]: defaultState,
    [ACTIONS.DATE_COMPARED]: defaultState,
    [ACTIONS.FAILS_COMPARED]: defaultState,
    [ACTIONS.AVG_COUNT_COMPARED]: defaultState,
    [ACTIONS.AVG_COUNT_SERVICE_STATS]: defaultState,
    [ACTIONS.APPLICANTS_WARNINGS_COUNT]: defaultState,
    [ACTIONS.TOTAL_NUMBER_OF_APPLICANTS]: defaultState,
    [ACTIONS.IN_REVIEW_COUNT_OF_APPLICANTS]: defaultState,
    [ACTIONS.APPLICANTS_COUNT_BY_STATUS]: defaultState,
    [ACTIONS.NEW_APPLICANTS_PER_DAY]: defaultState,
    [ACTIONS.APPLICANTS_TIME_PASS_FLOW]: defaultState,
    [ACTIONS.APPLICANTS_DOC_COUNTRY_COUNT]: defaultState,
    [ACTIONS.APPLICANTS_PHONE_COUNTRY_COUNT]: defaultState,
    [ACTIONS.APPLICANTS_COUNTRY_COUNT]: defaultState,
  },

  extraReducers: (builder) => {
    const stateActionAssociation = [
      [ACTIONS.FAILS_STAGE, actions.getFailsStageStatistics],
      [ACTIONS.FAILED, actions.getFailedStatistic],
      [ACTIONS.SUCCESSFULL, actions.getSuccessStatistic],
      [ACTIONS.DATE_COMPARED, actions.getDateComparedStatistic],
      [ACTIONS.FAILS_COMPARED, actions.getFailsComparedStatistic],
      [ACTIONS.AVG_COUNT_COMPARED, actions.getAverageCountComparedStatistics],
      [ACTIONS.AVG_COUNT_SERVICE_STATS, actions.getAverageCountServicesStats],
      [ACTIONS.TOTAL_NUMBER_OF_APPLICANTS, actions.getTotalNumberOfApplicants],
      [ACTIONS.IN_REVIEW_COUNT_OF_APPLICANTS, actions.getInReviewApplicantsCount],
      [ACTIONS.APPLICANTS_WARNINGS_COUNT, actions.getApplicantsWarningsCount],
      [ACTIONS.APPLICANTS_COUNT_BY_STATUS, actions.getApplicantsCountByStatus],
      [ACTIONS.NEW_APPLICANTS_PER_DAY, actions.getNewApplicantsPerDay],
      [ACTIONS.APPLICANTS_TIME_PASS_FLOW, actions.getApplicantsTimePassFlow],
      [ACTIONS.APPLICANTS_DOC_COUNTRY_COUNT, actions.getApplicantsDocCountryCount],
      [ACTIONS.APPLICANTS_PHONE_COUNTRY_COUNT, actions.getApplicantsPhoneCountryCount],
      [ACTIONS.APPLICANTS_COUNTRY_COUNT, actions.getApplicantsCountryCount],
    ];

    stateActionAssociation.forEach(([key, action]) =>
      builder
        .addCase(action.pending, (state) => {
          state[key].loading = true;
        })
        .addCase(action.fulfilled, (state, { payload }) => {
          state[key] = {
            data: payload,
            loading: false,
          };
        })
        .addCase(action.rejected, (state, { error }) => {
          state[key].error = error.message;
          state[key].loading = false;
        })
    );
  },
});

export default dashboardSlice.reducer;
