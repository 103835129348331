import Close from '@mui/icons-material/Close';
import { Stack, Divider, IconButton, Typography } from '@mui/material';

import * as SVG from 'app/shared-components/Icons';

const CommentReply = ({
  dividerHeight = 'auto',
  userNameProps,
  contentProps,
  text,
  userName,
  onClear = null,
  onCommentClick,
  ...resetProps
}) => (
  <Stack
    border="1px solid"
    borderColor="accent.200"
    direction="row"
    alignItems="center"
    gap="8px"
    padding="6px 12px"
    borderRadius="30px"
    backgroundColor="accent.50"
    position="absolute"
    bottom="60px"
    left={0}
    width="100%"
    {...resetProps}
    onClick={onCommentClick}
  >
    <SVG.CommonIcons color="primary" size={12} iconId="reply" />
    <Divider orientation="vertical" variant="middle" flexItem={!!onClear} sx={{ height: dividerHeight }} />
    <Stack minWidth={0}>
      <Typography variant="body2" fontWeight={600} color="accent.900" {...userNameProps}>
        {userName}
      </Typography>

      <Typography variant="caption" {...contentProps}>
        {text}
      </Typography>
    </Stack>
    {onClear && (
      <IconButton sx={{ color: 'accent.900', padding: 0, marginLeft: 'auto' }} onClick={onClear}>
        <Close fontSize="small" />
      </IconButton>
    )}
  </Stack>
);

export default CommentReply;
